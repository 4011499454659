import {useState} from 'react';
import {useDispatch} from 'react-redux';
import {changeEmailAsync} from 'store/slices/authSlice';
import {Form, Input, Button} from 'antd';
import {useTranslation} from 'react-i18next';
import {Row, Col, Typography} from 'antd';
import {ViewHeader} from 'components/layout/ViewHeader';

const {Text} = Typography;

export type ChangeEmailFormValues = {
  email: string;
  confirm: string;
};

export const ChangeEmailView = () => {
  const [ButtonLoading, setButtonLoading] = useState(false);
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const onFinish = (values: ChangeEmailFormValues) => {
    setButtonLoading(true);
    dispatch(changeEmailAsync(values));
    setTimeout(() => {
      setButtonLoading(false);
    }, 1000);
  };

  return (
    <div className="site-view-welcome" style={{paddingTop: '128px'}}>
      <Row>
        <Col flex="auto" />
        <Col
          flex="300px"
          style={{background: 'white', padding: '16px', borderRadius: '8px'}}>
          <ViewHeader title={t('change-email.changeEmailTitle')} />
          <Form
            name="change-email"
            layout="vertical"
            initialValues={{remember: true}}
            onFinish={onFinish}>
            <Form.Item
              name="email"
              label={t('change-email.enterNewEmail')}
              rules={[{type: 'email'}]}>
              <Input />
            </Form.Item>
            <Form.Item
              name="confirm"
              label={t('change-email.confirmNewEmail')}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: t('change-email.confirmYourEmailWarningText'),
                },
                ({getFieldValue}) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('email') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(t('change-email.dosNotMatch'))
                    );
                  },
                }),
              ]}>
              <Input />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{width: '100%', marginTop: '20px'}}
                loading={ButtonLoading}>
                {t('change-email.accept')}
              </Button>
            </Form.Item>
          </Form>
          <Text type="secondary">{t('change-email.changeEmailText')}</Text>
        </Col>
        <Col flex="auto" />
      </Row>
    </div>
  );
};
