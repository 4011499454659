import * as _ from 'lodash';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {COMPANIES_COLLECTION, Company} from 'store/slices/companiesSlice';
import {AppThunk, RootState} from 'store/index';
import {v4 as uuidv4} from 'uuid';
import {firebase} from 'services/firebase';
import {DepotFormValues} from 'views/depot/partials/DepotForm';
import {message} from 'antd';
import {createSelector} from 'reselect';
import {sessionStateSelector} from 'store/slices/sessionSlice';
import {GeoLocation} from 'services/hooks/useGeocode';
import {StopMessage, stopMessageSelector} from 'store/slices/stopMessagesSlice';
import i18next from 'i18next';

export const DEPOTS_COLLECTION = 'depots';

export interface StatusDepot extends Depot {
  activeStopMessages: StopMessage[];
  indicatorColor?: '#ff0000' | '#00ff00' | '#ffa500';
}

export interface Depot {
  id: string;
  name: string;
  company: Company;
  country: string;
  products: string[];
  geoLocation: GeoLocation;
  deleted?: boolean;
}

export interface DepotLight {
  id: string;
  name: string;
  country: string;
  company: string;
}

interface DepotState {
  depots: Depot[];
  hasError: boolean;
  error: string;
  isLoading: boolean;
}

const initialState = {
  depots: [],
  hasError: false,
  error: '',
  isLoading: false,
} as DepotState;

export const depotsSlice = createSlice({
  name: 'depots',
  initialState: initialState,
  reducers: {
    updating: (state) => {
      state.depots = [];
      state.isLoading = true;
    },
    hasError: (state, action: PayloadAction<string>) => {
      state.hasError = true;
      state.error = i18next.t('error.anErrorOccurredEntity', {
        entity: action.payload,
      });
      state.isLoading = false;
    },
    update: (state, action: PayloadAction<Depot[]>) => {
      state.depots = _.orderBy(
        action.payload,
        ['company.name', 'name'],
        ['asc', 'asc']
      );
      state.isLoading = false;
    },
  },
});

/**
 * Load Depots
 */
export const loadDepotsAsync = (): AppThunk => async (dispatch) => {
  try {
    dispatch(updating());
    const querySnapshot = await firebase
      .firestore()
      .collectionGroup(DEPOTS_COLLECTION)
      .get();

    const depots = querySnapshot.docs.map((doc) => {
      return {
        id: doc.id,
        name: doc.data().name,
        country: doc.data().country,
        company: doc.data().company,
        products: doc.data().products,
        geoLocation: doc.data().geoLocation,
        deleted: doc.data().deleted ?? false,
      } as Depot;
    });
    dispatch(update(depots));
  } catch (error) {
    dispatch(hasError(error.message.toString()));
  }
};

/**
 * Creates a new Depot Async
 * @param depotFormValues
 * @param company
 */
export const createDepotAsync = (
  depotFormValues: DepotFormValues,
  company: Company
): AppThunk => async (dispatch) => {
  try {
    const uuid = uuidv4();
    const docRef = firebase
      .firestore()
      .collection(COMPANIES_COLLECTION)
      .doc(company.id)
      .collection(DEPOTS_COLLECTION)
      .doc(uuid);

    const companyLight = {
      id: company.id,
      name: company.name,
    };

    await docRef.set({
      name: depotFormValues.name,
      geoLocation: depotFormValues.geoLocation,
      products:
        depotFormValues.products && depotFormValues.products.length > 0
          ? depotFormValues.products
          : [],
      country: depotFormValues.geoLocation.country, // make it backward compatible
      company: companyLight, // store a simplified company object (id, name)
      id: uuid,
      deleted: false,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
    dispatch(loadDepotsAsync());
    message.info(
      i18next.t('depot.successfullyEntity', {
        entity: i18next.t('genericWords.created'),
      })
    );
    return true;
  } catch (error) {
    message.error(error.message.toString());
    return false;
  }
};

/**
 * Edit a Depot Async
 * @param depotFormValues
 * @param companyId
 * @param depotId
 */
export const updateDepotAsync = (
  depotFormValues: DepotFormValues,
  companyId: string,
  depotId: string
): AppThunk => async (dispatch) => {
  try {
    const depotRef = firebase
      .firestore()
      .collection(COMPANIES_COLLECTION)
      .doc(companyId)
      .collection(DEPOTS_COLLECTION)
      .doc(depotId);

    await depotRef.update({
      name: depotFormValues.name,
      geoLocation: depotFormValues.geoLocation,
      products:
        depotFormValues.products && depotFormValues.products.length > 0
          ? depotFormValues.products
          : [],
      country: depotFormValues.geoLocation.country, // make it backward compatible
    });
    dispatch(loadDepotsAsync());
    message.info(
      i18next.t('depot.successfullyEntity', {
        entity: i18next.t('genericWords.updated'),
      })
    );
    return true;
  } catch (error) {
    message.error(error.message.toString());
    return false;
  }
};

export const {updating, hasError, update} = depotsSlice.actions;
export const depotStateSelector = (state: RootState): DepotState =>
  state.depots;

/**
 * Soft delete company depot
 * @param companyId
 * @param depotId
 */
export const softDeleteDepotAsync = (
  companyId: string,
  depotId: string
): AppThunk => async (dispatch) => {
  try {
    const depotRef = firebase
      .firestore()
      .collection(COMPANIES_COLLECTION)
      .doc(companyId)
      .collection(DEPOTS_COLLECTION)
      .doc(depotId);
    await depotRef.update({deleted: true});
    dispatch(loadDepotsAsync());
    message.info(
      i18next.t('depot.successfullyEntity', {
        entity: i18next.t('genericWords.deleted'),
      })
    );
  } catch (error) {
    message.error(error.message.toString());
  }
};

/**
 * This method will probably handle different context and
 * return values filtered by for example Haulage->Depots
 */
export const companyDepotsSelector = createSelector(
  depotStateSelector,
  (state) => {
    return _.chain(state.depots)
      .groupBy('company.name')
      .map((depots, company) => ({
        companyName: company,
        depots: depots.filter((depot) => depot.deleted !== true),
      }))
      .value();
  }
);

export const getReplacementDepotsSelector = (currentDepotId: string) =>
  createSelector(depotStateSelector, (state) => {
    return _.chain(state.depots)
      .groupBy('company.name')
      .map((depots, company) => ({
        companyName: company,
        depots: depots
          .filter(
            (depot) => depot.deleted !== true && depot.id !== currentDepotId
          )
          .map(
            (d) =>
              ({
                id: d.id,
                name: d.name,
                company: d.company.name,
                country: d.country,
              } as DepotLight)
          ),
      }))
      .value();
  });

export const getDepotsBySessionCompanySelector = createSelector(
  [depotStateSelector, sessionStateSelector],
  (depotState, sessionState) => {
    return depotState.depots.filter(
      (depot) =>
        depot.deleted === false &&
        depot.company.id === sessionState.selectedCompany?.id
    );
  }
);

export const getStatusDepotsByCompany = createSelector(
  [getDepotsBySessionCompanySelector, stopMessageSelector],
  (depots, stopMessageState) => {
    return depots.map((depot) => {
      const activeStopMessages = stopMessageState.activeStopMessages.filter(
        (stop) =>
          stop.depot.id === depot.id && !stop.deleted && stop.status !== 'DRAFT'
      );

      const upcoming = activeStopMessages.filter(
        (stop) => stop.startDate > new Date().getTime()
      );

      const depotColor =
        activeStopMessages.length > 0
          ? upcoming.length === activeStopMessages.length
            ? '#ffa500'
            : '#ff0000'
          : '#00ff00';
      return {
        ...depot,
        indicatorColor: depotColor,
        activeStopMessages,
      } as StatusDepot;
    });
  }
);

export const getDepotByIdSelector = (depotId: string) =>
  createSelector(depotStateSelector, (state) => {
    return state.depots.find((depot) => depot.id === depotId);
  });

export default depotsSlice.reducer;
