import {ConfigProvider, Collapse, Table} from 'antd';
import {ColumnsType} from 'antd/lib/table';
import {ViewHeader} from 'components/layout/ViewHeader';
import {useSupports} from 'services/hooks/useSupports';
import {SupportContact, supportStateSelector} from 'store/slices/supportSlice';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {capitalizeFirstLetter} from 'utils/helpers';

const {Panel} = Collapse;
export const SupportListView = (): JSX.Element => {
  useSupports();
  const supportState = useSelector(supportStateSelector);
  const {t} = useTranslation();
  /**
   * Config Table Columns
   */
  const columns: ColumnsType<any> = [
    {
      title: t('support.name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: capitalizeFirstLetter(t('company.name')),
      dataIndex: 'company',
      key: 'company',
    },
    {
      title: t('email.name'),
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: capitalizeFirstLetter(t('communication.phoneNumber')),
      dataIndex: 'phone',
      key: 'phone',
    },
  ];

  return (
    <div className="site-main-content" style={{padding: '16px'}}>
      <ViewHeader title={t('support.name')} />
      <Collapse collapsible="header" defaultActiveKey={['support-table']}>
        <Panel
          header={capitalizeFirstLetter(t('contact.contacts'))}
          key="support-table"
          className="panel-body-no-padding">
          <ConfigProvider>
            <Table<SupportContact>
              pagination={false}
              loading={supportState.isLoading}
              dataSource={supportState.support}
              columns={columns}
              rowKey={'email'}
            />
          </ConfigProvider>
        </Panel>
      </Collapse>
    </div>
  );
};
