import {useSelector} from 'react-redux';
import {Spin} from 'antd';
import {authStateSelector} from 'store/slices/authSlice';
import {SuperAdminLayout} from 'components/layout/users/SuperAdminLayout';
import {AdministratorLayout} from 'components/layout/users/AdministratorLayout';
import {DepotOperatorLayout} from 'components/layout/users/DepotOperatorLayout';
import {HaulageAdminLayout} from 'components/layout/users/HaulageAdminLayout';
import {DriverAndStakeholderLayout} from 'components/layout/users/DriverAndStakeholderLayout';
import {
  SUPER_ADMIN,
  ADMINISTRATOR,
  DEPOT_OPERATOR,
  HAULAGE_ADMIN,
  DRIVER,
  STAKEHOLDER,
} from 'constants/roles';

export const NavbarLayout = () => {
  const authState = useSelector(authStateSelector);
  const userRole = authState.user?.role;

  if (userRole === SUPER_ADMIN) return <SuperAdminLayout />;
  if (userRole === ADMINISTRATOR) return <AdministratorLayout />;
  if (userRole === DEPOT_OPERATOR) return <DepotOperatorLayout />;
  if (userRole === HAULAGE_ADMIN) return <HaulageAdminLayout />;
  if (userRole === DRIVER) return <DriverAndStakeholderLayout />;
  if (userRole === STAKEHOLDER) return <DriverAndStakeholderLayout />;

  return (
    <>
      <Spin />
    </>
  );
};
