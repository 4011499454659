import {Link} from 'react-router-dom';
import {Button, Form, Input, Row, Col, Spin, message} from 'antd';
import {UserOutlined, LockOutlined} from '@ant-design/icons';
import {useState, useEffect} from 'react';
import {
  authStateSelector,
  loginAsync,
  sendEmailVerificationAsync,
  setEmailVerified,
} from 'store/slices/authSlice';
import {useDispatch, useSelector} from 'react-redux';
import {ViewHeader} from 'components/layout/ViewHeader';
import {useTranslation} from 'react-i18next';
import {Redirect} from 'react-router-dom';
import {capitalizeFirstLetter} from 'utils/helpers';

export const LoginView = (props: any) => {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const authState = useSelector(authStateSelector);
  const [email, setEmail] = useState('');
  const [sentEmail, setSentEmail] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    const sendEmailVerification = async () => {
      try {
        await sendEmailVerificationAsync(
          sentEmail,
          t('auth.verifyEmail.emailTemplateHtmlPart1'),
          t('auth.verifyEmail.emailTemplateHtmlPart2'),
          t('auth.verifyEmail.emailSubject')
        );
        message.success(t('auth.verifyEmail.verificationEmailSent'));
      } catch (error) {
        message.error(error.message);
      }
    };
    if (!authState.isEmailVerified) {
      message.error({
        content: (
          <div>
            <span>
              {t('auth.error.emailNotVerified')}
              <Button
                style={{padding: '3px'}}
                size="small"
                type="link"
                onClick={sendEmailVerification}>
                {t('auth.error.resendEmailVerificationLinkText')}
              </Button>
            </span>
          </div>
        ),
        duration: 10,
      });
    }
    dispatch(setEmailVerified(true));
  }, [authState.isEmailVerified, dispatch, t, sentEmail]);

  const onFinish = async () => {
    setSentEmail(email);
    dispatch(loginAsync(email, password));
  };

  if (
    authState.isAuthenticated &&
    !authState.isAuthenticating &&
    authState.user &&
    !authState.isLoadingProfile
  ) {
    const userDepots = authState.user?.depots ?? [];
    const redirect = userDepots.length === 1 ? `/depot/${userDepots[0]}` : '/';
    return <Redirect to={redirect} />;
  }

  return (
    <div className="site-view-welcome" style={{paddingTop: '128px'}}>
      <Row>
        <Col flex="auto" />
        <Col
          flex="300px"
          style={{background: 'white', padding: '16px', borderRadius: '8px'}}>
          <ViewHeader title={'Login'} />
          <Spin spinning={authState.isAuthenticating}>
            <Form
              name="login-form"
              className="login-form"
              hideRequiredMark
              onFinish={onFinish}>
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: t('genericWords.missingEntity', {
                      entity: capitalizeFirstLetter(t('email.name')),
                    }),
                  },
                ]}>
                <Input
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  placeholder={capitalizeFirstLetter(t('email.name'))}
                  prefix={<UserOutlined className="site-form-item-icon" />}
                />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: t('genericWords.missingEntity', {
                      entity: capitalizeFirstLetter(t('auth.password')),
                    }),
                  },
                ]}>
                <Input.Password
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  placeholder={capitalizeFirstLetter(t('auth.password'))}
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                />
              </Form.Item>
              <Form.Item>
                <Link className="login-form-forgot" to={`/forgotPassword`}>
                  {t('auth.forgottenPassword')}
                </Link>
              </Form.Item>
              <Form.Item>
                <Button block type="primary" htmlType="submit">
                  {t('auth.login')}
                </Button>
              </Form.Item>
            </Form>
          </Spin>
        </Col>
        <Col flex="auto" />
      </Row>
    </div>
  );
};
