import {useState, useEffect} from 'react';
import {Button, Col, Form, Input, Row, Spin} from 'antd';
import {LockOutlined} from '@ant-design/icons';
import {useDispatch, useSelector} from 'react-redux';
import {
  resetPasswordAsync,
  verifyPasswordResetCodeAsync,
  authStateSelector,
} from 'store/slices/authSlice';
import {useTranslation} from 'react-i18next';
import {ViewHeader} from 'components/layout/ViewHeader';
import {Redirect} from 'react-router-dom';
import {PASSWORD_PATTERN} from 'utils/patterns';

export const ResetPassword = (props: any) => {
  const dispatch = useDispatch();
  const authState = useSelector(authStateSelector);
  const {t} = useTranslation();
  const [newPassword, setNewPassword] = useState('');
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    const verifier = async () => {
      dispatch(verifyPasswordResetCodeAsync(props.actionCode));
    };
    verifier();
  }, [dispatch, props.actionCode]);

  const handleResetPassword = async () => {
    setSubmitting(true);
    dispatch(
      resetPasswordAsync(props.actionCode, newPassword, t('auth.success'))
    );
    setSubmitting(false);
  };

  if (authState.isPasswordReset && !authState.isResettingPassword)
    return <Redirect to="/login" />;

  if (authState.hasError) return <Redirect to="/forgotPassword" />;

  return (
    <div className="site-view-welcome" style={{paddingTop: '128px'}}>
      <Row>
        <Col flex="auto" />
        <Col
          flex="300px"
          style={{background: 'white', padding: '16px', borderRadius: '8px'}}>
          <div>
            <ViewHeader title={t('auth.resetPassword')} />
            <Spin spinning={authState.isResettingPassword}>
              <Form
                name="reset-password-form"
                className="login-form"
                hideRequiredMark
                onFinish={handleResetPassword}>
                <Form.Item
                  name="newPassword"
                  rules={[
                    {
                      required: true,
                      message: t('auth.error.password.newPasswordRequired'),
                    },
                    {
                      pattern: PASSWORD_PATTERN,
                      message: t(
                        'auth.error.password.newPasswordPatternValidation'
                      ),
                    },
                  ]}>
                  <Input.Password
                    placeholder={t('auth.newPassword')}
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    onChange={(e) => setNewPassword(e.target.value)}
                    value={newPassword}
                  />
                </Form.Item>
                <Form.Item
                  name="confirmPassword"
                  dependencies={['newPassword']}
                  hasFeedback
                  rules={[
                    {
                      message: t('auth.error.password.confirmPasswordRequired'),
                      required: true,
                    },
                    ({getFieldValue}) => ({
                      validator(rule, value) {
                        if (!value || getFieldValue('newPassword') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          t('auth.error.password.noMatchPassword')
                        );
                      },
                    }),
                  ]}>
                  <Input.Password
                    placeholder={t('auth.confirmPassword')}
                    prefix={<LockOutlined className="site-form-item-icon" />}
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    block
                    loading={submitting}
                    type="primary"
                    htmlType="submit">
                    {t('auth.resetPassword')}
                  </Button>
                </Form.Item>
              </Form>
            </Spin>
          </div>
        </Col>
        <Col flex="auto" />
      </Row>
    </div>
  );
};
