import {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {Button, Form, message, Modal} from 'antd';
import {User, adminUpdateUser, loadUsersAsync} from 'store/slices/usersSlice';
import {UserForm} from 'components/form/UserForm';
import {useTranslation} from 'react-i18next';

interface UserEditModalProps {
  onSave?: () => void;
  onClose: () => void;
  visible: boolean;
  user: User;
  haulageId?: string;
}

export const UserEditModal = (props: UserEditModalProps) => {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(props.visible);
  const [submitted, setSubmitted] = useState(false);
  const [form] = Form.useForm<User>();
  const {t} = useTranslation();

  useEffect(() => {
    if (props.visible) {
      setSubmitted(false);
      setVisible(true);
    }
  }, [props.visible]);

  const editUser = async (formValues: User) => {
    const key = 'updating-user';
    try {
      setSubmitted(true);
      message.loading({content: t('user.updatingUser'), key});
      await adminUpdateUser({id: props.user.id as string, ...formValues});
      setTimeout(() => {
        message.success({content: t('user.userUpdatedSuccess'), key});
        form.resetFields();
        setVisible(false);
        dispatch(loadUsersAsync());
        if (props.onSave) props.onSave();
        if (props.onClose) props.onClose();
      }, 3000);
    } catch (error) {
      message.error({content: error.message, key});
      setSubmitted(false);
    }
  };

  const submit = async () => {
    try {
      await form.validateFields();
      form.submit();
    } catch (errorInfo) {
      message.error(t('error.fieldNotFilled'));
      setSubmitted(false);
    }
  };

  return (
    <>
      <Modal
        title={`${t('user.profile')}`}
        width={1000}
        bodyStyle={{height: '660px', overflowY: 'auto'}}
        maskClosable={false}
        closable={false}
        key={'edit-user-modal'}
        keyboard={false}
        centered
        visible={visible}
        footer={[
          <Button
            key="back-cancel"
            disabled={submitted}
            onClick={() => {
              setVisible(false);
              form.resetFields();
              if (props.onClose) props.onClose();
            }}>
            {t('buttons.cancel')}
          </Button>,
          <Button
            key="submit-btn"
            type="primary"
            loading={submitted}
            style={{marginRight: 8}}
            onClick={async () => {
              await submit();
            }}>
            {t('buttons.save')}
          </Button>,
        ]}>
        <UserForm
          mode="edit"
          isSaving={submitted}
          user={props.user}
          onSave={(formValues) => editUser(formValues)}
          form={form}
          haulageId={props.haulageId}
        />
      </Modal>
    </>
  );
};
