import {EditOutlined} from '@ant-design/icons';
import {Button} from 'antd';
import {useProducts} from 'services/hooks/useProducts';
import {useHistory} from 'react-router-dom';

export const EditProfileView = (props: any) => {
  useProducts();

  const history = useHistory();
  const goToEditProfile = () => {
    history.push('/user/edit/' + props.userId);
  };

  return (
    <>
      <Button
        onClick={() => goToEditProfile()}
        key="create-depot-button"
        type="primary"
        icon={<EditOutlined />}>
        Edit Profile
      </Button>
    </>
  );
};
