import {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {loadSupportAsync} from 'store/slices/supportSlice';

export const useSupports = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadSupportAsync())
  }, [dispatch]);
};