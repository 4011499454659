import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {loadProductsAsync} from 'store/slices/productSlice';
import {sessionStateSelector} from 'store/slices/sessionSlice';

export const useProducts = () => {
  const dispatch = useDispatch();
  const session = useSelector(sessionStateSelector);

  useEffect(() => {
    if (session.selectedCompany) {
      dispatch(loadProductsAsync(session.selectedCompany?.id));
    }
  }, [dispatch, session.selectedCompany]);
};
