import {PlusOutlined} from '@ant-design/icons';
import {Button, Form, message} from 'antd';
import {Modal} from 'antd';
import {useEffect, useState} from 'react';
import {
  ProductForm,
  ProductFormValues,
} from 'views/product/partials/ProductForm';
import {createProductAsync} from 'store/slices/productSlice';
import {sessionStateSelector} from 'store/slices/sessionSlice';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

export const ProductCreateModal = () => {
  const dispatch = useDispatch();
  const session = useSelector(sessionStateSelector);
  const [visible, setVisible] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [form] = Form.useForm<ProductFormValues>();
  const {t} = useTranslation();

  useEffect(() => {
    setSubmitted(false);
  }, [visible]);

  const addProduct = (formValues: ProductFormValues) => {
    if (session.selectedCompany) {
      dispatch(createProductAsync(formValues, session.selectedCompany));
      setSubmitted(true);
      setTimeout(() => {
        setVisible(false);
        form.resetFields();
      }, 1000);
    }
  };

  const submit = async () => {
    try {
      await form.validateFields();
      form.submit();
    } catch (errorInfo) {
      message.error(t('error.fieldNotFilled'));
      setSubmitted(false);
    }
  };

  return (
    <>
      <Button
        onClick={() => setVisible(true)}
        key="create-product-button"
        type="primary"
        icon={<PlusOutlined />}>
        {t('product.addNew')}
      </Button>
      <Modal
        title={`${session.selectedCompany?.name ?? ''} - ${t(
          'product.addNew'
        )}`}
        width={600}
        maskClosable={false}
        closable={false}
        key={'create-product-modal'}
        centered
        onCancel={() => {
          setVisible(false);
          form.resetFields();
        }}
        visible={visible}
        footer={[
          <Button
            key="back-cancel"
            disabled={submitted}
            onClick={() => {
              setVisible(false);
              form.resetFields();
            }}>
            {t('buttons.cancel')}
          </Button>,
          <Button
            key="submit-btn"
            type="primary"
            loading={submitted}
            style={{marginRight: 8}}
            onClick={async () => {
              await submit();
            }}>
            {t('buttons.save')}
          </Button>,
        ]}>
        <ProductForm
          mode="create"
          isSaving={submitted}
          form={form}
          onSave={(formValues) => addProduct(formValues)}
        />
      </Modal>
    </>
  );
};
