import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import {LabeledValue} from 'antd/es/select';
import i18nCountries from 'i18n-iso-countries';
import en from 'i18n-iso-countries/langs/en.json';
import sv from 'i18n-iso-countries/langs/sv.json';
import {useTranslation} from 'react-i18next';
import * as _ from 'lodash';

i18nCountries.registerLocale(en);
i18nCountries.registerLocale(sv);

export interface GlobalContextValue {
  countries: LabeledValue[];
}

const GlobalContext = createContext<GlobalContextValue>({
  countries: [],
});

interface GlobalContextProviderProps {
  children: ReactNode;
}

export const GlobalContextProvider = (props: GlobalContextProviderProps) => {
  const [countries, setCountries] = useState<LabeledValue[]>([]);
  const [loading, setLoading] = useState(true);
  const {i18n} = useTranslation();

  useEffect(() => {
    setLoading(true);
    // todo fetch country form i18n.language
    const officials = i18nCountries.getNames('en', {
      select: 'official',
    });
    const countryOptions = Object.entries(officials).map(
      ([value, label]) => ({value, label: label} as LabeledValue)
    );
    setCountries(_.orderBy(countryOptions, ['label'], ['asc']));
    setLoading(false);
  }, [i18n.language]);

  if (loading) {
    return <></>;
  }
  return (
    <GlobalContext.Provider value={{countries}}>
      {props.children}
    </GlobalContext.Provider>
  );
};

export const useGlobalContext = () => useContext(GlobalContext);
