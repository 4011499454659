import {PageHeader} from 'antd';

interface ViewHeaderProps {
  title: string;
  subTitle?: string;
  extra?: React.ReactNode;
}

export const ViewHeader = (props: ViewHeaderProps): JSX.Element => {
  return (
    <div>
      <PageHeader
        style={{padding: '16px 8px'}}
        title={props.title}
        subTitle={props.subTitle}
        className="site-page-header"
        extra={props.extra ? props.extra : []}
      />
    </div>
  );
};
