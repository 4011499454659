import {useTranslation} from 'react-i18next';

export const IntroView = (): JSX.Element => {
  const {t} = useTranslation();
  return (
    <div className="site-view-welcome">
      <div className="caption">
        <span className="header">{t('splash.welcome')}</span>
      </div>
    </div>
  );
};
