import {Select, Skeleton} from 'antd';
import * as React from 'react';
import {useSelector} from 'react-redux';
import {companiesStateSelector} from 'store/slices/companiesSlice';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';

const {Option} = Select;

interface CompanySelectProps {
  value?: string;
  onChange?: (value: string) => void;
}

export const CompanySelect = (props: CompanySelectProps): JSX.Element => {
  const companies = useSelector(companiesStateSelector);
  const [company, setCompany] = useState<string>();
  const {t} = useTranslation();

  const handleChange = (value: string) => {
    setCompany(value);
    if (props.onChange) {
      props.onChange(value);
    }
  };

  if (companies.isLoading)
    return <Skeleton.Input style={{width: 200}} active={true} size="default" />;

  return (
    <Select
      value={props.value || company}
      placeholder={t('form.placeholder.selectEntity', {
        entity: t('company.name'),
      })}
      style={{width: 200}}
      onChange={handleChange}>
      {companies.companies.map((company) => (
        <Option key={company.id} value={company.id}>
          {company.name}
        </Option>
      ))}
    </Select>
  );
};
