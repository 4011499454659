import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {
  COMPANIES_COLLECTION,
  Company,
  CompanyLight,
} from 'store/slices/companiesSlice';
import {firebase} from 'services/firebase';
import {AppThunk, RootState} from 'store/index';
import {message} from 'antd';
import {v4 as uuidv4} from 'uuid';
import * as _ from 'lodash';
import {ProductType} from 'store/slices/productTypesSlice';
import {ProductFormValues} from 'views/product/partials/ProductForm';
import {getDepotByIdSelector} from 'store/slices/depotsSlice';
import {createSelector} from 'reselect';
import i18next from 'i18next';

export const PRODUCTS_COLLECTION = 'products';

export interface Product {
  id: string;
  name: string;
  company: CompanyLight;
  description: string;
  code: string;
  type: ProductType;
  createdAt: number;
  updatedAt?: number;
  deleted: boolean;
}

export interface ProductLight {
  id: string;
  name: string;
  code: string;
  type: ProductType;
  description: string;
}

interface ProductState {
  products: Product[];
  hasError: boolean;
  error: string;
  isLoading: boolean;
}

const initialState = {
  products: [],
  hasError: false,
  error: '',
  isLoading: false,
} as ProductState;

export const productSlice = createSlice({
  name: 'products',
  initialState: initialState,
  reducers: {
    updating: (state) => {
      state.products = [];
      state.isLoading = true;
    },
    hasError: (state, action: PayloadAction<string>) => {
      state.hasError = true;
      state.error = i18next.t('error.anErrorOccurredEntity', {
        entity: action.payload,
      });
      state.isLoading = false;
    },
    update: (state, action: PayloadAction<Product[]>) => {
      // state.products = _.orderBy(action.payload, ['company.name'], ['asc']);
      state.products = action.payload;
      state.isLoading = false;
    },
  },
});

/**
 * Load products by company id
 */
export const loadProductsAsync = (companyId: string): AppThunk => async (
  dispatch
) => {
  try {
    dispatch(updating());
    const querySnapshot = await firebase
      .firestore()
      .collection(COMPANIES_COLLECTION)
      .doc(companyId)
      .collection(PRODUCTS_COLLECTION)
      .get();

    const products = querySnapshot.docs.map((doc) => {
      return {
        id: doc.id,
        name: doc.data().name,
        company: doc.data().company,
        description: doc.data().description,
        code: doc.data().code,
        type: doc.data().type,
        // Note: redux does not like serialize firebase timestamps
        createdAt: doc.data().createdAt.toMillis(),
        updatedAt: doc.data().updatedAt ? doc.data().updatedAt.toMillis() : 0,
        deleted: doc.data().deleted,
      } as Product;
    });
    dispatch(update(products));
  } catch (error) {
    dispatch(hasError(error.message.toString()));
  }
};

/**
 * Creates a new Product record (only super_users && company_admins)
 * @param productFormValues ProductFormValues
 * @param company Company
 * @return boolean
 */
export const createProductAsync = (
  productFormValues: ProductFormValues,
  company: Company
): AppThunk => async (dispatch) => {
  try {
    const uuid = uuidv4();
    const docRef = firebase
      .firestore()
      .collection(COMPANIES_COLLECTION)
      .doc(company.id)
      .collection(PRODUCTS_COLLECTION)
      .doc(uuid);

    const companyLight = {
      id: company.id,
      name: company.name,
    };

    await docRef.set({
      ...productFormValues,
      company: companyLight, // store a simplified company object (id, name)
      id: uuid,
      deleted: false,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
    dispatch(loadProductsAsync(company.id));
    message.info(
      i18next.t('product.successfullyEntity', {
        entity: i18next.t('genericWords.created'),
      })
    );
    return true;
  } catch (error) {
    message.error(error.message.toString());
  }
};

/**
 * Update edited product Async
 * @param productFormValues ProductFormValues
 * @param product Product
 * @return boolean
 */
export const updateProductAsync = (
  productFormValues: ProductFormValues,
  product: Product
): AppThunk => async (dispatch) => {
  try {
    const docRef = firebase
      .firestore()
      .collection(COMPANIES_COLLECTION)
      .doc(product.company.id)
      .collection(PRODUCTS_COLLECTION)
      .doc(product.id);

    await docRef.update({
      ...productFormValues,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
    dispatch(loadProductsAsync(product.company.id));
    message.info(
      i18next.t('product.successfullyEntity', {
        entity: i18next.t('genericWords.updated'),
      })
    );
    return true;
  } catch (error) {
    message.error(error.message.toString());
  }
};

/**
 * Soft Deletes a product by setting deleted to true
 * @param id string
 * @param companyId string
 */
export const softDeleteProductAsync = (
  id: string,
  companyId: string
): AppThunk => async (dispatch) => {
  try {
    const docRef = firebase
      .firestore()
      .collection(COMPANIES_COLLECTION)
      .doc(companyId)
      .collection(PRODUCTS_COLLECTION)
      .doc(id);
    await docRef.update({deleted: true});
    dispatch(loadProductsAsync(companyId));
    message.info(
      i18next.t('product.successfullyEntity', {
        entity: i18next.t('genericWords.deleted'),
      })
    );
  } catch (error) {
    message.error(error.message.toString());
  }
};

export const {updating, hasError, update} = productSlice.actions;
export const productStateSelector = (state: RootState): ProductState =>
  state.products;

export const getActiveProductSelector = (state: RootState) => {
  return state.products.products.filter((product) => product.deleted === false);
};

/**
 * Get Paginated products
 */
export const getPaginatedProductsSelector = createSelector(
  [getActiveProductSelector],
  (products) => {
    const sorted = _.orderBy(products, ['name'], ['asc']);
    return _.chunk(sorted, 20);
  }
);

/**
 * Move this depotProductSelector.ts?
 * Get available product options for a given Depot
 * @param depotId
 */
export const getAvailableDepotProductsSelector = (depotId: string) =>
  createSelector(
    [getActiveProductSelector, getDepotByIdSelector(depotId)],
    (products, depot) => {
      return products
        .filter((product) => depot?.products.includes(product.id))
        .map(
          (product) =>
            ({
              id: product.id,
              name: product.name,
              code: product.code,
              type: product.type,
              description: product.description,
            } as ProductLight)
        );
    }
  );

export const getDepotProductsSelector = (currentDepotId: string) =>
  createSelector(
    getAvailableDepotProductsSelector(currentDepotId),
    (products) => {
      return _.chain(products)
        .groupBy('type.name')
        .map((products, type) => ({
          productType: type,
          products: _.sortBy(products, (product) => product.name),
        }))
        .value();
    }
  );

export default productSlice.reducer;
