import {Input, Button, PageHeader, message, Radio} from 'antd';
import {useEffect, useState} from 'react';
import {authStateSelector} from 'store/slices/authSlice';
import {useSelector} from 'react-redux';
import {sendMail} from 'services/mail';
import {useTranslation} from 'react-i18next';

export const MailTestView = (): JSX.Element => {
  const [email, setEmail] = useState('');
  const [msg, setMsg] = useState('');
  const authState = useSelector(authStateSelector);
  const {t} = useTranslation();

  const [selectedEmailLanguage, setSelectedEmailLanguage] = useState('Svenska');
  const [sendingEmail, setSendingEmail] = useState({
    emailType: '',
    isLoading: false,
  });

  useEffect(() => {
    setEmail(authState.user?.email ?? '');
  }, [authState]);

  return (
    <div className="site-main-content" style={{padding: '1rem'}}>
      <div style={{padding: '1rem', maxWidth: '600px'}}>
        <PageHeader
          style={{padding: '16px 8px'}}
          title={t('email.title')}
          subTitle={t('email.subTitle')}
        />
        <Input
          value={email}
          placeholder={t('form.placeholder.enterEntity', {
            entity: t('email.emailAddress'),
          })}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Input
          placeholder={t('form.placeholder.enterEntity', {
            entity: t('communication.message'),
          })}
          onChange={(e) => setMsg(e.target.value)}
        />
        <Button
          loading={
            sendingEmail.emailType === 'normal' && sendingEmail.isLoading
          }
          style={{margin: '5px'}}
          type="primary"
          onClick={() => {
            setSendingEmail({
              emailType: 'normal',
              isLoading: true,
            });

            sendMail(email, msg)
              .then((r) => {
                message.success(t('email.sendSucceed'));
                setSendingEmail({
                  emailType: 'normal',
                  isLoading: false,
                });
              })
              .catch((e) => {
                message.error(t('error.occurred'));
                setSendingEmail({
                  emailType: 'normal',
                  isLoading: false,
                });
              });
          }}>
          {t('email.sendTestEmail')}
        </Button>
        <div
          style={{
            display: 'flex',
            flexWrap: 'nowrap',
            justifyContent: 'space-around',
          }}>
          <div>
            <Button
              loading={
                sendingEmail.emailType === 'reminder-upcoming' &&
                sendingEmail.isLoading
              }
              style={{margin: '5px'}}
              type="primary"
              onClick={() => {
                setSendingEmail({
                  emailType: 'reminder-upcoming',
                  isLoading: true,
                });

                sendMail(email, msg, 'reminder-upcoming', selectedEmailLanguage)
                  .then((r) => {
                    message.success(t('email.sendSucceed'));
                    setSendingEmail({
                      emailType: 'reminder-upcoming',
                      isLoading: false,
                    });
                  })
                  .catch((e) => {
                    message.error(t('error.occurred'));
                    setSendingEmail({
                      emailType: 'reminder-upcoming',
                      isLoading: false,
                    });
                  });
              }}>
              {t('email.sendTestReminderStartEmail')}
            </Button>
            <Button
              style={{margin: '5px'}}
              type="primary"
              loading={
                sendingEmail.emailType === 'reminder-ending' &&
                sendingEmail.isLoading
              }
              onClick={() => {
                setSendingEmail({
                  emailType: 'reminder-ending',
                  isLoading: true,
                });
                sendMail(email, msg, 'reminder-ending', selectedEmailLanguage)
                  .then((r) => {
                    message.success(t('email.sendSucceed'));
                    setSendingEmail({
                      emailType: 'reminder-ending',
                      isLoading: false,
                    });
                  })
                  .catch((e) => {
                    message.error(t('error.occurred'));
                    setSendingEmail({
                      emailType: 'reminder-ending',
                      isLoading: false,
                    });
                  });
              }}>
              {t('email.sendTestReminderEndingEmail')}
            </Button>
          </div>
          <div style={{margin: 'auto'}}>
            <Radio.Group
              defaultValue="Svenska"
              onChange={(e) => setSelectedEmailLanguage(e.target.value)}>
              <Radio.Button style={{margin: '5px'}} value="Svenska">
                Svenska
              </Radio.Button>
              <Radio.Button style={{margin: '5px'}} value="English">
                English
              </Radio.Button>
            </Radio.Group>
          </div>
        </div>
      </div>
    </div>
  );
};
