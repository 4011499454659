import {Input, Button, PageHeader, Tabs} from 'antd';
import {useState} from 'react';

export const TemplateView = (): JSX.Element => {
  const [msg, setMsg] = useState('');
  // const {t} = useTranslation();

  return (
    <div className="site-main-content" style={{padding: '16px'}}>
      <PageHeader
        style={{padding: '16px 8px'}}
        title={'Message templates'}
        subTitle={'edit messages going out'}
      />
      <Tabs type="card">
        <Tabs.TabPane tab="Stop Message Template" key="item-1">
          Stop Message sv/en
        </Tabs.TabPane>
        <Tabs.TabPane tab="Info Message Template" key="item-2">
          Info Message sv/en
        </Tabs.TabPane>
      </Tabs>
      <Input.TextArea
        rows={16}
        value={msg}
        onChange={(e) => setMsg(e.target.value)}
      />
      <Button type="primary">Save template</Button>
    </div>
  );
};
