import {useState} from 'react';
import {Switch} from 'antd';
import {FormFilterDataModel} from '../DashboardView';
import {ChartDrawing} from './ChartDrawing';
import i18n from 'utils/i18n';
import 'styles/chart.css';

interface ChartViewProps {
  chartsFormFilterData: {
    companyId: string;
    startDate: object; // Date?
    endDate: object; // Date?
  };
  tabKey: string;
  filteredNumber?: number;
}
export const ChartView = (props: ChartViewProps) => {
  const [modePicker, setModePicker] = useState(true);
  const modeSelector = (e: boolean) => {
    setModePicker(e);
  };

  let chartTextYAxis = '';
  if (props.tabKey === 'dashboard')
    chartTextYAxis = i18n.t('chart.numberOfStops');
  if (props.tabKey === 'meansAdvancedPlanning')
    chartTextYAxis = i18n.t('chart.numberOfDays');
  if (props.tabKey === 'stopDays')
    chartTextYAxis = i18n.t('chart.totalCostValue');
  if (props.tabKey === 'numberOfDowntimes')
    chartTextYAxis = i18n.t('chart.numberOfStops');

  return (
    <>
      <div style={{padding: '20px 0 20px 18px', textAlign: 'center'}}>
        <Switch
          checkedChildren={i18n.t('chart.groupByDepot')}
          unCheckedChildren={i18n.t('chart.groupByDepot')}
          style={{marginLeft: '20px'}}
          defaultChecked
          onChange={(e) => modeSelector(e)}
        />
      </div>

      {props.chartsFormFilterData.companyId ? (
        <>
          <div className={'chart-container'}>
            <div className={'number-of-text'}>
              <span
                style={{
                  backgroundColor: '#eee',
                  color: '#999999',
                  paddingTop: '0.5rem',
                  paddingBottom: '0.5rem',
                  paddingLeft: '0.25rem',
                  margin: 0,
                }}>
                {chartTextYAxis}
              </span>
            </div>
            <div className={'histogram'}>
              <ChartDrawing
                modePicker={modePicker}
                queryParams={props.chartsFormFilterData as FormFilterDataModel}
                tabKey={props.tabKey}
                dailyCost={props.filteredNumber}
              />
            </div>
          </div>
        </>
      ) : (
        <div style={{height: '400px', textAlign: 'center'}}>
          <h4 style={{paddingTop: '100px', color: 'salmon', fontSize: '18px'}}>
            {i18n.t('chart.pressShowStatistics')}
          </h4>
        </div>
      )}
    </>
  );
};
