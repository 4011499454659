import {Typography} from 'antd';
import {useState} from 'react';
const {Paragraph} = Typography;

interface ExpandableProps {
  rows: number;
  children: React.ReactNode;
}

export const Expandable = (props: ExpandableProps): JSX.Element => {
  const [expanded, setExpanded] = useState(false);
  const [counter, setCounter] = useState(0);

  const expand = () => {
    setExpanded(true);
    setCounter(!expanded ? counter + 0 : counter + 1);
  };

  const close = (e: any) => {
    e.preventDefault();
    setExpanded(false);
    setCounter(!expanded ? counter + 0 : counter + 1);
  };
  return (
    <div>
      <div key={counter}>
        <Paragraph
          ellipsis={{
            rows: props.rows,
            expandable: true,
            onExpand: () => expand(),
          }}>
          {props.children}
        </Paragraph>
      </div>
      {expanded && <a href="#toggle" onClick={close}>Close</a>}
    </div>
  );
};
