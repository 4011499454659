import ReactDOM from 'react-dom';
import 'styles/index.css';
import {store} from 'store';
import {Provider} from 'react-redux';
import App from 'App';
import './utils/i18n';

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);
