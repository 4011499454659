import {Layout, Menu, BackTop} from 'antd';
import {Link} from 'react-router-dom';
import {useAuth} from 'providers/AuthProvider';
import {UserOutlined, GlobalOutlined} from '@ant-design/icons';
import {useDispatch, useSelector} from 'react-redux';
import {
  changeSelectedAntDLocale,
  changeSelectedLanguage,
} from 'store/slices/sessionSlice';
import {
  authStateSelector,
  loadUserProfileByUIdAsync,
  logoutAsync,
} from 'store/slices/authSlice';
import {useDepots} from 'services/hooks/useDepots';
import {useProductTypes} from 'services/hooks/useProductTypes';
import {useTranslation} from 'react-i18next';
import {UserEditModal} from 'views/user/UserEditModal';
import {useState} from 'react';
import en_GB from 'antd/lib/locale-provider/en_GB';
import sv_SE from 'antd/lib/locale-provider/sv_SE';
import {NavbarLayout} from 'components/layout/NavbarLayout';
import {useLocation} from 'react-router-dom';
import {useEffect} from 'react';

const {Header, Content, Footer} = Layout;
const {SubMenu} = Menu;

export interface AppLayoutProps {
  children: React.ReactNode;
}

export const AppLayout = ({children}: AppLayoutProps) => {
  useProductTypes();
  useDepots();

  const location = useLocation();
  const auth = useAuth();
  const [editAccountModalVisible, setEditAccountModalVisible] = useState(false);
  const dispatch = useDispatch();
  const authState = useSelector(authStateSelector);
  const {t, i18n} = useTranslation();
  const [isDashboard, setIsDashboard] = useState<boolean>(false);

  useEffect(() => {
    if (location.pathname.startsWith('/dashboard')) {
      setIsDashboard(true);
    }
  }, [location]);

  const changeLanguageToggle = (currentLanguage: string) => {
    if (currentLanguage === 'en') {
      dispatch(changeSelectedLanguage('sv'));
      dispatch(changeSelectedAntDLocale(sv_SE));
      i18n.changeLanguage('sv');
    } else {
      dispatch(changeSelectedLanguage('en'));
      dispatch(changeSelectedAntDLocale(en_GB));
      i18n.changeLanguage('en');
    }
  };

  return (
    <Layout>
      <Header
        style={{
          position: 'fixed',
          zIndex: 999,
          width: '100%',
          visibility: isDashboard ? 'hidden' : 'visible',
        }}>
        <Menu theme="dark" mode="horizontal" selectedKeys={[]}>
          {auth.currentUser && <NavbarLayout />}
          <Menu.Item
            style={{float: 'right', width: '80px', paddingLeft: '4px'}}
            key="language"
            icon={<GlobalOutlined />}
            title={i18n.language === 'en' ? 'English' : 'Svenska'}
            onClick={() => changeLanguageToggle(i18n.language)}>
            <span>{i18n.language === 'en' ? 'English' : 'Svenska'}</span>
          </Menu.Item>
          {auth.currentUser ? (
            <SubMenu
              style={{float: 'right'}}
              icon={<UserOutlined />}
              title={auth.currentUser.email}
              key="logout">
              <Menu.Item key="account-info">
                <Link
                  to={'/account'}
                  onClick={(e) => {
                    e.preventDefault();
                    setEditAccountModalVisible(true);
                  }}>
                  {t('user.profile')}
                </Link>
              </Menu.Item>
              <Menu.Item key="change-email">
                <Link to={'/change-email'}>{t('menu.user.changeEmail')}</Link>
              </Menu.Item>
              <Menu.Item key="change-password">
                <Link to={'/change-password'}>
                  {t('menu.user.changePassword')}
                </Link>
              </Menu.Item>
              <Menu.Divider />
              <Menu.Item
                key="logout"
                onClick={() => {
                  dispatch(logoutAsync(t('user.goodbye')));
                }}>
                <span>{t('menu.user.logout')}</span>
              </Menu.Item>
            </SubMenu>
          ) : (
            <Menu.Item
              icon={<UserOutlined />}
              key="login"
              style={{float: 'right'}}>
              <Link to={'/login'}>{t('menu.user.login')}</Link>
            </Menu.Item>
          )}
        </Menu>
      </Header>
      <Content className="site-layout-background">
        {children}
        <BackTop />
      </Content>
      <Footer
        style={{
          background: '#001529',
          bottom: 0,
          position: 'fixed',
          zIndex: 10,
          width: '100%',
          height: '68px',
          textAlign: 'center',
          visibility: isDashboard ? 'hidden' : 'visible',
        }}>
        <span style={{color: 'rgba(255, 255, 255, 0.65)'}}>
          &copy; {new Date().getFullYear()} - {t('appName')}
        </span>
      </Footer>
      {!authState.isLoadingProfile && authState.user && (
        <UserEditModal
          onSave={() => {
            if (authState.user && authState.user.id)
              dispatch(loadUserProfileByUIdAsync(authState.user.id));
          }}
          onClose={() => {
            setEditAccountModalVisible(false);
          }}
          visible={editAccountModalVisible}
          user={authState.user}
        />
      )}
    </Layout>
  );
};
