import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {message} from 'antd';
import {firebase} from 'services/firebase';
import {AppThunk, RootState} from 'store/index';
import {User, USERS_COLLECTION} from 'store/slices/usersSlice';
import {ChangePasswordFormValues} from 'views/auth/ChangePasswordView';
import {ChangeEmailFormValues} from 'views/auth/ChangeEmailView';
import i18next from 'i18next';
import i18n from 'utils/i18n';
import {changeSelectedCompany} from './sessionSlice';
import {Company} from './companiesSlice';

export interface AuthState {
  isLoadingProfile: boolean;
  isAuthenticated: boolean;
  isAuthenticating: boolean;
  hasError: boolean;
  error?: string;
  user: User | null;
  isResettingPassword: boolean;
  isPasswordReset: boolean;
  isEmailVerified: boolean;
}

const initialState = {
  isLoadingProfile: false,
  isAuthenticated: false,
  isAuthenticating: false,
  hasError: false,
  error: '',
  user: null,
  isResettingPassword: false,
  isPasswordReset: false,
  isEmailVerified: true,
} as AuthState;

export const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    authenticating: (state) => {
      state.isAuthenticating = true;
    },
    hasError: (state, action: PayloadAction<string>) => {
      state.hasError = true;
      state.error = `${action.payload}`;
      state.isAuthenticating = false;
      state.isLoadingProfile = false;
    },
    authenticated: (state) => {
      state.isAuthenticating = false;
      state.isAuthenticated = true;
      state.hasError = false;
      state.error = '';
    },
    startLoadingProfile: (state) => {
      state.isLoadingProfile = true;
      state.user = null;
    },
    profileLoaded: (state, action: PayloadAction<User>) => {
      state.isLoadingProfile = false;
      state.hasError = false;
      state.error = '';
      state.user = action.payload;
    },
    logout: (state) => {
      state.hasError = false;
      state.isAuthenticating = false;
      state.user = null;
      state.isAuthenticated = false;
    },
    resettingPassword: (state) => {
      state.isResettingPassword = true;
      state.isPasswordReset = false;
      state.hasError = false;
      state.error = '';
    },
    passwordReset: (state) => {
      state.isResettingPassword = false;
      state.isPasswordReset = true;
      state.hasError = false;
      state.error = '';
    },
    setEmailVerified: (state, action: PayloadAction<boolean>) => {
      state.isEmailVerified = action.payload;
    },
  },
});

const {
  startLoadingProfile,
  authenticating,
  hasError,
  profileLoaded,
  resettingPassword,
  passwordReset,
} = authSlice.actions;
export const {authenticated, logout, setEmailVerified} = authSlice.actions;

/**
 * Login async
 * @param email
 * @param password
 */
export const loginAsync = (email: string, password: string): AppThunk => async (
  dispatch
) => {
  try {
    dispatch(setEmailVerified(true));
    dispatch(authenticating());
    const res = await firebase
      .auth()
      .signInWithEmailAndPassword(email, password);
    if (res.user && res.user.emailVerified) {
      message.info(i18next.t('user.welcomeUser', {user: res.user?.email}));
      await firebase.functions().httpsCallable('increaseLogin')();
    }
  } catch (error) {
    message.error(i18n.t(`auth.error.${error.code}`));
    dispatch(hasError(error.code));
  }
};

/**
 * Load User Profile By uid
 */
export const loadUserProfileByUIdAsync = (uid: string): AppThunk => async (
  dispatch
) => {
  try {
    dispatch(startLoadingProfile());
    const doc = await firebase
      .firestore()
      .collection(USERS_COLLECTION)
      .doc(uid)
      .get();
    if (doc.exists && doc.data() != null) {
      const user: User = {
        id: doc.id,
        firstName: doc.data()?.firstName,
        lastName: doc.data()?.lastName,
        email: doc.data()?.email,
        phoneNumber: doc.data()?.phoneNumber,
        notificationsByEmail: doc.data()?.notificationsByEmail,
        notificationsBySms: doc.data()?.notificationsBySms,
        lastLoginAt: doc.data()?.lastLoginAt?.toMillis() ?? null,
        loginCount: doc.data()?.loginCount ?? 0,
        role: doc.data()?.role,
        depots: doc.data()?.depots,
        productTypes: doc.data()?.productTypes,
        emailVerified: doc.data()?.emailVerified,
        hauliers: doc.data()?.hauliers ?? [],
        company: doc.data()?.company,
        phoneNumbers: doc.data()?.phoneNumbers ?? []
      } as User;
      dispatch(profileLoaded(user));
      if (user && user.company) {
        dispatch(
          changeSelectedCompany({
            name: user.company?.name,
            id: user.company?.id,
          } as Company)
        );
      }
    } else {
      dispatch(hasError(i18next.t('error.problemLoadingProfileData')));
      message.error(i18next.t('error.problemLoadingProfileData'));
    }
  } catch (error) {
    dispatch(hasError(i18next.t('error.unexpectedError')));
    message.error(i18next.t('error.problemLoadingProfileData'));
  }
};

/**
 * Logout async
 */
export const logoutAsync = (msg?: string): AppThunk => async (dispatch) => {
  try {
    await firebase.auth().signOut();
    dispatch(logout());
    if (msg) {
      message.info(msg);
    }
  } catch (error) {
    dispatch(hasError(error.message.toString()));
  }
};

/**
 * Change Pass async
 */

export const changePasswordAsync = (
  values: ChangePasswordFormValues
): AppThunk => async (dispatch) => {
  try {
    const user = firebase.auth().currentUser;
    await user?.updatePassword(values.password);
    dispatch(logoutAsync(i18next.t('auth.passwordSuccessfullyUpdated')));
  } catch (error) {
    message.error(error.message.toString());
    dispatch(hasError(error.message.toString()));
  }
};

/**
 * Send reset password email async
 */
export const sendPasswordResetEmailAsync = (
  email: string,
  lang: string,
  successMessage: string
): AppThunk => async (dispatch) => {
  try {
    firebase.auth().languageCode = lang;
    await firebase.auth().sendPasswordResetEmail(email);
    message.info(successMessage, 10);
  } catch (error) {
    message.error(error.message.toString(), 5);
    dispatch(hasError(error.message.toString()));
  }
};

/**
 * Verify password reset code async
 */
export const verifyPasswordResetCodeAsync = (
  actionCode: any
): AppThunk => async (dispatch) => {
  try {
    await firebase.auth().verifyPasswordResetCode(actionCode);
  } catch (error) {
    message.error(error.message.toString(), 30);
    message.error(i18next.t('error.pleaseTryAgain'), 5);
    dispatch(hasError(error.message.toString()));
  }
};

/**
 * Reset password async
 */
export const resetPasswordAsync = (
  actionCode: any,
  newPassword: string,
  successMessage: string
): AppThunk => async (dispatch) => {
  try {
    dispatch(resettingPassword());
    await firebase.auth().confirmPasswordReset(actionCode, newPassword);
    message.success(successMessage, 5);
    dispatch(passwordReset());
  } catch (error) {
    message.error(error.message.toString(), 5);
    dispatch(hasError(error.message.toString()));
  }
};

export const authStateSelector = (state: RootState): AuthState => state.auth;

export default authSlice.reducer;

/**
 * Get email by actioncode async
 * @return
 */
export const getEmailByActionCode = async (
  actionCode: string
): Promise<string | undefined> => {
  const info = await firebase.auth().checkActionCode(actionCode);
  return info.data.email?.toString();
};

/**
 * Send email verification async
 * @return
 */
export const sendEmailVerificationAsync = async (
  email: string,
  messagePart1: string,
  messagePart2: string,
  subject: string
) => {
  try {
    const res = await firebase
      .functions()
      .httpsCallable('getEmailVerificationLink')(email);
    const msg = messagePart1 + res.data.toString() + messagePart2;
    await firebase.functions().httpsCallable('sendEmailVerification')({
      to: email,
      from: 'noreply@depastoppsportalen.se',
      subject: subject,
      text: msg,
      html: msg,
    });
  } catch (error) {
    message.error(error.message.toString(), 5);
  }
};

/**
 * Change Email async
 */
export const changeEmailAsync = (
  values: ChangeEmailFormValues
): AppThunk => async (dispatch) => {
  const user = firebase.auth().currentUser;
  const userId = user?.uid;
  const userOldEmail = user?.email;

  if (values.email === userOldEmail)
    return message.warning('You should to choose new email!');

  try {
    await user?.updateEmail(values.email);
    await firebase
      .firestore()
      .collection(USERS_COLLECTION)
      .doc(userId)
      .update({email: firebase.auth().currentUser?.email});
    dispatch(logoutAsync(i18next.t('change-email.emailSuccessfullyUpdated')));
  } catch (error) {
    message.error(error.message.toString());
    dispatch(hasError(error.message.toString()));
  }
};

/**
 * Revert Email async
 */
export const revertEmailAsync = async (email: string) => {
  try {
    await firebase.functions().httpsCallable('revertEmailAsync')(email);
  } catch (error) {
    message.error(error.message.toString());
  }
};
