import {firebase} from 'services/firebase';

/**
 * Send a test SMS
 * @return
 */
export const sendSMS = async (phoneNumber: string, message: string) => {
  return await firebase.functions().httpsCallable('sendTestSMS')({
    phoneNumber: phoneNumber,
    messageBody: message,
  });
};
