import React from 'react';
import {Divider, Typography} from 'antd';
import {Environment} from 'views/user/dev/Environment';
import {authStateSelector} from 'store/slices/authSlice';
import {useSelector} from 'react-redux';
import {getBrowserTimezone} from 'utils/helpers';
import {ViewHeader} from 'components/layout/ViewHeader';
import {EditProfileView} from './dev/EditProfileView';
import {useTranslation} from 'react-i18next';

const {Paragraph} = Typography;

export const AccountInfoView = (): JSX.Element => {
  const authState = useSelector(authStateSelector);
  const {t} = useTranslation();
  return (
    <div className="site-main-content" style={{padding: '16px'}}>
      <ViewHeader
        title={t('user.profile')}
        subTitle={authState.user?.firstName ?? 'n/a'}
        extra={[
          <EditProfileView
            key={'edit-profile-modal'}
            userId={authState.user?.id}
          />,
        ]}
      />
      <Divider />
      <div style={{paddingLeft: '18px'}}>
        <Paragraph>{JSON.stringify(getBrowserTimezone())}</Paragraph>
        <Paragraph>Firstname: {authState.user?.firstName ?? 'n/a'}</Paragraph>
        <Paragraph>Lastname: {authState.user?.lastName ?? 'n/a'}</Paragraph>
        <Paragraph copyable={{tooltips: true}}>{authState.user?.id}</Paragraph>
        <Paragraph>
          Role: <strong>{authState?.user?.role}</strong>
        </Paragraph>
        <Paragraph copyable={{tooltips: true}}>
          {authState.user?.email}
        </Paragraph>
        <Paragraph>
          Phone Number: {authState.user?.phoneNumber ?? 'n/a'}
        </Paragraph>
        <Paragraph>
          Notifications by email:{' '}
          {authState.user?.notificationsByEmail ? 'Yes' : 'NO'}
        </Paragraph>
        <Paragraph>
          Notifications by sms:{' '}
          {authState.user?.notificationsBySms ? 'Yes' : 'NO'}
        </Paragraph>
        <Environment />
      </div>
    </div>
  );
};
