import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AppThunk, RootState} from 'store/index';
import {firebase} from 'services/firebase';
import i18next from 'i18next';

export const PRODUCT_TYPES_COLLECTION = 'types';

export interface ProductType {
  id: string;
  name: string;
  // todo add a color we can use in <Tag>product name</Tag>
  // todo i18n support for names
}

interface ProductTypeState {
  types: ProductType[];
  hasError: boolean;
  error: string;
  isLoading: boolean;
}

const initialState = {
  types: [],
  hasError: false,
  error: '',
  isLoading: true,
} as ProductTypeState;

export const productTypesSlice = createSlice({
  name: 'productTypes',
  initialState: initialState,
  reducers: {
    updating: (state) => {
      state.isLoading = true;
      state.error = '';
      state.isLoading = false;
    },
    hasError: (state, action: PayloadAction<string>) => {
      state.hasError = true;
      state.error = i18next.t('error.anErrorOccurredEntity', {
        entity: action.payload,
      });
      state.isLoading = false;
    },
    update: (state, action: PayloadAction<ProductType[]>) => {
      state.types = action.payload;
      state.isLoading = false;
      state.error = '';
      state.hasError = false;
    },
  },
});

/**
 * Load Product Types
 */
export const loadProductTypesAsync = (): AppThunk => async (dispatch) => {
  try {
    dispatch(updating());
    const querySnapshot = await firebase
      .firestore()
      .collection(PRODUCT_TYPES_COLLECTION)
      .get();

    const productTypes = querySnapshot.docs.map((doc) => {
      return {
        id: doc.id,
        name: doc.data().name,
        // todo fix language support for name prop
      } as ProductType;
    });
    dispatch(update(productTypes));
  } catch (error) {
    dispatch(hasError(error.message.toString()));
  }
};

export const {updating, hasError, update} = productTypesSlice.actions;
export const productTypesStateSelector = (state: RootState): ProductTypeState =>
  state.productTypes;

export default productTypesSlice.reducer;
