import {StopMessage} from 'store/slices/stopMessagesSlice';
import {ColumnsType} from 'antd/lib/table';
import {Table, Typography} from 'antd';
import {Expandable} from 'components/typography/Expandable';
import {useTranslation} from 'react-i18next';
import {capitalizeFirstLetter} from 'utils/helpers';

const {Text, Paragraph} = Typography;

interface StopMessageTableProps {
  stopMessages: StopMessage[];
  loading: boolean;
  compact?: boolean;
}

export const MessageListTable = (props: StopMessageTableProps): JSX.Element => {
  const {t} = useTranslation();
  const columns: ColumnsType<any> = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
      width: 32,
      render: (_: any, stopMessage: StopMessage) => (
        <Paragraph
          copyable={{
            text: stopMessage.id,
            tooltips: stopMessage.id,
          }}
        />
      ),
    },
    {
      title: capitalizeFirstLetter(t('depot.name')),
      dataIndex: 'depot',
      key: 'depot',
      render: (_: any, stopMessage: StopMessage) =>
        `${stopMessage.depot.company.name} - ${stopMessage.depot.name}`,
      width: 120,
    },
    {
      title: t('stopMessage.startEnd'),
      dataIndex: 'startDate',
      key: 'startDate',
      render: (_: any, stopMessage: StopMessage) => {
        return (
          <>
            <Text keyboard>
              {new Date(stopMessage.startDate).toLocaleString()}
            </Text>
            <br />
            <Text keyboard>
              {new Date(stopMessage.endDate).toLocaleString()}
            </Text>
          </>
        );
      },
      sorter: (a: StopMessage, b: StopMessage) => {
        return a.startDate - b.startDate;
      },
      width: 200,
    },
    {
      title: capitalizeFirstLetter(t('stopMessage.downtime')),
      dataIndex: 'downTimeDays',
      key: 'downTimeDays',
      render: (_: any, stopMessage: StopMessage) => {
        return <i>{stopMessage.downTimeDays} days</i>;
      },
      sorter: (a: StopMessage, b: StopMessage) => {
        return a.downTimeMinutes - b.downTimeMinutes;
      },
      width: 100,
    },
    {
      title: capitalizeFirstLetter(t('communication.message')),
      dataIndex: 'message',
      key: 'message',
      ellipsis: false,
      render: (_: any, stopMessage: StopMessage) => {
        return (
          <Expandable rows={3}>
            <strong>{t(`stopMessage.urgency.${stopMessage.urgency}`)}</strong>
            <br />
            <strong>{t(`stopMessage.subject.${stopMessage.subject}`)}</strong>
            <br />
            {stopMessage.message}
          </Expandable>
        );
      },
    },
    {
      title: capitalizeFirstLetter(t('product.products')),
      dataIndex: 'products',
      key: 'products',
      width: 250,
      ellipsis: false,
      render: (_: any, stopMessage: StopMessage) => {
        return (
          <Expandable rows={3}>
            {stopMessage.isConcerningAllProducts ? 'All products' : ''}
            {stopMessage.products.map((p) => (
              <span key={p.id}>
                {p.name}
                <br />
              </span>
            ))}
          </Expandable>
        );
      },
    },
    {
      title: t('user.createdBy'),
      dataIndex: 'createdBy',
      key: 'createdBy',
      width: 150,
      render: (_: any, stopMessage: StopMessage) => {
        return (
          <Text type="secondary" key={stopMessage.id}>
            {stopMessage.createdBy?.name}
          </Text>
        );
      },
    },
  ];

  const columnsCompact: ColumnsType<any> = [
    {
      title: capitalizeFirstLetter(t('depot.name')),
      dataIndex: 'depot',
      key: 'depot',
      render: (_: any, stopMessage: StopMessage) =>
        `${stopMessage.depot.company.name} - ${stopMessage.depot.name}`,
      width: 260,
    },
    {
      title: t('stopMessage.startEnd'),
      dataIndex: 'startDate',
      key: 'startDate',
      render: (_: any, stopMessage: StopMessage) => {
        return (
          <>
            <Text keyboard>
              {new Date(stopMessage.startDate).toLocaleString()}
            </Text>
            <br />
            <Text keyboard>
              {new Date(stopMessage.endDate).toLocaleString()}
            </Text>
          </>
        );
      },
      defaultSortOrder: 'descend',
      sorter: (a: StopMessage, b: StopMessage) => {
        return a.startDate - b.startDate;
      },
      width: 200,
    },
    {
      title: capitalizeFirstLetter(t('stopMessage.downtime')),
      dataIndex: 'downTimeDays',
      key: 'downTimeDays',
      render: (_: any, stopMessage: StopMessage) => {
        return <i>{stopMessage.downTimeDays} days</i>;
      },
      sorter: (a: StopMessage, b: StopMessage) => {
        return a.downTimeMinutes - b.downTimeMinutes;
      },
      width: 100,
    },
    {
      title: capitalizeFirstLetter(t('communication.message')),
      dataIndex: 'message',
      key: 'message',
      ellipsis: false,
      render: (_: any, stopMessage: StopMessage) => {
        return (
          <Expandable rows={3}>
            <strong>{t(`stopMessage.urgency.${stopMessage.urgency}`)} - {t(`stopMessage.subject.${stopMessage.subject}`)}</strong>
            <br />
            {stopMessage.message}
          </Expandable>
        );
      },
    },
    {
      title: capitalizeFirstLetter(t('product.products')),
      dataIndex: 'products',
      key: 'products',
      width: 250,
      ellipsis: false,
      render: (_: any, stopMessage: StopMessage) => {
        return (
          <Expandable rows={3}>
            {stopMessage.isConcerningAllProducts ? 'All products' : ''}
            {stopMessage.products.map((p) => (
              <span key={p.id}>
                {p.name}
                <br />
              </span>
            ))}
          </Expandable>
        );
      },
    },
  ];

  return (
    <Table<StopMessage>
      style={props.compact ? {minHeight: '50vh'} : undefined}
      size={props.compact ? "small" : "middle"}
      pagination={props.compact ? false : undefined}
      rowKey={'id'}
      loading={props.loading}
      dataSource={props.stopMessages}
      columns={props.compact ? columnsCompact : columns}
    />
  );
};
