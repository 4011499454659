import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {COMPANIES_COLLECTION} from 'store/slices/companiesSlice';
import {firebase} from 'services/firebase';
import {AppThunk, RootState} from 'store/index';
import {Depot, DepotLight, DEPOTS_COLLECTION} from 'store/slices/depotsSlice';
import {v4 as uuidv4} from 'uuid';
import {StopMessageFormValues} from 'components/form/StopMessageForm';
import {message} from 'antd';
import {auditLog, Downtime, downTimeSummary, userToLight} from 'utils/helpers';
import {ProductLight} from 'store/slices/productSlice';
import {UserLight} from 'store/slices/usersSlice';
import {STOP_MESSAGE_STATUS} from 'constants/stopMessage';
import i18next from 'i18next';
import {
  clearScheduledReminders,
  createScheduledRemindersAsync,
  replaceScheduledRemindersAsync,
  ScheduledReminder,
} from './reminderSlice';

export const STOP_MESSAGES_COLLECTION = 'stopMessages';

export interface StopMessage {
  id: string;
  depot: Depot;
  message: string;
  status: string;
  subject: string;
  urgency: string;
  startDate: number;
  endDate: number;
  sendLaterDate: number;
  downTimeDays: number;
  downTimeHours: number;
  downTimeMinutes: number;
  createdAt: number;
  products: ProductLight[];
  replacementDepots: DepotLight[];
  createdBy?: UserLight;
  isConcerningAllProducts: boolean;
  isDisturbanceInformation: boolean;
  isPatch: boolean;
  deleted: boolean;
  queued?: boolean;
  languageCulture?: string;
  updated?: boolean;
  completed?: boolean;
  hasStakeholdersBeenNotified?: boolean;
}

interface StopMessagesState {
  stopMessages: StopMessage[];
  activeStopMessages: StopMessage[];
  hasError: boolean;
  error: string;
  isLoading: boolean;
}

const initialState = {
  stopMessages: [],
  activeStopMessages: [],
  hasError: false,
  error: '',
  isLoading: false,
} as StopMessagesState;

export const stopMessagesSlice = createSlice({
  name: 'stopMessages',
  initialState: initialState,
  reducers: {
    clearStops: (state) => {
      state.stopMessages = [];
    },
    updating: (state) => {
      state.stopMessages = [];
      state.isLoading = true;
    },
    hasError: (state, action: PayloadAction<string>) => {
      state.hasError = true;
      state.error = i18next.t('error.anErrorOccurredEntity', {
        entity: action.payload,
      });
      state.isLoading = false;
    },
    update: (state, action: PayloadAction<StopMessage[]>) => {
      state.stopMessages = action.payload;
      state.isLoading = false;
    },
    updatingAllActiveStops: (state) => {
      state.isLoading = true;
      state.activeStopMessages = [];
    },
    updateAllActiveStops: (state, action: PayloadAction<StopMessage[]>) => {
      state.activeStopMessages = action.payload;
      state.isLoading = false;
    },
  },
});

export const dataToStopMessage = (
  doc: firebase.firestore.QueryDocumentSnapshot
): StopMessage => {
  return {
    id: doc.id,
    depot: doc.data().depot,
    message: doc.data().message,
    status: doc.data().status,
    subject: doc.data().subject,
    urgency: doc.data().urgency,
    startDate: doc.data().startDate.toMillis(),
    endDate: doc.data().endDate.toMillis(),
    sendLaterDate: doc.data().sendLaterDate
      ? doc.data().sendLaterDate.toMillis()
      : 0,
    downTimeDays: doc.data().downTimeDays,
    downTimeHours: doc.data().downTimeHours,
    downTimeMinutes: doc.data().downTimeMinutes,
    createdAt: doc.data().createdAt.toMillis(),
    createdBy: doc.data().createdBy,
    products: doc.data().products,
    replacementDepots: doc.data().replacementDepots,
    isConcerningAllProducts: doc.data().isConcerningAllProducts
      ? doc.data().isConcerningAllProducts
      : false,
    isDisturbanceInformation: doc.data().isDisturbanceInformation
      ? doc.data().isDisturbanceInformation
      : false,
    isPatch: doc.data().isPatch ? doc.data().isPatch : false,
    deleted: doc.data().deleted ? doc.data().deleted : false,
    queued: doc.data().queued ? doc.data().queued : null,
    languageCulture: doc.data().languageCulture
      ? doc.data().languageCulture
      : null,
    updated: doc.data().updated ? doc.data().updated : false,
    completed: doc.data().completed ? doc.data().completed : false,
    hasStakeholdersBeenNotified: doc.data().hasStakeholdersBeenNotified
      ? doc.data().hasStakeholdersBeenNotified
      : false,
  } as StopMessage;
};

export const getStopMessageRef = (sm: StopMessage) => {
  return firebase
    .firestore()
    .collection(COMPANIES_COLLECTION)
    .doc(sm.depot.company.id)
    .collection(DEPOTS_COLLECTION)
    .doc(sm.depot.id)
    .collection(STOP_MESSAGES_COLLECTION)
    .doc(sm.id);
};

export interface CompanyDepot {
  company: string;
  depot: string;
}

/**
 * Loads depot stops status = INACTIVE by a list of depotIds (will fetch cross company)
 * @param companyDepots
 */
export const loadStopsByDepotIds = (
  companyDepots: CompanyDepot[]
): AppThunk => async (dispatch) => {
  try {
    dispatch(updating());
    const promises: Promise<
      firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
    >[] = companyDepots.map((cd) =>
      createCompanyDepotStopsQuery(cd.company, cd.depot)
        .where('status', 'in', ['INACTIVE'])
        .get()
    );
    const querySnapshots = await Promise.all(promises);
    const stops = querySnapshots
      .map((qs) => qs.docs.map((d) => dataToStopMessage(d)))
      .flat();
    dispatch(update(stops));
  } catch (error: any) {
    dispatch(hasError(error.message.toString()));
  }
};

export const createCompanyDepotStopsQuery = (
  companyId: string,
  depotId: string
) => {
  return firebase
    .firestore()
    .collection(COMPANIES_COLLECTION)
    .doc(companyId)
    .collection(DEPOTS_COLLECTION)
    .doc(depotId)
    .collection(STOP_MESSAGES_COLLECTION);
};

/**
 * Load stop messages by companyId & depotID
 */
export const loadStopMessagesAsync = (
  companyId: string,
  depotId: string
): AppThunk => async (dispatch) => {
  try {
    dispatch(updating());
    const querySnapshot = await createCompanyDepotStopsQuery(
      companyId,
      depotId
    ).get();

    const stopMessages = querySnapshot.docs.map((doc) => {
      return dataToStopMessage(doc);
    });
    dispatch(update(stopMessages));
  } catch (error: any) {
    message.error(i18next.t('stopMessage.error.couldntLoad'));
    dispatch(hasError(error.message.toString()));
  }
};

/**
 * Load Active Stop Messages in the system. (Also Drafts)
 * status != INACTIVE instead of endDate > new Date()
 * Tips: Use selectors to filter out by company
 */
export const loadAllActiveStopMessages = (): AppThunk => async (dispatch) => {
  try {
    dispatch(updatingAllActiveStops());
    const querySnapshot = await firebase
      .firestore()
      .collectionGroup(STOP_MESSAGES_COLLECTION)
      .where('status', 'in', ['SEND_NOW', 'SEND_LATER', 'DRAFT'])
      .get();

    const activeStopMessages = querySnapshot.docs.map((doc) => {
      return dataToStopMessage(doc);
    });
    dispatch(updateAllActiveStops(activeStopMessages));
  } catch (error: any) {
    message.error(i18next.t('stopMessage.error.couldntLoadActive'));
    dispatch(hasError(error.message.toString()));
  }
};

/**
 * Create a new Stop Message for given Depot
 * @param stopMessageFormValues
 * @param depot
 * @param scheduledReminders
 */
export const createStopMessageAsync = (
  stopMessageFormValues: StopMessageFormValues,
  depot: Depot,
  scheduledReminders: ScheduledReminder[]
): AppThunk => async (dispatch, getState) => {
  try {
    const creator = getState().auth.user;
    const language = getState().session.selectedLanguage;

    if (!creator) {
      throw new Error(i18next.t('stopMessage.error.couldntCreate'));
    }

    console.log(stopMessageFormValues);

    const downTime: Downtime = downTimeSummary(
      stopMessageFormValues.startDate.toDate(),
      stopMessageFormValues.endDate.toDate()
    );

    const startStamp = firebase.firestore.Timestamp.fromDate(
      stopMessageFormValues.startDate.toDate()
    );
    const endStamp = firebase.firestore.Timestamp.fromDate(
      stopMessageFormValues.endDate.toDate()
    );

    const sendLaterStamp =
      stopMessageFormValues.status === 'SEND_LATER'
        ? firebase.firestore.Timestamp.fromDate(
            stopMessageFormValues.sendLaterDate.toDate()
          )
        : firebase.firestore.FieldValue.serverTimestamp();

    const uuid = uuidv4();
    const userLight = userToLight(creator);

    const stopMessage = {
      ...stopMessageFormValues,
      depot: depot,
      startDate: startStamp,
      endDate: endStamp,
      sendLaterDate: sendLaterStamp,
      downTimeDays: downTime.days,
      downTimeHours: downTime.hours,
      downTimeMinutes: downTime.minutes,
      deleted: false,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      id: uuid,
      createdBy: userLight,
      queued: true, // this will be set to false by the cron later.
      languageCulture: language,
      updated: false,
      completed: false,
    };

    const docRef = firebase
      .firestore()
      .collection(COMPANIES_COLLECTION)
      .doc(depot.company.id)
      .collection(DEPOTS_COLLECTION)
      .doc(depot.id)
      .collection(STOP_MESSAGES_COLLECTION)
      .doc(uuid);

    await docRef.set(stopMessage);
    await auditLog(userLight, docRef, 'created');
    await createScheduledRemindersAsync(docRef, uuid, scheduledReminders);

    dispatch(loadStopMessagesAsync(depot.company.id, depot.id));
    dispatch(loadAllActiveStopMessages());
    message.info(
      i18next.t('stopMessage.successfullyEntity', {
        entity: i18next.t('genericWords.created'),
      })
    );
    dispatch(clearScheduledReminders());
    return true;
  } catch (error: any) {
    message.error(error.message.toString());
  }
};

/**
 * Update StopMessage async
 * @param stopMessageFormValues
 * @param stopMessage
 */
export const updateStopMessageAsync = (
  stopMessageFormValues: StopMessageFormValues,
  stopMessage: StopMessage,
  scheduledReminders: ScheduledReminder[]
): AppThunk => async (dispatch, getState) => {
  try {
    const currentUser = getState().auth.user;

    if (!currentUser) {
      throw new Error(i18next.t('stopMessage.error.couldntEdit'));
    }
    const downTime: Downtime = downTimeSummary(
      stopMessageFormValues.startDate.toDate(),
      stopMessageFormValues.endDate.toDate()
    );

    const startStamp = firebase.firestore.Timestamp.fromDate(
      stopMessageFormValues.startDate.toDate()
    );

    const endStamp = stopMessageFormValues.completed
      ? firebase.firestore.FieldValue.serverTimestamp()
      : firebase.firestore.Timestamp.fromDate(
          stopMessageFormValues.endDate.toDate()
        );

    let sendLaterStamp = {};
    switch (stopMessageFormValues.status) {
      case STOP_MESSAGE_STATUS[0]: {
        sendLaterStamp = firebase.firestore.Timestamp.fromMillis(
          stopMessage.createdAt
        );
        break;
      }
      case STOP_MESSAGE_STATUS[1]: {
        sendLaterStamp = firebase.firestore.FieldValue.serverTimestamp();
        break;
      }
      case STOP_MESSAGE_STATUS[2]: {
        sendLaterStamp = firebase.firestore.Timestamp.fromDate(
          stopMessageFormValues.sendLaterDate.toDate()
        );
        break;
      }
    }

    if (stopMessageFormValues.completed) {
      sendLaterStamp = firebase.firestore.FieldValue.serverTimestamp();
    }

    const updatedStopMessage = {
      ...stopMessageFormValues,
      startDate: startStamp,
      endDate: endStamp,
      sendLaterDate: sendLaterStamp,
      downTimeDays: downTime.days,
      downTimeHours: downTime.hours,
      downTimeMinutes: downTime.minutes,
      updated: true,
      queued:
        stopMessageFormValues.status !== STOP_MESSAGE_STATUS[0] ? true : false,
    };

    const userLight = userToLight(currentUser);
    const docRef = firebase
      .firestore()
      .collection(COMPANIES_COLLECTION)
      .doc(stopMessage.depot.company.id)
      .collection(DEPOTS_COLLECTION)
      .doc(stopMessage.depot.id)
      .collection(STOP_MESSAGES_COLLECTION)
      .doc(stopMessage.id);

    await docRef.update(updatedStopMessage);
    await auditLog(userLight, docRef, 'updated');
    await replaceScheduledRemindersAsync(
      docRef,
      stopMessage.id,
      scheduledReminders
    );

    dispatch(
      loadStopMessagesAsync(stopMessage.depot.company.id, stopMessage.depot.id)
    );
    dispatch(loadAllActiveStopMessages());
    message.info(
      i18next.t('stopMessage.successfullyEntity', {
        entity: i18next.t('genericWords.edited'),
      })
    );
    return true;
  } catch (error: any) {
    message.error(error.message.toString());
  }
};

export const softDeleteStopMessageAsync = (
  companyId: string,
  depotId: string,
  stopMessageId: string
): AppThunk => async (dispatch) => {
  try {
    const depotRef = firebase
      .firestore()
      .collection(COMPANIES_COLLECTION)
      .doc(companyId)
      .collection(DEPOTS_COLLECTION)
      .doc(depotId)
      .collection(STOP_MESSAGES_COLLECTION)
      .doc(stopMessageId);
    await depotRef.update({deleted: true});
    dispatch(loadStopMessagesAsync(companyId, depotId));
    dispatch(loadAllActiveStopMessages());
    message.info(
      i18next.t('stopMessage.successfullyEntity', {
        entity: i18next.t('genericWords.deleted'),
      })
    );
  } catch (error: any) {
    message.error(error.message.toString());
  }
};

export const archiveStopMessageAsync = (
  companyId: string,
  depotId: string,
  stopMessageId: string
): AppThunk => async (dispatch) => {
  try {
    const depotRef = firebase
      .firestore()
      .collection(COMPANIES_COLLECTION)
      .doc(companyId)
      .collection(DEPOTS_COLLECTION)
      .doc(depotId)
      .collection(STOP_MESSAGES_COLLECTION)
      .doc(stopMessageId);
    await depotRef.update({status: 'INACTIVE'});
    dispatch(loadStopMessagesAsync(companyId, depotId));
    dispatch(loadAllActiveStopMessages());
    message.info(
      i18next.t('stopMessage.successfullyEntity', {
        entity: i18next.t('genericWords.archived'),
      })
    );
  } catch (error: any) {
    message.error(error.message.toString());
  }
};

export const sendNowStopMessageAsync = (
  stopMessage: StopMessage,
  statusMessage?: string
): AppThunk => async (dispatch, getState) => {
  try {
    const currentUser = getState().auth.user;

    if (!currentUser) {
      throw new Error(i18next.t('stopMessage.error.couldntEdit'));
    }
    let sendLaterStamp = firebase.firestore.FieldValue.serverTimestamp();
    const docRef = firebase
      .firestore()
      .collection(COMPANIES_COLLECTION)
      .doc(stopMessage.depot.company.id)
      .collection(DEPOTS_COLLECTION)
      .doc(stopMessage.depot.id)
      .collection(STOP_MESSAGES_COLLECTION)
      .doc(stopMessage.id);

    if (statusMessage) {
      await docRef.update({
        sendLaterDate: sendLaterStamp,
        status: statusMessage,
        queued: true,
      });
    } else {
      await docRef.update({sendLaterDate: sendLaterStamp, queued: true});
    }
    dispatch(
      loadStopMessagesAsync(stopMessage.depot.company.id, stopMessage.depot.id)
    );
    dispatch(loadAllActiveStopMessages());
    message.info(i18next.t('stopMessage.hasBeenSent'));
    return true;
  } catch (error: any) {
    message.error(error.message.toString());
  }
};

const {
  updating,
  hasError,
  update,
  updatingAllActiveStops,
  updateAllActiveStops,
} = stopMessagesSlice.actions;

export const stopMessageSelector = (state: RootState): StopMessagesState =>
  state.stopMessages;

export const getEndedStopMessagesSelector = (state: RootState) => {
  return state.stopMessages.stopMessages.filter(
    (stop) => stop.status === 'INACTIVE'
  );
};

export default stopMessagesSlice.reducer;
