import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {COMPANIES_COLLECTION} from 'store/slices/companiesSlice';
import {firebase} from 'services/firebase';
import {AppThunk, RootState} from 'store/index';
import {Depot, DEPOTS_COLLECTION} from 'store/slices/depotsSlice';
import moment from 'moment';
import {v4 as uuidv4} from 'uuid';
import {InfoMessageFormValues} from 'components/form/InfoMessageForm';
import {message} from 'antd';
import {auditLog, userToLight} from 'utils/helpers';
import {ProductLight} from 'store/slices/productSlice';
import {UserLight} from 'store/slices/usersSlice';
import i18next from 'i18next';
import {CompanyDepot} from 'store/slices/stopMessagesSlice';

export const INFO_MESSAGES_COLLECTION = 'infoMessages';

export interface InfoMessage {
  id: string;
  depot: Depot;
  message: string;
  startDate: number;
  endDate: number;
  createdAt: number;
  products: ProductLight[];
  sendTo: string[];
  createdBy?: UserLight;
  deleted: boolean;
  queued?: boolean;
  languageCulture?: string;
}

interface InfoMessagesState {
  infoMessages: InfoMessage[]; // per depot
  activeInfoMessages: InfoMessage[]; // all active stops in the system
  hasError: boolean;
  error: string;
  isLoading: boolean;
}

const initialState = {
  infoMessages: [],
  activeInfoMessages: [],
  hasError: false,
  error: '',
  isLoading: false,
} as InfoMessagesState;

export const infoMessagesSlice = createSlice({
  name: 'infoMessages',
  initialState: initialState,
  reducers: {
    clearInfoMessages: (state) => {
      state.infoMessages = [];
    },
    updating: (state) => {
      state.infoMessages = [];
      state.isLoading = true;
    },
    hasError: (state, action: PayloadAction<string>) => {
      state.hasError = true;
      state.error = i18next.t('error.anErrorOccurredEntity', {
        entity: action.payload,
      });
      state.isLoading = false;
    },
    update: (state, action: PayloadAction<InfoMessage[]>) => {
      state.infoMessages = action.payload;
      state.isLoading = false;
    },
    updatingAllActiveInfoMessages: (state) => {
      state.isLoading = true;
      state.activeInfoMessages = [];
    },
    updateAllActiveInfoMessages: (
      state,
      action: PayloadAction<InfoMessage[]>
    ) => {
      state.activeInfoMessages = action.payload;
      state.isLoading = false;
    },
  },
});

const dataToInfoMessage = (
  doc: firebase.firestore.QueryDocumentSnapshot
): InfoMessage => {
  return {
    id: doc.id,
    depot: doc.data().depot,
    message: doc.data().message,
    startDate: doc.data().startDate.toMillis(),
    endDate: doc.data().endDate.toMillis(),
    createdAt: doc.data().createdAt.toMillis(),
    createdBy: doc.data().createdBy,
    products: doc.data().products,
    sendTo: doc.data().sendTo,
    deleted: doc.data().deleted ? doc.data().deleted : false,
    queued: doc.data().queued ? doc.data().queued : null,
    languageCulture: doc.data().languageCulture
      ? doc.data().languageCulture
      : null,
  } as InfoMessage;
};

export const getInfoMessageRef = (sm: InfoMessage) => {
  const docRef = firebase
    .firestore()
    .collection(COMPANIES_COLLECTION)
    .doc(sm.depot.company.id)
    .collection(DEPOTS_COLLECTION)
    .doc(sm.depot.id)
    .collection(INFO_MESSAGES_COLLECTION)
    .doc(sm.id);

  return docRef;
};
/**
 * Load stop messages by depotID
 */
export const loadInfoMessagesAsync = (
  companyId: string,
  depotId: string
): AppThunk => async (dispatch) => {
  try {
    dispatch(updating());
    const docRef = firebase
      .firestore()
      .collection(COMPANIES_COLLECTION)
      .doc(companyId)
      .collection(DEPOTS_COLLECTION)
      .doc(depotId)
      .collection(INFO_MESSAGES_COLLECTION);

    const querySnapshot = await docRef.get();

    const infoMessages = querySnapshot.docs.map((doc) => {
      return dataToInfoMessage(doc);
    });
    dispatch(update(infoMessages));
  } catch (error) {
    message.error(i18next.t('infoMessage.error.couldntLoad'));
    dispatch(hasError(error.message.toString()));
  }
};

export const createCompanyDepotInfoMessageQuery = (
  companyId: string,
  depotId: string
) => {
  return firebase
    .firestore()
    .collection(COMPANIES_COLLECTION)
    .doc(companyId)
    .collection(DEPOTS_COLLECTION)
    .doc(depotId)
    .collection(INFO_MESSAGES_COLLECTION);
};

export const loadInfoMessagesByDepotIds = (
  companyDepots: CompanyDepot[]
): AppThunk => async (dispatch) => {
  try {
    dispatch(updating());
    const promises: Promise<
      firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
    >[] = companyDepots.map((cd) =>
      createCompanyDepotInfoMessageQuery(cd.company, cd.depot).get()
    );
    const querySnapshots = await Promise.all(promises);
    const infoMessages = querySnapshots
      .map((qs) => qs.docs.map((d) => dataToInfoMessage(d)))
      .flat();
    dispatch(update(infoMessages));
  } catch (error) {
    dispatch(hasError(error.message.toString()));
  }
};

/**
 * Load Active Stop Messages in the system. (Also Drafts)
 * Tips: Use selectors to filter out by company
 */
export const loadAllActiveInfoMessages = (): AppThunk => async (dispatch) => {
  try {
    dispatch(updatingAllActiveInfoMessages());
    const querySnapshot = await firebase
      .firestore()
      .collectionGroup(INFO_MESSAGES_COLLECTION)
      .where('endDate', '>=', new Date())
      .get();

    const activeInfoMessages = querySnapshot.docs.map((doc) => {
      return dataToInfoMessage(doc);
    });
    dispatch(updateAllActiveInfoMessages(activeInfoMessages));
  } catch (error) {
    message.error(i18next.t('infoMessage.error.couldntLoadActive'));
    console.log(error.message.toString());
    dispatch(hasError(error.message.toString()));
  }
};

/**
 * Create a new Stop Message for given Depot
 * @param infoMessageFormValues
 * @param depot
 */
export const createInfoMessageAsync = (
  infoMessageFormValues: InfoMessageFormValues,
  depot: Depot
): AppThunk => async (dispatch, getState) => {
  try {
    const creator = getState().auth.user;
    const language = getState().session.selectedLanguage;

    if (!creator) {
      throw new Error(i18next.t('infoMessage.error.couldntCreate'));
    }
    const startStamp = firebase.firestore.Timestamp.fromDate(
      infoMessageFormValues.startDate.toDate()
    );
    const endStamp = firebase.firestore.Timestamp.fromDate(
      infoMessageFormValues.endDate.toDate()
    );

    const uuid = uuidv4();
    const userLight = userToLight(creator);

    const infoMessage = {
      ...infoMessageFormValues,
      depot: depot,
      startDate: startStamp,
      endDate: endStamp,
      deleted: false,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      id: uuid,
      createdBy: userLight,
      queued: true,
      languageCulture: language,
    };

    const docRef = firebase
      .firestore()
      .collection(COMPANIES_COLLECTION)
      .doc(depot.company.id)
      .collection(DEPOTS_COLLECTION)
      .doc(depot.id)
      .collection(INFO_MESSAGES_COLLECTION)
      .doc(uuid);

    await docRef.set(infoMessage);
    await auditLog(userLight, docRef, 'created');
    dispatch(loadInfoMessagesAsync(depot.company.id, depot.id));
    dispatch(loadAllActiveInfoMessages());
    message.info(
      i18next.t('infoMessage.successfullyEntity', {
        entity: i18next.t('genericWords.created'),
      })
    );
    return true;
  } catch (error) {
    message.error(error.message.toString());
  }
};

/**
 * Edit info Message for given Depot
 * @param infoMessageFormValues
 * @param infoMessage
 */
export const updateInfoMessageAsync = (
  infoMessageFormValues: InfoMessageFormValues,
  infoMessage: InfoMessage
): AppThunk => async (dispatch, getState) => {
  try {
    const currentUser = getState().auth.user;

    if (!currentUser) {
      throw new Error(i18next.t('infoMessage.error.couldntEdit'));
    }

    const startStamp = firebase.firestore.Timestamp.fromDate(
      infoMessageFormValues.startDate.toDate()
    );
    const endStamp = firebase.firestore.Timestamp.fromDate(
      infoMessageFormValues.endDate.toDate()
    );

    const updatedInfoMessage = {
      ...infoMessageFormValues,
      startDate: startStamp,
      endDate: endStamp,
      queued: true,
    };
    const userLight = userToLight(currentUser);
    const docRef = firebase
      .firestore()
      .collection(COMPANIES_COLLECTION)
      .doc(infoMessage.depot.company.id)
      .collection(DEPOTS_COLLECTION)
      .doc(infoMessage.depot.id)
      .collection(INFO_MESSAGES_COLLECTION)
      .doc(infoMessage.id);

    await docRef.update(updatedInfoMessage);
    await auditLog(userLight, docRef, 'updated');
    dispatch(
      loadInfoMessagesAsync(infoMessage.depot.company.id, infoMessage.depot.id)
    );
    dispatch(loadAllActiveInfoMessages());
    message.info(i18next.t('infoMessage.hasBeenSent'));
    return true;
  } catch (error) {
    message.error(error.message.toString());
  }
};

export const softDeleteInfoMessageAsync = (
  companyId: string,
  depotId: string,
  infoMessageId: string
): AppThunk => async (dispatch) => {
  try {
    const depotRef = firebase
      .firestore()
      .collection(COMPANIES_COLLECTION)
      .doc(companyId)
      .collection(DEPOTS_COLLECTION)
      .doc(depotId)
      .collection(INFO_MESSAGES_COLLECTION)
      .doc(infoMessageId);
    await depotRef.update({deleted: true});
    dispatch(loadInfoMessagesAsync(companyId, depotId));
    dispatch(loadAllActiveInfoMessages());
    message.info(
      i18next.t('infoMessage.successfullyEntity', {
        entity: i18next.t('genericWords.deleted'),
      })
    );
  } catch (error) {
    message.error(error.message.toString());
  }
};

const {
  updating,
  hasError,
  update,
  updatingAllActiveInfoMessages,
  updateAllActiveInfoMessages,
} = infoMessagesSlice.actions;

export const infoMessageSelector = (state: RootState): InfoMessagesState =>
  state.infoMessages;

export const getActiveInfoMessagesSelector = (state: RootState) => {
  return state.infoMessages.infoMessages.filter(
    (stop) => moment().isBefore(stop.endDate) && !stop.deleted
  );
};

export const getEndedInfoMessagesSelector = (state: RootState) => {
  return state.infoMessages.infoMessages.filter(
    (stop) => !moment().isBetween(stop.startDate, stop.endDate)
  );
};

export default infoMessagesSlice.reducer;
