import {firebase} from 'services/firebase';

/**
 * Send a test SMS
 * @return
 */
export const sendMail = async (
  emailAddress: string,
  message: string,
  reminderType?: string,
  selectedLanguage?: string
) => {
  let reminderText = '';
  if (reminderType) {
    if (reminderType === 'reminder-upcoming') {
      reminderText =
        selectedLanguage === 'English'
          ? `<strong>REMINDER</strong><br>
          <strong>This is a reminder of an upcoming terminal stop</strong>
          <br><br>`
          : `<strong>PÅMINNELSE</strong><br>
          <strong>Det här är en påminnelse om ett kommande depåstopp</strong>
          <br><br>`;
    }
    if (reminderType === 'reminder-ending') {
      reminderText =
        selectedLanguage === 'English'
          ? `<strong>REMINDER</strong><br>
          <strong>This is a reminder that the terminal stop bellow is about to end soon</strong>
          <br><br>`
          : `<strong>PÅMINNELSE</strong><br>
          <strong>Det här är en påminnelse om att stoppet nedanför är på väg att avslutas snart</strong>
          <br><br>`;
    }
  }

  return await firebase.functions().httpsCallable('sendTestMail')({
    to: [emailAddress],
    from: 'noreply@depastoppsportalen.se',
    subject: 'Test DSP',
    text: reminderText + message,
    html: reminderText + message,
  });
};
