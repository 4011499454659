import {useDispatch} from 'react-redux';
import {changePasswordAsync} from 'store/slices/authSlice';
import {Form, Input, Button} from 'antd';
import {useTranslation} from 'react-i18next';
import {Row, Col, Typography} from 'antd';
import {ViewHeader} from 'components/layout/ViewHeader';
import {PASSWORD_PATTERN} from 'utils/patterns';

const {Text} = Typography;

export type ChangePasswordFormValues = {
  password: string;
  confirm: string;
};

export const ChangePasswordView = () => {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const onFinish = (values: ChangePasswordFormValues) => {
    dispatch(changePasswordAsync(values));
  };

  return (
    <div className="site-view-welcome" style={{paddingTop: '128px'}}>
      <Row>
        <Col flex="auto" />
        <Col
          flex="300px"
          style={{background: 'white', padding: '16px', borderRadius: '8px'}}>
          <ViewHeader title={t('change-pass.changePassTitle')} />
          <Form
            className="login-form"
            name="basic"
            layout="vertical"
            initialValues={{remember: true}}
            onFinish={onFinish}>
            <Form.Item
              name="password"
              label={t('auth.newPassword')}
              rules={[
                {
                  required: true,
                  message: t('auth.pleaseConfirmPassword'),
                },
                {
                  pattern: PASSWORD_PATTERN,
                  message: t(
                    'auth.error.password.newPasswordPatternValidation'
                  ),
                },
              ]}
              hasFeedback>
              <Input.Password />
            </Form.Item>
            <Form.Item
              name="confirm"
              label={t('auth.confirmPassword')}
              dependencies={['password']}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: t('auth.pleaseConfirmPassword'),
                },
                ({getFieldValue}) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(t('auth.error.password.noMatchPassword'))
                    );
                  },
                }),
              ]}>
              <Input.Password />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{width: '100%', marginTop: '20px'}}>
                {t('change-pass.submit')}
              </Button>
            </Form.Item>
          </Form>
          <Text type="secondary">
            {t('auth.error.password.newPasswordPatternValidation')}
          </Text>
        </Col>
        <Col flex="auto" />
      </Row>
    </div>
  );
};
