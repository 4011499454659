import {Checkbox, Row, Col} from 'antd';
import {CheckboxValueType} from 'antd/lib/checkbox/Group';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {productTypesStateSelector} from 'store/slices/productTypesSlice';

interface ProductTypeMultiSelectProps {
  value?: string[];
  onChange?: (value: string[]) => void;
}

export const ProductTypeMultiSelect = (
  props: ProductTypeMultiSelectProps
): JSX.Element => {
  const {t} = useTranslation();
  const productTypesState = useSelector(productTypesStateSelector);

  const onChange = (list: CheckboxValueType[]) => {
    if (props.onChange) props.onChange(list as string[]);
  };

  return (
    <Checkbox.Group
      defaultValue={props.value ? props.value : []}
      style={{width: '100%'}}
      onChange={onChange}>
      <Row>
        {productTypesState.types.map((type, index) => (
          <Col key={index} span={6}>
            <Checkbox value={type.id}>{t(`productType.${type.name}`)}</Checkbox>
          </Col>
        ))}
      </Row>
    </Checkbox.Group>
  );
};
