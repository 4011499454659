import {useEffect, useState, useMemo} from 'react';
import moment from 'moment';
import {Column} from '@ant-design/charts';
import {getChartData} from 'views/tools/stats/getChartData';
import {message} from 'antd';
import 'styles/chart.css';

interface ChartDrawingProps {
  modePicker: boolean;
  queryParams: {
    companyId: string;
    endDate: object;
    startDate: object;
  };
  tabKey: string;
  dailyCost?: number;
}

interface ChartDataModel {
  company: string;
  depot: string;
  value: number;
}

export const ChartDrawing = (props: ChartDrawingProps) => {
  const start = useMemo(() => {
    return new Date(moment(props.queryParams?.startDate).format('YYYY-MM-DD'));
  }, [props.queryParams?.startDate]);

  const end = useMemo(() => {
    return new Date(moment(props.queryParams?.endDate).format('YYYY-MM-DD'));
  }, [props.queryParams?.endDate]);

  const tab = useMemo(() => {
    return props.tabKey;
  }, [props.tabKey]);

  interface GetDataModel {
    company: string;
    depot: string;
    value: number;
  }

  const [chartData, setChartData] = useState<GetDataModel[]>([]);
  useEffect(() => {
    getChartData(props.queryParams?.companyId, start, end, tab)
      .then((res: ChartDataModel[]) => {
        setChartData(
          !props.dailyCost
            ? res
            : res.map((object) => {
                return {...object, value: object.value * props.dailyCost!};
              })
        );
      })
      .catch((error) => {
        message.error(error || error.message);
      });
  }, [start, end, props.queryParams?.companyId, tab, props.dailyCost]);

  let configColumn = {
    data: chartData,
    xField: props.modePicker ? 'company' : 'depot',
    yField: 'value',
    seriesField: props.modePicker ? 'depot' : 'company',
    isGroup: 'true',
    columnStyle: {
      radius: [0, 0, 0, 0],
    },
    label: {
      position: 'middle',
    },
  };

  // @ts-ignore
  return <Column {...configColumn} />;
};
