import {Typography, Space} from 'antd';
const {Text} = Typography;

export const Environment = (): JSX.Element => {
  return (
    <div>
      <Space>
        <Text type="danger">{process.env.REACT_APP_FIREBASE_PROJECT_ID}</Text>
        <Text type="danger">{process.env.REACT_APP_ENVIRONMENT}</Text>
      </Space>
    </div>
  );
};
