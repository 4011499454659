import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AppThunk, RootState} from 'store/index';
import {firebase} from 'services/firebase';
import {message} from 'antd/es';
import i18next from 'i18next';

export const WEEKLY_EXPORT_COLLECTION = 'weekly_exports';

interface WeeklyExportState {
  isLoading: boolean;
  companyIds: string[];
  hasSubscription: boolean;
}

const initialState = {
  isLoading: false,
} as WeeklyExportState;

export const weeklyExportSlice = createSlice({
  name: 'weeklyExport',
  initialState: initialState,
  reducers: {
    updating: (state) => {
      state.isLoading = true;
      state.companyIds = [];
      state.hasSubscription = false;
    },
    hasError: (state) => {
      state.isLoading = false;
      state.companyIds = [];
      state.hasSubscription = false;
    },
    update: (state, action: PayloadAction<string[]>) => {
      state.isLoading = false;
      state.companyIds = action.payload;
      state.hasSubscription = action.payload.length > 0;
    },
  },
});

export const createExportSubscriptionAsync = (
  companyIds: string[]
): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(updating());
    const email = getState().auth.user?.email ?? '';
    await firebase
      .firestore()
      .collection(WEEKLY_EXPORT_COLLECTION)
      .doc(email)
      .set({email, companyIds});
    dispatch(loadWeeklyExportSubscriptionAsync(email));
    message.info(i18next.t('export.subscriptionSuccess'));
  } catch (error) {
    dispatch(hasError());
    message.error('Unknown error occurred');
  }
};

export const cancelSubscriptionAsync = (): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(updating());
    const email = getState().auth.user?.email ?? '';
    await firebase
      .firestore()
      .collection(WEEKLY_EXPORT_COLLECTION)
      .doc(email)
      .delete();
    dispatch(update([]));
    message.info(i18next.t('export.subscriptionCancelled'));
  } catch (error) {
    dispatch(hasError());
    message.error('Unknown error occurred');
  }
};

export const loadWeeklyExportSubscriptionAsync = (
  email: string
): AppThunk => async (dispatch) => {
  try {
    if (email === '') dispatch(update([]));
    dispatch(updating());
    const doc = await firebase
      .firestore()
      .collection(WEEKLY_EXPORT_COLLECTION)
      .doc(email)
      .get();
    if (doc.exists) {
      dispatch(update(doc.data()?.companyIds ?? []));
    } else {
      dispatch(update([]));
    }
  } catch (error) {
    dispatch(hasError());
  }
};

export const {updating, hasError, update} = weeklyExportSlice.actions;

export const weeklyExportStateSelector = (
  state: RootState
): WeeklyExportState => state.weeklyExport;

export default weeklyExportSlice.reducer;
