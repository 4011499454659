import {AuthState} from 'store/slices/authSlice';

export const SUPER_ADMIN = 'super_admin';
export const ADMINISTRATOR = 'administrator';
export const DEPOT_OPERATOR = 'depot_operator'; // TerminalOperator
export const HAULAGE_ADMIN = 'haulage_admin';
export const DRIVER = 'driver';
export const STAKEHOLDER = 'stakeholder'; // interest

// we want them in a store?
// to be able to create selectors depending on rights etc
export const ALL_ROLES = [
  SUPER_ADMIN,
  ADMINISTRATOR,
  DEPOT_OPERATOR,
  HAULAGE_ADMIN,
  DRIVER,
  STAKEHOLDER,
];

export const COMPANY_ROLES = [
  SUPER_ADMIN,
  ADMINISTRATOR,
  DEPOT_OPERATOR,
  STAKEHOLDER,
];

export const HAULAGE_ROLES = [HAULAGE_ADMIN, DRIVER];

export const hasNotHaulageAdminRights = (authState: AuthState) => {
  return (
    authState.user &&
    authState.user.role !== SUPER_ADMIN &&
    authState.user.role !== ADMINISTRATOR
  );
};

export const hasSuperAdminRights = (authState: AuthState) => {
  return authState.user && authState.user.role === SUPER_ADMIN;
};

export const hasSuperAdminOrAdminRights = (authState: AuthState) => {
  return (
    (authState.user && authState.user.role === SUPER_ADMIN) ||
    (authState.user && authState.user.role === ADMINISTRATOR)
  );
};

export const isHaulageAdmin = (authState: AuthState) => {
  return authState.user && authState.user.role === HAULAGE_ADMIN;
};

export const hasDepotSendRights = (authState: AuthState, depotId: string) => {
  if (
    (authState.user && authState.user.role === SUPER_ADMIN) ||
    (authState.user && authState.user.role === ADMINISTRATOR)
  )
    return true;

  return (
    authState.user &&
    authState.user.role === DEPOT_OPERATOR &&
    authState.user.depots.includes(depotId)
  );
};
