import {Input, Button, PageHeader, message} from 'antd';
import {useState} from 'react';
import {sendSMS} from 'services/sms';
import {authStateSelector} from 'store/slices/authSlice';
import {useSelector} from 'react-redux';
import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';

export const SmsTestView = (): JSX.Element => {
  const [phone, setPhone] = useState('');
  const [msg, setMsg] = useState('');
  const authState = useSelector(authStateSelector);
  const {t} = useTranslation();

  useEffect(() => {
    setPhone(authState.user?.phoneNumber ?? '');
  }, [authState]);

  return (
    <div className="site-main-content" style={{padding: '16px'}}>
      <PageHeader
        style={{padding: '16px 8px'}}
        title={t('sms.title')}
        subTitle={t('sms.subTitle')}
      />
      <Input
        value={phone}
        placeholder={t('form.placeholder.enterEntity', {
          entity: t('communication.aPhoneNumber'),
        })}
        onChange={(e) => setPhone(e.target.value)}
      />
      <Input
        placeholder={t('form.placeholder.enterEntity', {
          entity: t('communication.message'),
        })}
        onChange={(e) => setMsg(e.target.value)}
      />
      <Button
        type="primary"
        onClick={() => {
          sendSMS(phone, msg)
            .then((r) => message.success(t('sms.sendSucceed')))
            .catch((e) => message.error(t('error.occurred')));
        }}>
        {t('sms.sendTestSMS')}
      </Button>
    </div>
  );
};
