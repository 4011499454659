import {Alert} from 'antd';
import i18n from 'utils/i18n';

export const AccessDenied = () => {
  return (
    <Alert
      style={{margin: '0 auto', width: '600px', marginTop: '200px'}}
      message={i18n.t('error.name')}
      description={i18n.t('error.notAvailableForYou')}
      type="error"
      showIcon
    />
  );
};
