import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from 'store/index';
import {Company} from 'store/slices/companiesSlice';
import en_GB from 'antd/lib/locale-provider/en_GB';
import {Locale} from 'antd/lib/locale-provider';

interface SessionState {
  selectedCompany: Company | null;
  selectedLanguage: string;
  selectedAntDLocale: Locale;
  selectedCompanies: string[];
}

const initialState = {
  selectedCompany: null,
  selectedLanguage: 'en',
  selectedAntDLocale: en_GB,
  selectedCompanies: [],
} as SessionState;

function writeState(state: SessionState) {
  sessionStorage.setItem('dsp_session', JSON.stringify(state));
}
const createInitialState = (): SessionState => {
  if (sessionStorage.getItem('dsp_session') == null) {
    return initialState;
  } else {
    return JSON.parse(
      sessionStorage.getItem('dsp_session') as string
    ) as SessionState;
  }
};

export const sessionSlice = createSlice({
  name: 'session',
  initialState: createInitialState(),
  reducers: {
    changeSelectedCompany(state, action: PayloadAction<Company>) {
      state.selectedCompany = action.payload;
      if (state.selectedCompanies.length < 1) {
        state.selectedCompanies = [action.payload.id];
      }
      writeState(state);
    },
    changeSelectedLanguage(state, action: PayloadAction<string>) {
      state.selectedLanguage = action.payload;
      writeState(state);
    },
    changeSelectedAntDLocale(state, action: PayloadAction<Locale>) {
      state.selectedAntDLocale = action.payload;
      writeState(state);
    },
    changeSelectedCompanies(state, action: PayloadAction<string[]>) {
      state.selectedCompanies = action.payload;
      writeState(state);
    },
  },
});

export const {
  changeSelectedCompany,
  changeSelectedLanguage,
  changeSelectedAntDLocale,
  changeSelectedCompanies,
} = sessionSlice.actions;
export const sessionStateSelector = (state: RootState): SessionState =>
  state.session;

export default sessionSlice.reducer;
