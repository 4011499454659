import {Form, Input, Spin} from 'antd';
import {FormInstance} from 'antd/es/form';
import {ProductType} from 'store/slices/productTypesSlice';
import {ProductTypeSelect} from 'components/form/ProductTypeSelect';
import {useTranslation} from 'react-i18next';
import {capitalizeFirstLetter} from 'utils/helpers';

export interface ProductFormProps {
  form: FormInstance<ProductFormValues>;
  onSave: (values: ProductFormValues) => void;
  product?: ProductFormValues; // send if edit mode
  mode: 'create' | 'edit';
  isSaving: boolean;
}

export interface ProductFormValues {
  name: string;
  code: string;
  description: string;
  type: ProductType; // how to handle this. Id or obj?
}

export const ProductForm = (props: ProductFormProps): JSX.Element => {
  const {t} = useTranslation();
  return (
    <Spin spinning={props.isSaving}>
      <Form
        initialValues={
          props.mode === 'edit' && props.product ? props.product : {}
        }
        name="product-form"
        autoComplete="off"
        layout="vertical"
        form={props.form}
        onFinish={props.onSave}>
        <Form.Item
          label={capitalizeFirstLetter(t('form.name'))}
          name="name"
          rules={[
            {
              required: true,
              message: t('form.required.pleaseEnterEntity', {
                entity: t('product.aProductName'),
              }),
            },
          ]}>
          <Input
            placeholder={t('form.placeholder.enterEntity', {
              entity: t('product.aProductName'),
            })}
          />
        </Form.Item>
        <Form.Item
          label={t('product.code')}
          name="code"
          rules={[
            {
              required: true,
              message: t('form.required.pleaseEnterEntity', {
                entity: t('product.aProductCode'),
              }),
            },
          ]}>
          <Input
            placeholder={t('form.placeholder.enterEntity', {
              entity: t('product.aProductCode'),
            })}
          />
        </Form.Item>
        <Form.Item
          label={t('genericWords.description')}
          name="description"
          rules={[
            {
              required: true,
              message: t('form.required.pleaseEnterEntity', {
                entity: t('product.aProductDescription'),
              }),
            },
          ]}>
          <Input
            placeholder={t('form.placeholder.enterEntity', {
              entity: t('product.aProductDescription'),
            })}
          />
        </Form.Item>
        <Form.Item
          label={capitalizeFirstLetter(t('product.type'))}
          name="type"
          rules={[
            {
              required: true,
              message: t('form.required.pleaseSelectEntity', {
                entity: t('product.aProductType'),
              }),
            },
          ]}>
          <ProductTypeSelect />
        </Form.Item>
      </Form>
    </Spin>
  );
};
