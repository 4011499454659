import React, {useState} from 'react';
import {Tabs, Row, Col, Card, Input} from 'antd';
import {ChartView} from './stats/ChartView';
import {StatsFilterForm} from 'views/tools/partials/StatsFilterForm';
import {useTranslation} from 'react-i18next';
import {ViewHeader} from 'components/layout/ViewHeader';
const {TabPane} = Tabs;

export interface FormFilterDataModel {
  companyId: string;
  startDate: object; // Date?
  endDate: object; // Date?
}

export const DashboardView = (): JSX.Element => {
  const {t} = useTranslation();

  const {Search} = Input;
  const [costPerDayInput, setCostPerDayInput] = useState<number>(100000);

  const [
    chartsFormFilterData,
    setChartsFormFilterData,
  ] = useState<FormFilterDataModel>({
    companyId: '',
    startDate: {}, // Date?
    endDate: {}, // Date?
  });

  const onSearch = (input: string) => {
    const searchNumber = +input;
    setCostPerDayInput(searchNumber);
  };
  return (
    <div className="site-main-content" style={{padding: '16px'}}>
      <ViewHeader title={t('statistics.name')} />
      <Row>
        <Col span={24} style={{paddingTop: '16px'}}>
          <Card style={{paddingTop: '16px'}}>
            <StatsFilterForm
              onQuery={(query) => setChartsFormFilterData(query)}
            />
          </Card>
        </Col>
      </Row>
      <Tabs type="card" centered style={{paddingTop: '10px'}}>
        <TabPane tab={t('statistics.terminalDownTimeReason')} key="1">
          <div style={{marginTop: '32px'}}>
            <ChartView
              chartsFormFilterData={chartsFormFilterData}
              tabKey="dashboard"
            />
          </div>
        </TabPane>
        <TabPane tab={t('statistics.tabs.meansAdvancedPlanning')} key="2">
          <div style={{marginTop: '32px'}}>
            <ChartView
              chartsFormFilterData={chartsFormFilterData}
              tabKey="meansAdvancedPlanning"
            />
          </div>
        </TabPane>
        <TabPane tab={t('statistics.tabs.stopsDayCost')} key="3">
          <div style={{marginTop: '32px'}}>
            <ChartView
              chartsFormFilterData={chartsFormFilterData}
              filteredNumber={costPerDayInput}
              tabKey="stopDays"
            />
            <div style={{textAlign: 'center'}}>
              <div style={{paddingTop: '16px'}}>
                {t('stopDayCost.standardCostPerDay')} &nbsp;
              </div>
              <div>
                <Search
                  placeholder={t('stopDayCost.standardCostPerDay')}
                  type="number"
                  allowClear
                  defaultValue={costPerDayInput}
                  enterButton={t('statistics.calculate')}
                  onSearch={(input) => onSearch(input)}
                  style={{width: 400}}
                />
              </div>
            </div>
          </div>
        </TabPane>
        <TabPane tab={t('statistics.tabs.numbTerminalDowntimes')} key="4">
          <div style={{marginTop: '32px'}}>
            <ChartView
              chartsFormFilterData={chartsFormFilterData}
              tabKey="numberOfDowntimes"
            />
          </div>
        </TabPane>
      </Tabs>
    </div>
  );
};
