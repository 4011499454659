import {Collapse, Result} from 'antd';
import {
  depotStateSelector,
  getStatusDepotsByCompany,
} from 'store/slices/depotsSlice';
import {useSelector} from 'react-redux';
import {ViewHeader} from 'components/layout/ViewHeader';
import {DepotMap} from 'components/DepotMap';
import {sessionStateSelector} from 'store/slices/sessionSlice';
import {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {
  loadAllActiveStopMessages,
  stopMessageSelector,
} from 'store/slices/stopMessagesSlice';
import {useTranslation} from 'react-i18next';

const {Panel} = Collapse;

export const DepotsOverview = (): JSX.Element => {
  const dispatch = useDispatch();
  const companyDepots = useSelector(getStatusDepotsByCompany);
  const depotState = useSelector(depotStateSelector);
  const stopMessageState = useSelector(stopMessageSelector);
  const session = useSelector(sessionStateSelector);
  const {t} = useTranslation();

  useEffect(() => {
    dispatch(loadAllActiveStopMessages());
  }, [dispatch]);

  if (!session.selectedCompany) {
    return (
      <Result
        status="404"
        title="404"
        subTitle={t('depot.overview.error.404')}
      />
    );
  }

  return (
    <div className="site-main-content" style={{padding: '16px'}}>
      <ViewHeader
        title={t('depot.overview.name')}
        subTitle={session?.selectedCompany?.name}
        extra={[]}
      />
      <Collapse collapsible="header" defaultActiveKey={['depots-map-panel']}>
        <Panel
          header={t('depot.stops')}
          key="depots-map-panel"
          className="panel-body-no-padding">
          <DepotMap
            isLoading={stopMessageState.isLoading || depotState.isLoading}
            statusDepots={companyDepots}
          />
        </Panel>
      </Collapse>
    </div>
  );
};
