import {useEffect} from 'react';
import {useLocation, withRouter} from 'react-router-dom';

export const ScrollToTop = withRouter(() => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
});

