import {CompanyList} from 'views/company/partials/CompanyList';
import {ViewHeader} from 'components/layout/ViewHeader';
import {CompanyCreateModal} from './CompanyCreateModal';
import {useTranslation} from 'react-i18next';
import {capitalizeFirstLetter} from 'utils/helpers';

export const CompanyAdminView = (): JSX.Element => {
  const {t} = useTranslation();
  return (
    <div className="site-main-content" style={{padding: '16px'}}>
      <ViewHeader
        title={capitalizeFirstLetter(t('company.companies'))}
        extra={[<CompanyCreateModal key={'company-create-modal-win'} />]}
      />
      <CompanyList />
    </div>
  );
};
