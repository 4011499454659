import {Menu} from 'antd';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {authStateSelector} from 'store/slices/authSlice';
import {sessionStateSelector} from "store/slices/sessionSlice";

const {SubMenu} = Menu;

export const HaulageAdminLayout = () => {
  const {t} = useTranslation();
  const authState = useSelector(authStateSelector);
  const session = useSelector(sessionStateSelector);

  if (authState.isLoadingProfile || authState.isAuthenticating) return null;

  return (
    <>
      <Menu theme="dark" mode="horizontal" style={{position: 'absolute'}}>
        <Menu.Item key="personal-overview">
          <Link to={'/personal-overview'}>{t('menu.personalOverview')}</Link>
        </Menu.Item>
        <SubMenu title={'Hauliers'} key="hauliers">
          {authState.user?.hauliers?.map((haulier) => (
            <Menu.Item key={`overview-haulier-${haulier.id}`}>
              <Link to={`/hauliers/${haulier.id}`}>{haulier.name}</Link>
            </Menu.Item>
          ))}
        </SubMenu>
        {session.selectedCompany?.id ===
        '87b69206-a0fa-11eb-bcbc-0242ac130002' && (
          <Menu.Item key="fullscreen-dashboard">
            <Link to={`/dashboard/${session.selectedCompany?.id}`}>
              Dashboard
            </Link>
          </Menu.Item>
        )}
        <Menu.Item key="contacts">
          <Link to={'/contacts'}>{t('menu.contacts')}</Link>
        </Menu.Item>
        <Menu.Item key="support">
          <Link to={'/support'}>{t('menu.support')}</Link>
        </Menu.Item>
      </Menu>
    </>
  );
};
