import {useSelector} from 'react-redux';
import {Row, Col} from 'antd';
import {companyDepotsSelector} from 'store/slices/depotsSlice';
import {Checkbox, Divider} from 'antd';
import {useState, useEffect} from 'react';
import * as _ from 'lodash';

interface DepotMultiSelectProps {
  value?: string[];
  onChange?: (value: string[]) => void;
}

interface SelectAllLookupHash {
  [company: string]: 'checked' | 'unchecked' | 'indeterminate';
}

export const DepotMultiSelect = (props: DepotMultiSelectProps): JSX.Element => {
  const companyDepots = useSelector(companyDepotsSelector);
  const [selected, setSelected] = useState<string[]>(props.value ?? []);
  const [selectedAll, setSelectedAll] = useState<SelectAllLookupHash>({});

  useEffect(() => {
    if (props.onChange) props.onChange(selected);
  }, [selected, props]);

  const onChange = (e: any) => {
    if (e.target.checked) {
      setSelected((state) => [e.target.value, ...state]);
    } else {
      setSelected((state) => state.filter((i) => i !== e.target.value));
    }
  };

  const onCheckAllChange = async (e: any, company: string) => {
    const companyAllChecked = companyDepots
      .filter((c) => c.companyName === company)
      .flatMap((c) => c.depots.map((d) => d.id));

    if (e.target.checked) {
      const obj: SelectAllLookupHash = {
        ...selectedAll,
        [company]: 'checked',
      };
      setSelectedAll(obj);
      setSelected((state) => {
        const merged = [...companyAllChecked, ...state];
        return [...new Set(merged)]; // make it unique trick
      });
    } else {
      const obj: SelectAllLookupHash = {
        ...selectedAll,
        [company]: 'unchecked',
      };
      setSelectedAll(obj);
      const newArray = _.pullAll(selected, companyAllChecked);
      setSelected(newArray);
      if (props.onChange) props.onChange(newArray);
    }
  };

  return (
    <>
      <Row gutter={16}>
        {companyDepots.map((company, index) => (
          <Col span={8} key={`div-${index}`}>
            <Row key={`row-header-${index}`}>
              <Col span={24}>
                <Divider orientation="left" plain>
                  <Checkbox
                    checked={selectedAll[company.companyName] === 'checked'}
                    onChange={(e) => onCheckAllChange(e, company.companyName)}>
                    <strong>
                      {company.companyName} ({company.depots?.length})
                    </strong>
                  </Checkbox>
                </Divider>
              </Col>
            </Row>
            <Row key={`row-body-${index}`}>
              {company.depots.map((depot) => (
                <Row key={`row-checkbox-group-${index}-${depot.id}`}>
                  <Col>
                    <Checkbox
                      onChange={onChange}
                      style={{width: '290px'}}
                      key={depot.id}
                      checked={selected.includes(depot.id)}
                      value={depot.id}>
                      {depot.name} ({depot.country})
                    </Checkbox>
                  </Col>
                </Row>
              ))}
            </Row>
          </Col>
        ))}
      </Row>
    </>
  );
};
