import {Checkbox, CheckboxOptionType, Spin} from 'antd';
import {CheckboxValueType} from 'antd/lib/checkbox/Group';
import * as React from 'react';
import {useState} from 'react';
import {useSelector} from 'react-redux';
import {companiesStateSelector} from 'store/slices/companiesSlice';

interface CompanyMultiSelectProps {
  value?: string[];
  onChange?: (value: string[]) => void;
}

export const CompanyMultiSelect = (
  props: CompanyMultiSelectProps
): JSX.Element => {
  const companyState = useSelector(companiesStateSelector);
  const [selected, setSelected] = useState<string[]>(props.value ?? []);

  const onChange = (list: CheckboxValueType[]) => {
    setSelected(list as string[]);
    if (props.onChange) props.onChange(list as string[]);
  };

  return (
    <Spin spinning={companyState.isLoading}>
      <Checkbox.Group
        onChange={onChange}
        value={selected}
        options={companyState.companies.map(
          (c) => ({label: c.name, value: c.id} as CheckboxOptionType)
        )}
      />
    </Spin>
  );
};
