import {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {loadProductTypesAsync} from 'store/slices/productTypesSlice';
import {useAuth} from 'providers/AuthProvider';

export const useProductTypes = () => {
  const dispatch = useDispatch();
  const auth = useAuth();

  useEffect(() => {
    if (auth.currentUser) {
      dispatch(loadProductTypesAsync())
    }
  }, [dispatch, auth.currentUser]);
};

