import {Form, Input, Button, Space, FormInstance, Spin} from 'antd';
import {MinusCircleOutlined, PlusOutlined} from '@ant-design/icons';
import {Company} from 'store/slices/companiesSlice';
import {useTranslation} from 'react-i18next';
import {capitalizeFirstLetter} from 'utils/helpers';

export interface CompanyFormProps {
  form: FormInstance<Company>;
  onSave: (values: Company) => void;
  company?: Company;
  mode: 'create' | 'edit';
  isSaving: boolean;
}

export const CompanyForm = (props: CompanyFormProps): JSX.Element => {
  const {t} = useTranslation();
  return (
    <Spin spinning={props.isSaving}>
      <Form
        initialValues={
          props.mode === 'edit' && props.company
            ? props.company
            : {contacts: []}
        }
        name="company-form"
        autoComplete="off"
        layout="vertical"
        form={props.form}
        onFinish={props.onSave}>
        <Form.Item
          label={capitalizeFirstLetter(t('form.name'))}
          name="name"
          rules={[
            {
              required: true,
              message: t('form.required.pleaseEnterEntity', {
                entity: t('form.aName'),
              }),
            },
          ]}>
          <Input
            placeholder={t('form.placeholder.enterEntity', {
              entity: t('company.aCompanyName'),
            })}
          />
        </Form.Item>

        <Form.Item
          label={capitalizeFirstLetter(t('email.name'))}
          name="email"
          rules={[
            {
              required: true,
              message: t('form.required.pleaseEnterEntity', {
                entity: t('email.anEmailAddress'),
              }),
            },
            {
              type: 'email',
              message: t('email.error.valid'),
            },
          ]}>
          <Input
            type="email"
            placeholder={t('form.placeholder.enterEntity', {
              entity: t('email.anEmailAddress'),
            })}
          />
        </Form.Item>
        <Form.Item label={capitalizeFirstLetter(t('contact.contacts'))}>
          <Form.List name="contacts">
            {(fields, {add, remove}) => (
              <>
                {fields.map(({key, name, fieldKey, ...restField}) => (
                  <Space
                    size={'large'}
                    key={key}
                    style={{display: 'flex', marginBottom: 8}}
                    align="baseline">
                    <Form.Item
                      {...restField}
                      name={[name, 'name']}
                      fieldKey={[fieldKey, 'name']}
                      rules={[
                        {
                          required: true,
                          message: t('form.required.pleaseEnterEntity', {
                            entity: t('form.aName'),
                          }),
                        },
                      ]}>
                      <Input
                        placeholder={t('form.placeholder.enterEntity', {
                          entity: t('form.aName'),
                        })}
                      />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'email']}
                      fieldKey={[fieldKey, 'email']}
                      rules={[
                        {
                          required: true,
                          message: t('form.required.pleaseEnterEntity', {
                            entity: t('email.anEmailAddress'),
                          }),
                        },
                      ]}>
                      <Input
                        placeholder={t('form.placeholder.enterEntity', {
                          entity: t('email.anEmailAddress'),
                        })}
                      />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'phone']}
                      fieldKey={[fieldKey, 'phone']}
                      rules={[
                        {
                          required: true,
                          message: t('form.required.pleaseEnterEntity', {
                            entity: t('communication.aPhoneNumber'),
                          }),
                        },
                      ]}>
                      <Input
                        placeholder={t('form.placeholder.enterEntity', {
                          entity: t('communication.aPhoneNumber'),
                        })}
                      />
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Space>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}>
                    {t('contact.addNewContactRow')}
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form.Item>
      </Form>
    </Spin>
  );
};
