import {Col, Image, List, Result, Row} from 'antd';
import {ViewHeader} from 'components/layout/ViewHeader';
import {companiesStateSelector} from 'store/slices/companiesSlice';
import {useSelector} from 'react-redux';
import CircleK from 'assets/company/logo-circlek.svg';
import OKQ8 from 'assets/company/logo-okq8.svg';
import Preem from 'assets/company/logo-preem.svg';
import St1 from 'assets/company/logo-st1.png';
import {useTranslation} from 'react-i18next';
import {UserOutlined, MailOutlined, PhoneOutlined} from '@ant-design/icons';
import {capitalizeFirstLetter} from 'utils/helpers';

export const ContactView = (): JSX.Element => {
  const companiesState = useSelector(companiesStateSelector);
  const {t} = useTranslation();

  if (companiesState.hasError) {
    return <Result status="403" title="403" subTitle={companiesState.error} />;
  }

  const logo = (name: string) => {
    if (name === 'Circle K') {
      return CircleK;
    }
    if (name === 'OKQ8') {
      return OKQ8;
    }
    if (name === 'Preem') {
      return Preem;
    }
    if (name === 'ST1') {
      return St1;
    }
    return '';
  };

  return (
    <div className="site-main-content" style={{padding: '16px'}}>
      <ViewHeader
        title={capitalizeFirstLetter(t('contact.contacts'))}
        extra={[]}
      />
      <List
        size="large"
        itemLayout="horizontal"
        bordered
        rowKey="id"
        loading={companiesState.isLoading}
        dataSource={companiesState.companies}
        renderItem={(company) => (
          <List.Item key={company.id}>
            <Row gutter={24} style={{width: '100%'}}>
              <Col>
                <Image width={100} src={logo(company.name)} preview={false} />
              </Col>
              <Col style={{minWidth: '75%'}}>
                <h3>{company.name}</h3>
                <span>
                  <MailOutlined title={'Email'} style={{paddingRight: '1em'}} />
                  <a href={'mailto:' + company.email}>{company.email}</a>
                </span>
                {company.contacts.length > 0 ? (
                  <h4 style={{marginTop: '1em'}}>
                    {t('userTypes.administrator')}
                  </h4>
                ) : (
                  <></>
                )}
                <Row gutter={64}>
                  {company.contacts.map((contact, index) => (
                    <Col key={index} style={{paddingBottom: '1em'}}>
                      <ul
                        style={{
                          listStyleType: 'none',
                          paddingLeft: '0',
                        }}>
                        <li>
                          <UserOutlined
                            title={'Name'}
                            style={{paddingRight: '1em'}}
                          />
                          {contact.name}
                        </li>
                        <li>
                          <span>
                            <MailOutlined
                              title={'Email'}
                              style={{paddingRight: '1em'}}
                            />
                            <a href={'mailto:' + contact.email}>
                              {contact.email}
                            </a>
                          </span>
                        </li>
                        <li>
                          <PhoneOutlined
                            title={'Phone'}
                            style={{paddingRight: '1em'}}
                          />
                          <a href={'tel:' + contact.phone}>{contact.phone}</a>
                        </li>
                      </ul>
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
          </List.Item>
        )}
      />
    </div>
  );
};
