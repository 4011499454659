import {Form, Button} from 'antd';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {CompanyMultiSelect} from 'components/form/CompanyMultiSelect';

export interface ExportQuery {
  companyIds: string[];
}

export interface ExportFilterFormProps {
  buttonText: string;
  onQuery: (values: ExportQuery) => void;
  selectedCompanies?: string[];
}

export const ExportFilterForm = (props: ExportFilterFormProps): JSX.Element => {
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});
  const {t} = useTranslation();

  useEffect(() => {
    forceUpdate({});
  }, []);

  const onSubmit = (values: ExportQuery) => {
    if (props.onQuery) props.onQuery({companyIds: values.companyIds});
  };

  return (
    <Form<ExportQuery>
      requiredMark={false}
      initialValues={{
        companyIds: props.selectedCompanies ?? [],
      }}
      form={form}
      name="export_query_form"
      onFinish={onSubmit}>
      <Form.Item
        name="companyIds"
        rules={[
          {
            required: true,
            message: t('form.required.pleaseEnterEntity', {
              entity: t('company.name'),
            }),
          },
        ]}>
        <CompanyMultiSelect />
      </Form.Item>
      <Form.Item shouldUpdate>
        {() => (
          <Button
            type="primary"
            htmlType="submit"
            disabled={
              !form.isFieldsTouched(true) ||
              !!form.getFieldsError().filter(({errors}) => errors.length).length
            }>
            {props.buttonText}
          </Button>
        )}
      </Form.Item>
    </Form>
  );
};
