import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import moment from 'moment';

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    debug: false,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
      format: (value, format, lng) => {
        if (value instanceof Date) {
          if (value instanceof Date) return moment(value).format(format);
          return value;
        }
        return value;
      },
    },
    resources: {
      en: {
        translation: require('../locales/en/translation.json'),
      },
      sv: {
        translation: require('../locales/sv/translation.json'),
      },
    },
  });

export default i18n;
