import {useEffect} from 'react';
import {ResetPassword} from '../../components/form/ResetPassword';
import {VerifyEmail} from '../../components/VerifyEmail';
import {RecoverEmail} from '../../components/RecoverEmail';
import {useLocation} from 'react-router-dom';
import {Alert, Row} from 'antd';
import {useDispatch} from 'react-redux';
import {changeSelectedLanguage} from 'store/slices/sessionSlice';
import {useTranslation} from 'react-i18next';

export const ActionView = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const mode = new URLSearchParams(location.search).get('mode');
  const actionCode = new URLSearchParams(location.search).get('oobCode');
  const lang = new URLSearchParams(location.search).get('lang') || 'en';

  useEffect(() => {
    dispatch(changeSelectedLanguage(lang));
  });

  switch (mode) {
    case 'resetPassword':
      return <ResetPassword actionCode={actionCode} />;
    case 'verifyEmail':
      return <VerifyEmail actionCode={actionCode} />;
    case 'recoverEmail':
      return <RecoverEmail actionCode={actionCode} />;
    default:
      return (
        <div className="site-view-welcome" style={{paddingTop: '128px'}}>
          <Row justify="center">
            <Alert
              message={t('auth.error.invalidActionCode')}
              type="error"
              showIcon
            />
          </Row>
        </div>
      );
  }
};
