import {useState, Fragment} from 'react';
import {StatusDepot} from 'store/slices/depotsSlice';
import {ActiveStopMessagesData} from 'views/depot/partials/BigCalendar';
import {Typography} from 'antd';
import {Expandable} from 'components/typography/Expandable';
import {useTranslation} from 'react-i18next';
import {StopMessage} from 'store/slices/stopMessagesSlice';

const {Text} = Typography;

interface DepotStatusCardProps {
  statusDepot?: StatusDepot;
  activeStopMessagesData?: ActiveStopMessagesData;
}

interface CustomPopInfo {
  activeStopMessages: CustomActiveMessages[];
}

interface CustomActiveMessages {
  id?: string;
  urgency?: string;
  subject?: string;
  startDate: number;
  endDate: number;
  downTimeDays: number;
  products?: {name: string}[];
  message?: string;
  createdBy: {
    name?: string;
  };
}

export const DepotStatusCard = (props: DepotStatusCardProps): JSX.Element => {
  const {t} = useTranslation();

  const statusDepot: CustomPopInfo = {
    activeStopMessages: [
      {
        id: props.activeStopMessagesData?.id,
        urgency: props.activeStopMessagesData?.urgency,
        subject: props.activeStopMessagesData?.subject,
        startDate: Number(props.activeStopMessagesData?.start),
        endDate: Number(props.activeStopMessagesData?.end),
        downTimeDays: Number(props.activeStopMessagesData?.downTimeDays),
        products: props.activeStopMessagesData?.products?.map((p) => {
          return {
            name: p,
          };
        }),
        message: props.activeStopMessagesData?.message,
        createdBy: {
          name: props.activeStopMessagesData?.createdBy,
        },
      },
    ],
    // },
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [popupData, _] = useState<StopMessage[] | CustomActiveMessages[]>(
    props.statusDepot?.activeStopMessages || statusDepot.activeStopMessages
  );

  return (
    <div style={{overflowY: 'auto', height: '600px'}}>
      {popupData.length < 1 && t(`stopMessage.noActiveMessages`)}
      <ul
        style={{
          listStyleType: 'none',
          margin: 0,
          padding: 0,
        }}>
        {popupData?.map((sm: StopMessage | CustomActiveMessages) => (
          <li key={sm.id}>
            <span style={{color: 'salmon'}}>
              {t(`stopMessage.urgency.${sm.urgency}`)}
            </span>
            <br />
            {t(`stopMessage.subject.${sm.subject}`)}
            <br />
            {new Date(sm.startDate).toLocaleString()}
            <br />
            {new Date(sm.endDate).toLocaleString()} ({sm.downTimeDays}{' '}
            {t('time.day', {count: sm.downTimeDays})})
            <br />
            <div style={{paddingTop: '8px'}}>
              <Expandable rows={2}>
                {sm.products?.map((product: {name: string}, index: number) => (
                  <span key={index} style={{color: 'gray'}}>
                    <Fragment>{product.name},&#160;</Fragment>
                  </span>
                ))}
              </Expandable>
            </div>
            {sm.message}
            <br />
            <Text type="secondary">
              {t('user.createdBy')} {sm.createdBy?.name}
            </Text>
            <hr />
          </li>
        ))}
      </ul>
    </div>
  );
};
