import 'styles/App.css';
import 'antd/dist/antd.css';
import {ConfigProvider} from 'antd';
import {GlobalContextProvider} from 'providers/GlobalContextProvider';
import 'moment/locale/en-gb';
import {useCompanies} from 'services/hooks/useCompanies';
import {AppLayout} from 'components/AppLayout';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import {DashboardView} from 'views/DashboardView';
import {DashboardView as AdminDashBoardView} from 'views/tools/DashboardView';
import {ProductListView} from 'views/product/ProductListView';
import {LoginView} from 'views/auth/LoginView';
import {ForgottenPasswordView} from 'views/auth/ForgottenPasswordView';
import {ActionView} from 'views/auth/ActionView';
import {IntroView} from 'views/IntroView';
import {AuthProvider} from 'providers/AuthProvider';
import {Result} from 'antd';
import {ScrollToTop} from 'components/ScrollToTop';
import {ProtectedRoute} from 'components/routes/ProtectedRoute';
import {AccountInfoView} from 'views/user/AccountInfoView';
import {CompanyAdminView} from 'views/company/CompanyAdminView';
import {ContactView} from 'views/ContactView';
import {SupportListView} from 'views/support/SupportListView';
import {ChangePasswordView} from 'views/auth/ChangePasswordView';
import {ChangeEmailView} from 'views/auth/ChangeEmailView';
import {PersonalizedOverview} from 'views/PersonalizedOverview';
import {SmsTestView} from 'views/tools/SmsTestView';
import {TemplateView} from 'views/tools/TemplateView';
import {ExportDepotStops} from 'views/tools/ExportDepotStops';
import {DepotsOverview} from 'views/DepotsOverview';
import {MailTestView} from 'views/tools/MailTestView';
import {useSelector} from 'react-redux';
import {sessionStateSelector} from 'store/slices/sessionSlice';
import {Suspense, lazy} from 'react';
import {
  SUPER_ADMIN,
  ADMINISTRATOR,
  DEPOT_OPERATOR,
  HAULAGE_ADMIN,
  DRIVER,
  STAKEHOLDER,
  ALL_ROLES,
} from 'constants/roles';
import {ApiView} from 'views/support/ApiView';

const HaulageListView = lazy(() =>
  import('views/haulage/HaulageListView').then(({HaulageListView}) => ({
    default: HaulageListView,
  }))
);

const HaulageView = lazy(() =>
  import('views/haulage/HaulageView').then(({HaulageView}) => ({
    default: HaulageView,
  }))
);

const DepotsListView = lazy(() =>
  import('views/depot/DepotsListView').then(({DepotsListView}) => ({
    default: DepotsListView,
  }))
);

const DepotView = lazy(() =>
  import('views/depot/DepotView').then(({DepotView}) => ({
    default: DepotView,
  }))
);

const UserListView = lazy(() =>
  import('views/user/UserListView').then(({UserListView}) => ({
    default: UserListView,
  }))
);

function App() {
  const session = useSelector(sessionStateSelector);
  useCompanies();

  return (
    <ConfigProvider locale={session.selectedAntDLocale}>
      <GlobalContextProvider>
        <BrowserRouter>
          <ScrollToTop />
          <AuthProvider>
            <AppLayout>
              <Suspense fallback={<p>Loading...</p>}>
                <Switch>
                  <Route exact path="/" component={IntroView} />
                  <Route exact path="/login" component={LoginView} />
                  <Route
                    exact
                    path="/forgotPassword"
                    component={ForgottenPasswordView}
                  />
                  <Route exact path="/action" component={ActionView} />
                  <Route exact path="/api/docs" component={ApiView} />
                  <ProtectedRoute
                    exact
                    path="/account"
                    component={AccountInfoView}
                    accessibilityRole={ALL_ROLES}
                  />
                  <ProtectedRoute
                    exact
                    path="/dashboard/:secret"
                    component={DashboardView}
                    accessibilityRole={ALL_ROLES}
                  />
                  <ProtectedRoute
                    exact
                    path="/overview"
                    component={DepotsOverview}
                    accessibilityRole={[
                      SUPER_ADMIN,
                      ADMINISTRATOR,
                      DEPOT_OPERATOR,
                    ]}
                  />
                  <ProtectedRoute
                    exact
                    path="/personal-overview"
                    component={PersonalizedOverview}
                    accessibilityRole={[DRIVER, STAKEHOLDER]}
                  />
                  <ProtectedRoute
                    exact
                    path="/depots"
                    component={DepotsListView}
                    accessibilityRole={[
                      SUPER_ADMIN,
                      ADMINISTRATOR,
                      DEPOT_OPERATOR,
                    ]}
                  />
                  <ProtectedRoute
                    exact
                    path="/depot/:depotId"
                    component={DepotView}
                    accessibilityRole={[
                      SUPER_ADMIN,
                      ADMINISTRATOR,
                      DEPOT_OPERATOR,
                    ]}
                  />
                  <ProtectedRoute
                    exact
                    path="/hauliers"
                    component={HaulageListView}
                    accessibilityRole={[SUPER_ADMIN, ADMINISTRATOR]}
                  />
                  <ProtectedRoute
                    exact
                    path="/hauliers/:haulageId"
                    component={HaulageView}
                    accessibilityRole={[
                      SUPER_ADMIN,
                      ADMINISTRATOR,
                      HAULAGE_ADMIN,
                    ]}
                  />
                  <ProtectedRoute
                    exact
                    path="/products"
                    component={ProductListView}
                    accessibilityRole={[
                      SUPER_ADMIN,
                      ADMINISTRATOR,
                      DEPOT_OPERATOR,
                    ]}
                  />
                  <ProtectedRoute
                    exact
                    path="/users"
                    component={UserListView}
                    accessibilityRole={[
                      SUPER_ADMIN,
                      ADMINISTRATOR,
                      DEPOT_OPERATOR,
                      HAULAGE_ADMIN,
                    ]}
                  />
                  <ProtectedRoute
                    exact
                    path="/message-templates"
                    component={TemplateView}
                    accessibilityRole={[SUPER_ADMIN]}
                  />
                  <ProtectedRoute
                    exact
                    path="/contacts"
                    component={ContactView}
                    accessibilityRole={ALL_ROLES}
                  />
                  <ProtectedRoute
                    exact
                    path="/admin/dashboard"
                    component={AdminDashBoardView}
                    accessibilityRole={[SUPER_ADMIN, ADMINISTRATOR]}
                  />
                  <ProtectedRoute
                    exact
                    path="/admin/companies"
                    component={CompanyAdminView}
                    accessibilityRole={[SUPER_ADMIN, ADMINISTRATOR]}
                  />
                  <ProtectedRoute
                    exact
                    path="/admin/testsms"
                    component={SmsTestView}
                    accessibilityRole={[SUPER_ADMIN, ADMINISTRATOR]}
                  />
                  <ProtectedRoute
                    exact
                    path="/admin/testmail"
                    component={MailTestView}
                    accessibilityRole={[SUPER_ADMIN, ADMINISTRATOR]}
                  />
                  <ProtectedRoute
                    exact
                    path="/admin/export-stops"
                    component={ExportDepotStops}
                    accessibilityRole={[SUPER_ADMIN, ADMINISTRATOR]}
                  />
                  <ProtectedRoute
                    exact
                    path="/support"
                    component={SupportListView}
                    accessibilityRole={ALL_ROLES}
                  />
                  <ProtectedRoute
                    exact
                    path="/change-password"
                    component={ChangePasswordView}
                    accessibilityRole={ALL_ROLES}
                  />
                  <ProtectedRoute
                    exact
                    path="/change-email"
                    component={ChangeEmailView}
                    accessibilityRole={ALL_ROLES}
                  />
                  <Route>
                    <Result
                      className={'site-main-content'}
                      status="404"
                      title="404"
                      subTitle="Sorry, the page you visited does not exist."
                    />
                  </Route>
                </Switch>
              </Suspense>
            </AppLayout>
          </AuthProvider>
        </BrowserRouter>
      </GlobalContextProvider>
    </ConfigProvider>
  );
}

export default App;
