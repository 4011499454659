import {User, UserLight} from 'store/slices/usersSlice';
import {Coordinates, GeocodeResponse} from 'services/hooks/useGeocode';
import {firebase} from 'services/firebase';

export const userToLight = (user: User): UserLight => {
  return {
    uid: user.id,
    name: `${user.firstName} ${user.lastName}`,
    email: user.email,
    phoneNumber: user.phoneNumber ?? null,
  };
};

export interface Downtime {
  days: number;
  hours: number;
  minutes: number;
}

export const downTimeSummary = (startDate: Date, endDate: Date): Downtime => {
  const diff = endDate.getTime() - startDate.getTime();
  let diffMinutes = diff / 1000;
  diffMinutes /= 60;
  const downTimeMinutes = Math.abs(Math.round(diffMinutes));

  let diffHours = diff / (1000 * 3600);
  const downTimeHours = Math.abs(Math.round(diffHours));

  let diffDays = diff / (1000 * 3600 * 24);
  const downTimeDays = Math.abs(Math.round(diffDays));

  return {hours: downTimeHours, minutes: downTimeMinutes, days: downTimeDays};
};

/*
export const createAuditLogObject = (
  user: User,
  action: 'created' | 'updated' | 'deleted'
) => {
  return {
    action,
    user: userToLight(user),
  };
};
 */

export const geoCodeAddress = async (address: string) => {
  const url = `${process.env.REACT_APP_GOOGLE_GEOCODE_API_URL}?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&address=${address}`;
  const response = await fetch(url);
  const json: GeocodeResponse = await response.json();
  if (json.status === 'OK' && json.results && json.results[0]) {
    const location = json.results[0].geometry.location;
    const address_components = json.results[0].address_components;

    const tz = await timezone(location);

    if (address_components.length > 0) {
      const countryComponent = address_components.find((ac) =>
        ac.types.includes('country')
      );
      return {
        full_address: address,
        country: countryComponent?.short_name ?? '',
        coordinates: location,
        timezone: {id: tz?.id ?? '', name: tz?.name ?? ''},
      };
    }
  }
};

export const timezone = async (point: {lat: number; lng: number}) => {
  try {
    const lat = point.lat;
    const lng = point.lng;
    const url = `https://maps.googleapis.com/maps/api/timezone/json?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&location=${lat},${lng}&timestamp=1831161200`;

    const response = await fetch(url);
    const json: any = await response.json();
    if (json.status === 'OK') {
      return {
        id: json.timeZoneId,
        name: json.timeZoneName,
      };
    }
  } catch (error) {
    console.log(error);
    return {
      id: '',
      name: '',
    };
  }
};

export const getBrowserTimezone = () => {
  const currentTime = new Date();
  let currentTimezone = currentTime.getTimezoneOffset();
  currentTimezone = (currentTimezone / 60) * -1;
  let utc = 'UTC';
  if (currentTimezone !== 0) {
    utc += currentTimezone > 0 ? ' +' : ' ';
    utc += currentTimezone;
  }
  return {timezone: Intl.DateTimeFormat().resolvedOptions().timeZone, utc};
};

export interface AuditLog {
  user: UserLight;
  action: string;
  updatedAt: number;
}

export interface AuditLogs {
  auditLogs: AuditLog[];
  hasError: boolean;
  error: string;
  isLoading: boolean;
}

/**
 * Audit Logger for a given firestore document
 * @param user
 * @param docRef
 * @param action
 */
export const auditLog = async (
  user: UserLight,
  docRef: firebase.firestore.DocumentReference,
  action: 'updated' | 'deleted' | 'created' | 'disabled'
) => {
  try {
    const updatedAt = firebase.firestore.FieldValue.serverTimestamp();
    await docRef.collection('auditLog').add({action, user, updatedAt});
  } catch (error) {
    console.log('Error could not write to audit log.');
  }
};

export const loadAuditLogsAsync = async (docPath: string) => {
  try {
    const querySnapshot = await firebase
      .firestore()
      .collection(`${docPath}/auditLog`)
      .get();
    const auditLogs = querySnapshot.docs.map((doc) => {
      return {
        id: doc.id,
        user: doc.data().user,
        action: doc.data().action,
        updatedAt: doc.data().updatedAt.toMillis(),
      } as AuditLog;
    });
    return auditLogs;
  } catch (error) {
    console.log('Error could not fetch audit log.');
    return null;
  }
};

export function capitalizeFirstLetter(text: string) {
  return text.charAt(0).toUpperCase() + text.slice(1);
}

export const calculateHomeGeoPoint = (coordinates: Coordinates[]) => {
  const reduceData = coordinates.reduce(
    (initialize, next) => {
      initialize.x +=
        Math.cos((next.lat * Math.PI) / 180) *
        Math.cos((next.lng * Math.PI) / 180);
      initialize.y +=
        Math.cos((next.lat * Math.PI) / 180) *
        Math.sin((next.lng * Math.PI) / 180);
      initialize.z += Math.sin((next.lat * Math.PI) / 180);
      return initialize;
    },
    {x: 0, y: 0, z: 0}
  );

  const centralLongitude = Math.atan2(
    reduceData.y / coordinates.length,
    reduceData.x / coordinates.length
  );
  const centralSquareRoot = Math.sqrt(
    ((reduceData.x / coordinates.length) * reduceData.x) / coordinates.length +
      ((reduceData.y / coordinates.length) * reduceData.y) / coordinates.length
  );
  const centralLatitude = Math.atan2(
    reduceData.z / coordinates.length,
    centralSquareRoot
  );

  return {
    latitude: (centralLatitude * 180) / Math.PI,
    longitude: (centralLongitude * 180) / Math.PI,
  };
};

export const calculateHomeZoomLevel = (data: {
  latOne: number;
  lngOne: number;
  latTwo: number;
  lngTwo: number;
}) => {
  if (data.latOne === data.latTwo && data.lngOne === data.lngTwo) {
    return 0;
  } else {
    const radlat1 = (Math.PI * data.latOne) / 180;
    const radlat2 = (Math.PI * data.latTwo) / 180;
    const theta = data.lngOne - data.lngTwo;
    const radtheta = (Math.PI * theta) / 180;
    let dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    dist = dist * 1.609344;

    if (dist <= 5000) return 5;
    if (dist > 5000 && dist <= 6800) return 4;
    if (dist > 6800 && dist <= 7300) return 3;
    if (dist > 7300 && dist <= 7800) return 2;
    if (dist > 7800) return 1;

    return 5;
  }
};
