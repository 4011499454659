import {Button, Result, List, Modal, Image, Tooltip, Row, Col} from 'antd';
import CircleK from 'assets/company/logo-circlek.svg';
import OKQ8 from 'assets/company/logo-okq8.svg';
import Preem from 'assets/company/logo-preem.svg';
import St1 from 'assets/company/logo-st1.png';
import {
  companiesStateSelector,
  Company,
  deleteCompanyAsync,
} from 'store/slices/companiesSlice';
import {useDispatch, useSelector} from 'react-redux';
import {useState} from 'react';
import {CompanyEditModal} from '../CompanyEditModal';
import {DeleteOutlined, EditOutlined} from '@ant-design/icons';
import {useTranslation} from 'react-i18next';
import {hasSuperAdminRights} from 'constants/roles';
import {authStateSelector} from '../../../store/slices/authSlice';

export const CompanyList = (): JSX.Element => {
  const companiesState = useSelector(companiesStateSelector);
  const authState = useSelector(authStateSelector);
  const dispatch = useDispatch();
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState<Company | null>(null);
  const {t} = useTranslation();

  if (!hasSuperAdminRights(authState)) {
    return <Result status="403" title="403" subTitle={t('error.403')} />;
  }

  if (companiesState.hasError) {
    return <Result status="403" title="403" subTitle={companiesState.error} />;
  }

  const logo = (name: string) => {
    if (name === 'Circle K') {
      return CircleK;
    }
    if (name === 'OKQ8') {
      return OKQ8;
    }
    if (name === 'Preem') {
      return Preem;
    }
    if (name === 'ST1') {
      return St1;
    }
    return '';
  };

  const startEditModal = (company: Company) => {
    setEditModalVisible(true);
    setSelectedCompany(company);
  };

  const deleteConfirm = (company: Company) => {
    Modal.confirm({
      title: t('modifyEntity.delete', {entity: t('company.name')}),
      content: t('confirm.delete', {entity: company.name}),
      okText: t('buttons.yes'),
      onOk() {
        dispatch(deleteCompanyAsync(company.id));
      },
      onCancel() {},
      cancelText: t('buttons.cancel'),
    });
  };

  return (
    <div>
      <List
        size="large"
        itemLayout="horizontal"
        bordered
        rowKey="id"
        loading={companiesState.isLoading}
        dataSource={companiesState.companies}
        renderItem={(company) => (
          <List.Item
            key={company.id}
            actions={[
              <Tooltip
                title={t('modifyEntity.edit', {entity: t('company.name')})}>
                <Button
                  style={{padding: 0}}
                  onClick={() => {
                    startEditModal(company);
                  }}
                  type="link">
                  <EditOutlined />
                </Button>
              </Tooltip>,
              <Tooltip
                title={t('modifyEntity.delete', {entity: t('company.name')})}>
                <Button
                  style={{padding: 0, color: '#'}}
                  onClick={() => {
                    deleteConfirm(company);
                  }}
                  type="link"
                  danger>
                  <DeleteOutlined />
                </Button>
              </Tooltip>,
            ]}>
            <List.Item.Meta
              avatar={
                <Image width={60} src={logo(company.name)} preview={false} />
              }
              title={company.name}
              description={
                <div>
                  <Row>
                    <p>{company.email}</p>
                  </Row>
                  {company.contacts.length > 0 && (
                    <Row>
                      <strong>{t('userTypes.administrators')}</strong>
                    </Row>
                  )}
                  <Row gutter={48}>
                    {company.contacts.map((contact, index) => (
                      <Col key={index}>
                        {contact.name}
                        <br />
                        {contact.email}
                        <br />
                        {contact.phone}
                      </Col>
                    ))}
                  </Row>
                </div>
              }
            />
          </List.Item>
        )}
      />
      {selectedCompany != null && (
        <CompanyEditModal
          onClose={() => {
            setEditModalVisible(false);
            setSelectedCompany(null);
          }}
          visible={editModalVisible}
          company={selectedCompany}
        />
      )}
    </div>
  );
};
