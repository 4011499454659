import {Form, DatePicker, Button} from 'antd';
import {CompanySelect} from 'components/form/CompanySelect';
import * as moment from 'moment';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {capitalizeFirstLetter} from 'utils/helpers';

export interface StatsQuery {
  companyId: string;
  startDate: moment.Moment; // Date?
  endDate: moment.Moment; // Date?
}

export interface StatsFilterFormProps {
  buttonText?: string;
  onQuery: (values: StatsQuery) => void;
}

export const StatsFilterForm = (props: StatsFilterFormProps): JSX.Element => {
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});
  const {t} = useTranslation();

  useEffect(() => {
    forceUpdate({});
  }, []);

  const onSubmit = (values: StatsQuery) => {
    const sd = values.startDate?.startOf('day');
    const ed = values.endDate?.endOf('day');
    if (props.onQuery)
      props.onQuery({companyId: values.companyId, startDate: sd, endDate: ed});
  };

  return (
    <Form<StatsQuery>
      requiredMark={false}
      form={form}
      name="stats_query_form"
      layout="inline"
      onFinish={onSubmit}>
      <Form.Item
        label={capitalizeFirstLetter(t('company.name'))}
        name="companyId"
        rules={[
          {
            required: true,
            message: t('form.required.pleaseEnterEntity', {
              entity: t('company.name'),
            }),
          },
        ]}>
        <CompanySelect />
      </Form.Item>
      <Form.Item
        label={capitalizeFirstLetter(t('time.startDate'))}
        name="startDate"
        rules={[
          {
            required: true,
            message: `${t('time.startDate')} ${t('form.required.name')}`,
          },
        ]}>
        <DatePicker format="YYYY-MM-DD" />
      </Form.Item>
      <Form.Item
        label={capitalizeFirstLetter(t('time.endDate'))}
        name="endDate"
        rules={[
          {
            required: true,
            message: `${t('time.endDate')} ${t('form.required.name')}`,
          },
        ]}>
        <DatePicker format="YYYY-MM-DD" />
      </Form.Item>
      <Form.Item shouldUpdate>
        {() => (
          <Button
            type="primary"
            htmlType="submit"
            disabled={
              !form.isFieldsTouched(true) ||
              !!form.getFieldsError().filter(({errors}) => errors.length).length
            }>
            {props.buttonText ?? t('statistics.showStatistics')}
          </Button>
        )}
      </Form.Item>
    </Form>
  );
};
