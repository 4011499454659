import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import {firebase} from 'services/firebase';
import * as app from 'firebase/app';

import {useDispatch} from 'react-redux';
import {
  authenticated,
  loadUserProfileByUIdAsync,
  logout,
  logoutAsync,
  setEmailVerified,
} from 'store/slices/authSlice';
import {message} from 'antd';

export interface AuthContextValue {
  currentUser: app.default.User | null;
}

const AuthContext = createContext<AuthContextValue>({
  currentUser: null,
});

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider = (props: AuthProviderProps) => {
  const dispatch = useDispatch();
  const [currentUser, setCurrentUser] = useState<app.default.User | null>(null);
  const [pending, setPending] = useState(true);

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(
      (user) => {
        if (!user) {
          dispatch(logout());
          setCurrentUser(null);
        } else if (!user.emailVerified) {
          dispatch(setEmailVerified(false));
          dispatch(logoutAsync());
        } else {
          setCurrentUser(user);
          dispatch(authenticated());
          dispatch(loadUserProfileByUIdAsync(user.uid));
        }
        setPending(false);
      },
      (error) => message.error(error)
    );
    return () => unsubscribe();
  }, [dispatch]);

  if (pending) {
    return (
      <div className="loading">
        <div className="loader" />
      </div>
    );
  }

  return (
    <AuthContext.Provider value={{currentUser}}>
      {props.children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
