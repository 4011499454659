import {Menu} from 'antd';
import {Link, useHistory} from 'react-router-dom';
import {companiesStateSelector} from 'store/slices/companiesSlice';
import {useDispatch, useSelector} from 'react-redux';
import {
  changeSelectedCompany,
  sessionStateSelector,
} from 'store/slices/sessionSlice';
import {useTranslation} from 'react-i18next';

const {SubMenu} = Menu;

export const SuperAdminLayout = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const companies = useSelector(companiesStateSelector);
  const session = useSelector(sessionStateSelector);
  const {t} = useTranslation();

  return (
    <>
      <Menu theme="dark" mode="horizontal" style={{position: 'absolute'}}>
        <Menu.Item key="personal-overview">
          <Link to={'/personal-overview'}>{t('menu.personalOverview')}</Link>
        </Menu.Item>
        <SubMenu key="system" title={t('menu.system.name')}>
          <SubMenu
            key="system-tools"
            title={t('menu.system.subMenu.tools.name')}>
            <Menu.Item key="tools-sms-test">
              <Link to={'/admin/testsms'}>
                {t('menu.system.subMenu.tools.subMenu.sms')}
              </Link>
            </Menu.Item>
            <Menu.Item key="tools-mail-test">
              <Link to={'/admin/testmail'}>
                {t('menu.system.subMenu.tools.subMenu.email')}
              </Link>
            </Menu.Item>
          </SubMenu>
          <Menu.Item key={'system-companies'}>
            <Link to={'/admin/companies'}>
              {t('menu.system.subMenu.companies')}
            </Link>
          </Menu.Item>
        </SubMenu>
        <SubMenu
          key="company"
          title={session.selectedCompany?.name ?? t('menu.company.name')}>
          <SubMenu
            key="company-select"
            title={t('menu.company.subMenu.select')}>
            {companies.companies.map((company) => (
              <Menu.Item
                onClick={() => {
                  dispatch(changeSelectedCompany(company));
                  history.push('/overview');
                }}
                key={company.id}
                hidden={session.selectedCompany?.name === company.name}>
                {company.name}
              </Menu.Item>
            ))}
          </SubMenu>
          <Menu.Item key="company-stops">
            <Link to={'/overview'}>
              {t('menu.company.subMenu.depotOverview')}
            </Link>
          </Menu.Item>
          <Menu.Item key="company-users">
            <Link to={'/users'}>{t('menu.company.subMenu.users')}</Link>
          </Menu.Item>
          <Menu.Item key="products">
            <Link to={'/products'}>{t('menu.company.subMenu.products')}</Link>
          </Menu.Item>
          <Menu.Item key="company-statistics">
            <Link to={'/admin/dashboard'}>
              {t('menu.company.subMenu.statistics')}
            </Link>
          </Menu.Item>
          <Menu.Item key="company-export-stops">
            <Link to={'/admin/export-stops'}>
              {t('menu.company.subMenu.exportStops')}
            </Link>
          </Menu.Item>
        </SubMenu>
        <Menu.Item key="depots">
          <Link to={'/depots'}>{t('menu.depots')}</Link>
        </Menu.Item>
        <Menu.Item key="hauliers">
          <Link to={'/hauliers'}>{t('menu.hauliers')}</Link>
        </Menu.Item>
        {session.selectedCompany?.id ===
          '87b69206-a0fa-11eb-bcbc-0242ac130002' && (
          <Menu.Item key="fullscreen-dashboard">
            <Link to={`/dashboard/${session.selectedCompany?.id}`}>
              Dashboard
            </Link>
          </Menu.Item>
        )}
        <Menu.Item key="contacts">
          <Link to={'/contacts'}>{t('menu.contacts')}</Link>
        </Menu.Item>
        <Menu.Item key="support">
          <Link to={'/support'}>{t('menu.support')}</Link>
        </Menu.Item>
      </Menu>
    </>
  );
};
