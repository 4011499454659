import {Calendar, momentLocalizer} from 'react-big-calendar';
import moment from 'moment';
import {StopMessage} from 'store/slices/stopMessagesSlice';
import {notification} from 'antd';
import '../../../styles/bigcalendar.css';
import i18next from 'i18next';
import i18n from 'utils/i18n';
import {DepotStatusCard} from 'components/cards/DepotStatusCard';

export interface ActiveStopMessagesData {
  id?: string;
  subject: string;
  title: string;
  notificationTitle: string;
  start: Date;
  end: Date;
  message: string;
  urgency: string;
  company?: string;
  depot?: string;
  depotId?: string;
  products?: string[];
  createdBy?: string;
  downTimeDays?: number;
}

interface BigCalendarPropsData {
  calendarEvent: StopMessage[];
}

const BigCalendar = (props: BigCalendarPropsData) => {
  const activeStopMessages: StopMessage[] = props.calendarEvent;
  const localizer: any = momentLocalizer(moment);

  let result = activeStopMessages.map((message) => ({
    subject: message.subject,
    title: `${i18n.t(`stopMessage.subject.${message.subject}`)} - ${new Date(
      message.startDate
    ).toLocaleString()} ${i18next.t('genericWords.to')} ${new Date(
      message.endDate
    ).toLocaleString()} - ${message.depot.company.name}, ${message.depot.name}`,
    notificationTitle: i18n.t(`stopMessage.subject.${message.subject}`),
    start: new Date(message.startDate),
    end: new Date(message.endDate),
    message: message.message,
    urgency: message.urgency,
    company: message.depot.company.name,
    depot: message.depot.name,
    depotId: message.depot.id,
    products: message.products.map((product) => product.name),
    createdBy: message.createdBy?.name,
    id: message.id,
    downTimeDays: message.downTimeDays,
  }));

  const openMessageInfo = (messageData: ActiveStopMessagesData) => {
    const msgHeader = (
      <a href={`/depot/${messageData.depotId}`}>
        {`${messageData.depot}`} - {`${messageData.company}`}
      </a>
    );

    const msgContent = <DepotStatusCard activeStopMessagesData={messageData} />;
    notification.open({
      message: msgHeader,
      description: msgContent,
      duration: 120,
      top: 128,
    });
  };

  const eventStyleGetter = (event: ActiveStopMessagesData) => {
    switch (event.notificationTitle) {
      case 'Maintenance Work':
        return {
          className: 'maintenance-work-event',
        };
      case 'Technical Issue':
        return {
          className: 'technical-issue-event',
        };
      case 'Products Shortage':
        return {
          className: 'tproducts-shortage-event',
        };
      case 'Boat Unloading':
        return {
          className: 'boat-unloading-event',
        };
      default:
        return {
          className: 'other-events',
        };
    }
  };

  return (
    <Calendar
      localizer={localizer}
      events={result}
      style={{height: 1000}}
      defaultView="month"
      views={['month', 'day', 'week']}
      culture={'es'}
      messages={{
        today: i18next.t('time.today'),
        week: i18next.t('time.week'),
        next: i18next.t('buttons.next'),
        previous: i18next.t('buttons.back'),
        day: i18next.t('time.day'),
        month: i18next.t('time.month'),
      }}
      onSelectEvent={(event: ActiveStopMessagesData) => openMessageInfo(event)}
      eventPropGetter={(event) => eventStyleGetter(event)}
    />
  );
};

export default BigCalendar;
