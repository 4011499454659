import {useEffect, useState, useCallback} from 'react';
import {Button, message, notification} from 'antd/es';
import {CloudDownloadOutlined, ExportOutlined} from '@ant-design/icons';
import {firebase} from 'services/firebase';
import {useSelector} from 'react-redux';
import {sessionStateSelector} from 'store/slices/sessionSlice';
import {useTranslation} from 'react-i18next';

export const ProductExport = (): JSX.Element => {
  const {t} = useTranslation();
  const [submitted, setSubmitted] = useState(false);
  const session = useSelector(sessionStateSelector);

  const exportCsv = useCallback(
    async (companyId) => {
      try {
        const response = await firebase
          .functions()
          .httpsCallable('exportProducts')({
            companyId,
          });
        notification.open({
          duration: 60,
          key: 'notification-org-id-0',
          message: t('file.download'),
          description: (
            <div>
              <a href={response.data}>
                <CloudDownloadOutlined
                  className={'attention-animation'}
                  style={{fontSize: '48px', color: '#08c'}}
                />
              </a>
            </div>
          ),
        });
      } catch (error) {
        message.error(error.message);
      }
    },
    [t]
  );

  useEffect(() => {
    let isMounted = true;
    if (submitted && session.selectedCompany) {
      exportCsv(session.selectedCompany.id).finally(() => {
        if (isMounted) setSubmitted(false);
      });
    }
    return () => {
      isMounted = false;
    };
  }, [submitted, session.selectedCompany, exportCsv]);

  return (
    <Button
      icon={<ExportOutlined />}
      loading={submitted}
      key="export-products-btn"
      onClick={() => setSubmitted(true)}>
      {t('export.button.exportToCsv')}
    </Button>
  );
};
