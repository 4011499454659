import * as React from 'react';
import {Row, Col} from 'antd';
import {Result} from 'antd';
import {depotStateSelector, loadDepotsAsync} from 'store/slices/depotsSlice';
import {useSelector} from 'react-redux';
import {DepotMap} from 'components/DepotMap';
import {sessionStateSelector} from 'store/slices/sessionSlice';
import {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {
  loadAllActiveStopMessages,
  stopMessageSelector,
} from 'store/slices/stopMessagesSlice';
import {useTranslation} from 'react-i18next';
import {MessageListTable} from 'components/tables/MessageListTable';
import {useParams} from 'react-router-dom';
import {getOverviewUserActiveStops} from 'store/slices/usersSlice';

const REFRESH_RATE_MILLIS = 60 * 1000 * 60;

export const DashboardView = (): JSX.Element => {
  const {secret} = useParams<{secret: string}>();
  const dispatch = useDispatch();
  const userDepotsStops = useSelector(getOverviewUserActiveStops());
  const depotState = useSelector(depotStateSelector);
  const stopMessageState = useSelector(stopMessageSelector);
  const session = useSelector(sessionStateSelector);
  const {t} = useTranslation();

  useEffect(() => {
    dispatch(loadAllActiveStopMessages());
  }, [dispatch]);

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(loadDepotsAsync());
      dispatch(loadAllActiveStopMessages());
    }, REFRESH_RATE_MILLIS);
    return () => clearInterval(interval);
  }, [dispatch]);

  if (!session.selectedCompany) {
    return (
      <Result
        status="404"
        title="Dashboard Session Expired"
        subTitle={t('depot.overview.error.404')}
      />
    );
  }

  if (session.selectedCompany.id !== secret) {
    return (
      <Result
        status="404"
        title="Dashboard not available"
        subTitle={'Contact support to get more info on this feature'}
      />
    );
  }

  return (
    <div className={'site-dashboard-content'}>
      <Row gutter={[16, 16]}>
        <Col lg={{span: 24}}>
          <DepotMap
            isLoading={stopMessageState.isLoading || depotState.isLoading}
            statusDepots={userDepotsStops}
            height={600}
            showDepotFilter={false}
          />
        </Col>
      </Row>
      <Row justify="space-between" align="bottom" gutter={[16, 16]}>
        <Col lg={{span: 24}}>
          <MessageListTable
            compact={true}
            loading={stopMessageState.isLoading || depotState.isLoading}
            stopMessages={userDepotsStops.flatMap(
              (depot) => depot.activeStopMessages
            )}
          />
        </Col>
      </Row>
    </div>
  );
};
