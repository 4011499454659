import {useState, useEffect} from 'react';
import {message} from 'antd';
import {useDispatch, useSelector} from 'react-redux';
import {
  sendPasswordResetEmailAsync,
  getEmailByActionCode,
} from 'store/slices/authSlice';
import {useTranslation} from 'react-i18next';
import {Redirect} from 'react-router-dom';
import {sessionStateSelector} from 'store/slices/sessionSlice';
import {firebase} from 'services/firebase';

export const VerifyEmail = (props: any) => {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const session = useSelector(sessionStateSelector);
  const [finished, setFinished] = useState(false);

  useEffect(() => {
    const verifier = async () => {
      try {
        const emailAddress = await getEmailByActionCode(props.actionCode);
        await firebase.auth().applyActionCode(props.actionCode);
        if (emailAddress) {
          dispatch(
            sendPasswordResetEmailAsync(
              emailAddress,
              session.selectedAntDLocale.locale,
              t('auth.verifyEmail.resetPasswordEmailSent', {
                email: emailAddress,
              })
            )
          );
        }
        setFinished(true);
      } catch (error) {
        message.error(error.message.toString(), 5);
      }
    };
    verifier().then();
  }, [dispatch, props.actionCode, session.selectedAntDLocale.locale, t]);

  if (finished) return <Redirect to="/" />;

  return (
    <div className="site-view-welcome" style={{paddingTop: '128px'}}></div>
  );
};
