import {createSelector} from 'reselect';
import {authStateSelector} from 'store/slices/authSlice';
import {
  Depot,
  depotStateSelector,
  getDepotByIdSelector,
} from 'store/slices/depotsSlice';
import {stopMessageSelector} from 'store/slices/stopMessagesSlice';
import {ADMINISTRATOR, DEPOT_OPERATOR, SUPER_ADMIN} from 'constants/roles';
import {User} from 'store/slices/usersSlice';

export const getUserDepots = createSelector(
  [authStateSelector, depotStateSelector],
  (authState, depotState) =>
    depotState.depots.filter((depot) =>
      authState.user?.depots.includes(depot.id) && depot.deleted === false
    )
);

const getDraftsVisibility = (
  user: User | null,
  depot: Depot | undefined
): string[] => {
  if (user === null || !depot) return [];
  const all = ['SEND_NOW', 'SEND_LATER', 'DRAFT'];
  const hideDraft = ['SEND_NOW', 'SEND_LATER'];
  if (user.role === DEPOT_OPERATOR && user.depots.includes(depot.id)) {
    return all;
  } else if (
    user.role === ADMINISTRATOR &&
    depot.company.id === user.company?.id
  ) {
    return all;
  } else if (user.role === SUPER_ADMIN) {
    return all;
  } else {
    // for all other roles hide drafts
    return hideDraft;
  }
};

export const getActiveStopsByDepot = (depotId: string) =>
  createSelector(
    [stopMessageSelector, authStateSelector, getDepotByIdSelector(depotId)],
    (stopMessageState, authState, depot) => {
      const visibleStatuses = getDraftsVisibility(authState.user, depot);
      return stopMessageState.activeStopMessages.filter(
        (stop) =>
          stop.depot.id === depotId &&
          stop.deleted === false &&
          visibleStatuses.includes(stop.status)
      );
    }
  );
