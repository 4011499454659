import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
  getDefaultMiddleware,
} from '@reduxjs/toolkit';
import sessionReducer from 'store/slices/sessionSlice';
import companiesReducer from 'store/slices/companiesSlice';
import depotsReducer from 'store/slices/depotsSlice';
import productsReducer from 'store/slices/productSlice';
import productTypesReducer from 'store/slices/productTypesSlice';
import authReducer from 'store/slices/authSlice';
import usersReducer from 'store/slices/usersSlice';
import stopMessagesReducer from 'store/slices/stopMessagesSlice';
import reminderReducer from 'store/slices/reminderSlice';
import infoMessagesReducer from 'store/slices/infoMessagesSlice';
import haulageReducer from 'store/slices/haulageSlice';
import supportReducer from 'store/slices/supportSlice';
import weeklyExportReducer from "store/slices/weeklyExportSlice";

const combinedReducer = combineReducers({
  auth: authReducer,
  session: sessionReducer,
  companies: companiesReducer,
  depots: depotsReducer,
  products: productsReducer,
  stopMessages: stopMessagesReducer,
  reminder: reminderReducer,
  infoMessages: infoMessagesReducer,
  productTypes: productTypesReducer,
  users: usersReducer,
  haulage: haulageReducer,
  support: supportReducer,
  weeklyExport: weeklyExportReducer,
});

const middlewares = [...getDefaultMiddleware()];

export const store = configureStore({
  reducer: combinedReducer,
  middleware: middlewares,
  devTools: process.env.REACT_APP_ENVIRONMENT === 'localhost',
});

export type RootState = ReturnType<typeof store.getState>;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  any,
  Action<string>
>;
