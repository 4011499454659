import * as React from 'react';
import {useSelector} from 'react-redux';
import {Redirect, Route, RouteComponentProps} from 'react-router-dom';
import {useAuth} from 'providers/AuthProvider';
import {authStateSelector} from 'store/slices/authSlice';
import {AccessDenied} from 'components/cards/AccessDenied';

export interface ProtectedRouteProps {
  component?:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>;
  exact: boolean;
  path: string;
  accessibilityRole?: string[];
}

export const ProtectedRoute = (props: ProtectedRouteProps) => {
  const auth = useAuth();
  const useInfo = useSelector(authStateSelector);
  const currentUserRole = useInfo.user?.role;
  const accessibilityRole = props.accessibilityRole;

  const urlValidator = async () => {
    if (currentUserRole && accessibilityRole)
      return accessibilityRole?.includes(currentUserRole);
  };

  if (auth.currentUser && urlValidator()) {
    return (
      <Route
        path={props.path}
        exact={props.exact}
        component={props.component}
      />
    );
  } else {
    if (!urlValidator()) return <AccessDenied />;
  }
  return <Redirect to="/login?permission-denied=1" />;
};
