import {useState} from 'react';
import {useAuth} from 'providers/AuthProvider';
import {Redirect} from 'react-router-dom';
import {Button, Col, Form, Input, Row} from 'antd';
import {useDispatch, useSelector} from 'react-redux';
import {sendPasswordResetEmailAsync} from 'store/slices/authSlice';
import {useTranslation} from 'react-i18next';
import {ViewHeader} from 'components/layout/ViewHeader';
import {MailOutlined} from '@ant-design/icons';
import {sessionStateSelector} from 'store/slices/sessionSlice';

export const ForgottenPasswordView = () => {
  const dispatch = useDispatch();
  const auth = useAuth();
  const session = useSelector(sessionStateSelector);
  const {t} = useTranslation();
  const [email, setEmail] = useState('');
  const [form] = Form.useForm();

  if (auth.currentUser) return <Redirect to="/login" />;

  const onFinish = async () => {
    dispatch(
      sendPasswordResetEmailAsync(
        email,
        session.selectedAntDLocale.locale,
        t('auth.emailSent')
      )
    );
    form.resetFields();
  };

  return (
    <div className="site-view-welcome" style={{paddingTop: '128px'}}>
      <Row>
        <Col flex="auto" />
        <Col
          flex="300px"
          style={{background: 'white', padding: '16px', borderRadius: '8px'}}>
          <ViewHeader title={t('auth.forgotPassword')} />
          <Form
            form={form}
            name="forgot-password-form"
            className="login-form"
            hideRequiredMark
            onFinish={onFinish}>
            <Form.Item
              name="email"
              rules={[
                {required: true, message: t('auth.error.email.required')},
                {
                  type: 'email',
                  message: t('auth.error.email.validEmail'),
                },
              ]}>
              <Input
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                placeholder={t('form.placeholder.enterEntity', {
                  entity: t('email.name'),
                })}
                prefix={<MailOutlined className="site-form-item-icon" />}
              />
            </Form.Item>
            <Form.Item>
              <Button block type="primary" htmlType="submit">
                {t('auth.sendInstructionsEmail')}
              </Button>
            </Form.Item>
          </Form>
        </Col>
        <Col flex="auto" />
      </Row>
    </div>
  );
};
