export const STOP_MESSAGE_STATUS = [
  'DRAFT',
  'SEND_NOW',
  'SEND_LATER',
  'INACTIVE',
];
export const STOP_MESSAGE_SUBJECTS = [
  'MAINTENANCE_WORK',
  'TECHNICAL_ISSUE',
  'PRODUCTS_SHORTAGE',
  'BOAT_UNLOADING',
  'ELECTRICAL_WORK',
  'TRAINING',
];
export const STOP_MESSAGE_URGENCY = ['ACUTE', 'PLANNED'];
