import {ConfigProvider, Empty, Table, Menu, Result, Modal} from 'antd';
import {useDispatch, useSelector} from 'react-redux';
import {useState} from 'react';
import {
  getActiveProductSelector,
  Product,
  productStateSelector,
  softDeleteProductAsync,
} from 'store/slices/productSlice';
import {Button, Dropdown, Collapse, Input, Space} from 'antd';
import {EditOutlined} from '@ant-design/icons';
import {ColumnsType} from 'antd/lib/table';
import {SearchOutlined} from '@ant-design/icons';
import {FilterDropdownProps} from 'antd/lib/table/interface';
import i18next from 'i18next';
import {productTypesStateSelector} from 'store/slices/productTypesSlice';
import {useProducts} from 'services/hooks/useProducts';
import {ViewHeader} from 'components/layout/ViewHeader';
import {sessionStateSelector} from 'store/slices/sessionSlice';
import {ProductCreateModal} from 'views/product/ProductCreateModal';
import {ProductEditModal} from 'views/product/ProductEditModal';
import {useTranslation} from 'react-i18next';
import {capitalizeFirstLetter} from 'utils/helpers';
import {ProductExport} from 'views/product/ProductExport';

const {Panel} = Collapse;

/***
 * Column Search Component and stuff
 */
const getColumnSearchProps = (dataIndex: string) => ({
  filterDropdown: ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }: FilterDropdownProps) => (
    <div style={{padding: 12}}>
      <Input
        placeholder={`${i18next.t('buttons.search')} ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={(e) =>
          setSelectedKeys(e.target.value ? [e.target.value] : [])
        }
        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        style={{width: 188, marginBottom: 8, display: 'block'}}
      />
      <Space>
        <Button
          type="primary"
          icon={<SearchOutlined />}
          size="small"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{width: 90}}>
          {i18next.t('buttons.search')}
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{width: 90}}>
          {i18next.t('buttons.reset')}
        </Button>
      </Space>
    </div>
  ),
  filterIcon: (filtered: boolean) => (
    <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}} />
  ),
  onFilter: (value: any, record: any) => {
    return record[dataIndex]
      ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
      : '';
  },
});

const handleSearch = (selectedKeys: any, confirm: any, _: any) => {
  if (confirm) confirm();
};

const handleReset = (clearFilters: any) => {
  if (clearFilters) clearFilters();
};

const emptyProducts = () => (
  <Empty description={i18next.t('product.noProductsFound')} />
);

export const ProductListView = (): JSX.Element => {
  useProducts();
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const session = useSelector(sessionStateSelector);
  const productState = useSelector(productStateSelector);
  const activeProducts = useSelector(getActiveProductSelector);
  const productTypeState = useSelector(productTypesStateSelector);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);

  const startEditModal = (product: Product) => {
    setEditModalVisible(true);
    setSelectedProduct(product);
  };

  if (!session.selectedCompany) {
    return (
      <Result status="404" title="404" subTitle={t('product.error.404')} />
    );
  }
  if (productState.hasError) {
    return <Result status="403" title="403" subTitle={productState.error} />;
  }

  const buildFilterTypes = () => {
    return productTypeState.types.map((type) => ({
      text: t(`productType.${type.name}`),
      value: type.id,
    }));
  };

  const deleteConfirm = (product: Product) => {
    Modal.confirm({
      title: t('modifyEntity.delete', {entity: t('product.name')}),
      content: `${t('confirm.delete', {entity: product.name})}`,
      okText: t('buttons.yes'),
      onOk() {
        dispatch(softDeleteProductAsync(product.id, product.company.id));
      },
      onCancel() {},
      cancelText: t('buttons.cancel'),
    });
  };

  /**
   * Action Menu for product
   * @param product
   */
  const actionMenu = (product: Product) => (
    <Menu>
      <Menu.Item key="edit" onClick={() => startEditModal(product)}>
        {t('buttons.edit')}
      </Menu.Item>
      <Menu.Item
        key="delete"
        onClick={() => {
          deleteConfirm(product);
        }}>
        {t('buttons.delete')}
      </Menu.Item>
    </Menu>
  );

  /**
   * Config Table Columns
   */
  const columns: ColumnsType<any> = [
    {
      title: capitalizeFirstLetter(t('product.productName')),
      dataIndex: 'name',
      defaultSortOrder: 'ascend',
      key: 'name',
      ...getColumnSearchProps('name'),
      sorter: (a: Product, b: Product) => {
        const val1 = a.name;
        const val2 = b.name;
        return (val1 > val2 ? 1 : 0) - (val2 > val1 ? 1 : 0);
      },
    },
    {
      title: t('genericWords.description'),
      dataIndex: 'description',
      key: 'description',
      ...getColumnSearchProps('description'),
      sorter: (a: Product, b: Product) => {
        const val1 = a.description;
        const val2 = b.description;
        return (val1 > val2 ? 1 : 0) - (val2 > val1 ? 1 : 0);
      },
    },
    {
      title: t('product.code'),
      dataIndex: 'code',
      key: 'code',
      ...getColumnSearchProps('code'),
      sorter: (a: Product, b: Product) => {
        const val1 = a.code;
        const val2 = b.code;
        return (val1 > val2 ? 1 : 0) - (val2 > val1 ? 1 : 0);
      },
    },
    {
      title: capitalizeFirstLetter(t('product.productType')),
      dataIndex: 'type',
      key: 'type',
      render: (_: any, product: Product) =>
        t(`productType.${product.type?.name}`),
      sorter: (a: Product, b: Product) => {
        const val1 = a.type?.name || '';
        const val2 = b.type?.name || '';
        return (val1 > val2 ? 1 : 0) - (val2 > val1 ? 1 : 0);
      },
      filters: buildFilterTypes(),
      onFilter: (value: any, product: Product) =>
        product.type?.id.includes(value),
    },
    {
      title: capitalizeFirstLetter(t('company.name')),
      dataIndex: 'company',
      key: 'company',
      render: (_: any, product: Product) => product.company?.name,
      sorter: (a: Product, b: Product) => {
        const val1: string = a.company?.name || '';
        const val2: string = b.company?.name || '';
        return (val1 > val2 ? 1 : 0) - (val2 > val1 ? 1 : 0);
      },
    },
    {
      title: t('time.createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_: any, product: Product) =>
        new Date(product.createdAt).toLocaleString(),
      sorter: (a: Product, b: Product) => {
        return a.createdAt - b.createdAt;
      },
    },
    {
      title: t('time.updatedAt'),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (_: any, product: Product) =>
        product.updatedAt
          ? new Date(product.updatedAt ?? 0).toLocaleString()
          : '',
      sorter: (a: Product, b: Product) => {
        return (a.updatedAt ?? 0) - (b.updatedAt ?? 0);
      },
    },
    {
      key: 'product-action-menu',
      render: (product: Product) => (
        <Dropdown overlay={actionMenu(product)} trigger={['click']}>
          <EditOutlined style={{fontSize: '24px', color: 'rgb(24,144,255)'}} />
        </Dropdown>
      ),
      title: '',
      width: 24,
    },
  ];

  return (
    <div className="site-main-content" style={{padding: '16px'}}>
      <ViewHeader
        title={t('product.list')}
        subTitle={session?.selectedCompany?.name}
        extra={<ProductExport />}
      />
      <Collapse collapsible="header" defaultActiveKey={['products-table']}>
        <Panel
          header={`${capitalizeFirstLetter(t('product.products'))} (${
            activeProducts.length
          })`}
          key="products-table"
          className="panel-body-no-padding"
          extra={[<ProductCreateModal key={'product-create-modal-win'} />]}>
          <ConfigProvider renderEmpty={emptyProducts}>
            <Table<Product>
              rowKey={'id'}
              loading={productState.isLoading}
              dataSource={activeProducts}
              columns={columns}
            />
          </ConfigProvider>
        </Panel>
      </Collapse>
      {selectedProduct != null && (
        <ProductEditModal
          onClose={() => {
            setEditModalVisible(false);
            setSelectedProduct(null);
          }}
          visible={editModalVisible}
          product={selectedProduct}
        />
      )}
    </div>
  );
};
