import {ViewHeader} from 'components/layout/ViewHeader';
import {Row, Col, Card, message, notification, Spin, Button, Alert} from 'antd';
import {CloudDownloadOutlined} from '@ant-design/icons';
import {
  ExportFilterForm,
  ExportQuery,
} from 'views/tools/partials/ExportFilterForm';
import {firebase} from 'services/firebase';
import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {authStateSelector} from 'store/slices/authSlice';
import {
  cancelSubscriptionAsync,
  createExportSubscriptionAsync,
  weeklyExportStateSelector,
} from 'store/slices/weeklyExportSlice';
import {useTranslation} from 'react-i18next';
import {useWeeklyExport} from 'services/hooks/useWeeklyExport';
import {Space} from 'antd/es';

export const ExportDepotStops = (): JSX.Element => {
  useWeeklyExport();
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const authState = useSelector(authStateSelector);
  const weeklyExportState = useSelector(weeklyExportStateSelector);

  const exportCsv = async (query: ExportQuery) => {
    try {
      setLoading(true);
      const response = await firebase
        .functions()
        .httpsCallable('exportStopMessages')({companyIds: query.companyIds});

      setLoading(false);

      notification.open({
        duration: 60,
        key: 'notification-stops-export-id-0',
        message: t('file.download'),
        description: (
          <div>
            <a href={response.data}>
              <CloudDownloadOutlined
                className={'attention-animation'}
                style={{fontSize: '48px', color: '#08c'}}
              />
            </a>
          </div>
        ),
      });
    } catch (error) {
      message.error(error.message);
      setLoading(false);
    }
  };

  return (
    <div className="site-main-content" style={{padding: '16px'}}>
      <ViewHeader title={t('menu.company.subMenu.exportStops')} />
      <Row>
        <Col span={24} style={{paddingTop: '16px'}}>
          <Spin spinning={loading}>
            <Card style={{paddingTop: '16px'}}>
              <ExportFilterForm
                onQuery={exportCsv}
                buttonText={t('export.button.exportToCsv')}
              />
            </Card>
          </Spin>
        </Col>
      </Row>
      <Row>
        <Col span={24} style={{paddingTop: '16px'}}>
          <Spin spinning={loading}>
            <Card
              title={t('export.weeklyAutomated')}
              loading={
                weeklyExportState.isLoading || authState.isLoadingProfile
              }>
              <Space
                direction="vertical"
                size="middle"
                style={{display: 'flex'}}>
                <p>
                  {t('export.automatedInstructions', {
                    email: authState.user?.email ?? 'unknown',
                  })}
                </p>
                {weeklyExportState.hasSubscription && (
                  <Alert
                    message="Active subscription"
                    description={`You currently have an active subscription for ${weeklyExportState.companyIds.length} companies`}
                    type="info"
                    action={
                      <Button
                        type="primary"
                        danger
                        onClick={() => dispatch(cancelSubscriptionAsync())}>
                        Cancel subscription
                      </Button>
                    }
                  />
                )}
                <ExportFilterForm
                  selectedCompanies={weeklyExportState.companyIds}
                  buttonText={'Save subscription'}
                  onQuery={(q) =>
                    dispatch(createExportSubscriptionAsync(q.companyIds))
                  }
                />
              </Space>
            </Card>
          </Spin>
        </Col>
      </Row>
    </div>
  );
};
