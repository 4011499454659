import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AppThunk, RootState} from 'store/index';
import {firebase} from 'services/firebase';
import {v4 as uuidv4} from 'uuid';
import {message} from 'antd';
import i18next from 'i18next';

export const COMPANIES_COLLECTION = 'companies';

export interface CompanyLight {
  id: string;
  name: string;
}

export interface Company {
  id: string;
  name: string;
  email: string;
  contacts: Contact[];
}

export interface Contact {
  email: string;
  name: string;
  phone: string;
}

interface CompaniesState {
  companies: Company[];
  hasError: boolean;
  error: string;
  isLoading: boolean;
}

const initialState = {
  companies: [],
  hasError: false,
  error: '',
  isLoading: true,
} as CompaniesState;

export const companiesSlice = createSlice({
  name: 'companies',
  initialState: initialState,
  reducers: {
    updating: (state) => {
      state.isLoading = true;
    },
    hasError: (state, action: PayloadAction<string>) => {
      state.hasError = true;
      state.error = i18next.t('error.anErrorOccurredEntity', {
        entity: action.payload,
      });
      state.isLoading = false;
    },
    update: (state, action: PayloadAction<Company[]>) => {
      state.companies = action.payload;
      state.isLoading = false;
    },
  },
});

/**
 * Load Companies Thunk
 */
export const loadCompaniesAsync = (): AppThunk => async (dispatch) => {
  try {
    dispatch(updating());
    const querySnapshot = await firebase
      .firestore()
      .collection(COMPANIES_COLLECTION)
      .get();

    const companies = querySnapshot.docs.map((doc) => {
      return {
        id: doc.id,
        name: doc.data().name,
        email: doc.data().email,
        contacts: doc.data().contacts,
      } as Company;
    });

    dispatch(update(companies));
  } catch (error) {
    dispatch(hasError(error.message.toString()));
  }
};

/**
 * Creates a new Company record (only super_users)
 * @param company
 * @return id
 */
export const createCompanyAsync = (company: Company): AppThunk => async (
  dispatch
) => {
  try {
    const uuid = uuidv4();
    const docRef = firebase
      .firestore()
      .collection(COMPANIES_COLLECTION)
      .doc(uuid);
    await docRef.set({...company, id: uuid});
    dispatch(loadCompaniesAsync());
    message.success(
      i18next.t('company.successfullyEntity', {
        entity: i18next.t('genericWords.created'),
      })
    );
    return docRef.id;
  } catch (error) {
    message.error(error.message.toString());
  }
};

/**
 * Updates Company record (only super_users)
 * @param company
 * @return id
 */
export const updateCompanyAsync = (
  companyFormValues: Company,
  company: Company
): AppThunk => async (dispatch) => {
  try {
    const docRef = firebase
      .firestore()
      .collection(COMPANIES_COLLECTION)
      .doc(company.id);
    await docRef.update({
      name: companyFormValues.name,
      email: companyFormValues.email,
      contacts: companyFormValues.contacts ?? [],
    });
    dispatch(loadCompaniesAsync());
    message.success(
      i18next.t('company.successfullyEntity', {
        entity: i18next.t('genericWords.edited'),
      })
    );
    return docRef.id;
  } catch (error) {
    message.error(error.message.toString());
  }
};

/**
 * Deletes a company by Id (only super_users should have the rights)
 * @todo Think about what should be deleted in form of subCollections products/users/depots etc.
 * @param id string
 */
export const deleteCompanyAsync = (id: string): AppThunk => async (
  dispatch
) => {
  try {
    const docRef = firebase
      .firestore()
      .collection(COMPANIES_COLLECTION)
      .doc(id);
    await docRef.delete();
    dispatch(loadCompaniesAsync());
    message.success(
      i18next.t('company.successfullyEntity', {
        entity: i18next.t('genericWords.deleted'),
      })
    );
  } catch (error) {
    message.error(error.message.toString());
  }
};

export const {updating, hasError, update} = companiesSlice.actions;
export const companiesStateSelector = (state: RootState): CompaniesState =>
  state.companies;

export default companiesSlice.reducer;
