import {useState, useEffect, useLayoutEffect, useRef} from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4maps from '@amcharts/amcharts4/maps';
import am4geodata_worldLow from '@amcharts/amcharts4-geodata/worldLow';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import {StatusDepot} from 'store/slices/depotsSlice';
import {Spin, notification, Select, Tag} from 'antd';
import {DepotStatusCard} from 'components/cards/DepotStatusCard';
import {calculateHomeGeoPoint, calculateHomeZoomLevel} from 'utils/helpers';
import {LabeledValue} from 'antd/es/select';

am4core.useTheme(am4themes_animated);

interface DepotMapInterface {
  isLoading: boolean;
  statusDepots: StatusDepot[];
  height?: number;
  showDepotFilter?: boolean;
}

export const DepotMap = (props: DepotMapInterface): JSX.Element => {
  const coordinates = props.statusDepots.map(
    (data) => data.geoLocation.coordinates
  );
  const homeGeoPoint = calculateHomeGeoPoint(coordinates);
  const homeZoomLevel = calculateHomeZoomLevel({
    latOne: Math.max(...coordinates.map((d) => d.lat)),
    lngOne: Math.max(...coordinates.map((d) => d.lng)),
    latTwo: Math.min(...coordinates.map((d) => d.lat)),
    lngTwo: Math.min(...coordinates.map((d) => d.lng)),
  });

  const mapRef = useRef<any>(null);
  const [visibleDepots, setVisibleDepots] = useState<string[]>([]);
  const openDepotInfo = (depot: StatusDepot) => {
    const msgHeader = (
      <a href={`/depot/${depot.id}`}>
        {`${depot.name}`} - {`${depot.company.name}`}
      </a>
    );
    const msgContent = <DepotStatusCard statusDepot={depot} />;
    notification.open({
      message: msgHeader,
      description: msgContent,
      duration: 120,
      top: 128,
    });
  };

  useEffect(() => {
    const all = props.statusDepots.map((depot) => depot.id);
    setVisibleDepots(all);
  }, [props.statusDepots]);

  useLayoutEffect(() => {
    const map = am4core.create('map', am4maps.MapChart);
    map.geodata = am4geodata_worldLow;
    map.projection = new am4maps.projections.Miller();
    map.homeZoomLevel = homeZoomLevel;
    map.homeGeoPoint = homeGeoPoint;
    // optimized for "North Eur"
    map.zoomControl = new am4maps.ZoomControl();

    const polygonSeries = map.series.push(new am4maps.MapPolygonSeries());
    polygonSeries.exclude = ['AQ'];
    polygonSeries.useGeodata = true;

    // Configure series
    let polygonTemplate = polygonSeries.mapPolygons.template;
    polygonTemplate.polygon.fillOpacity = 0.6;
    polygonTemplate.fillOpacity = 0.45;
    polygonTemplate.strokeOpacity = 0;

    // Add image series
    let imageSeries: any = map.series.push(new am4maps.MapImageSeries());
    imageSeries.mapImages.template.propertyFields.longitude = 'longitude';
    imageSeries.mapImages.template.propertyFields.latitude = 'latitude';
    imageSeries.mapImages.template.tooltipText = '{title}';
    imageSeries.mapImages.template.propertyFields.url = 'url';
    imageSeries.fill = am4core.color('#ffffff');
    imageSeries.mapImages.template.events.on(
      'hit',
      (ev: any) => {
        openDepotInfo(ev.target.dataItem.dataContext.depot);
      },
      map
    );

    let depotBullet = imageSeries.mapImages.template.createChild(
      am4core.Circle
    );
    depotBullet.radius = 3 / map.zoomLevel;
    depotBullet.propertyFields = {fill: 'color', pulsingProperty: 'pulsing'};
    depotBullet.nonScaling = true;

    let bulletPulsate = imageSeries.mapImages.template.createChild(
      am4core.Circle
    );
    bulletPulsate.radius = 3;
    bulletPulsate.propertyFields = {fill: 'color', pulsingProperty: 'pulsing'};

    bulletPulsate.events.on('inited', function (event: any) {
      if (event.target.pulsingProperty) {
        animateBullet(event.target);
      } else {
        const fixCircle = event.target;
        fixCircle.radius = 6;
        fixCircle.propertyFields.fill = 'color';
        fixCircle.nonScaling = true;
      }
    });

    function animateBullet(circle: am4core.Circle) {
      let animation = circle.animate(
        [
          {
            property: 'scale',
            from: 1 / map.zoomLevel,
            to: 7 / map.zoomLevel,
          },
          {property: 'opacity', from: 1, to: 0},
        ],
        2200,
        am4core.ease.circleOut
      );
      animation.events.on('animationended', function (event: any) {
        animateBullet(event.target.object);
      });
    }

    imageSeries.data = props.statusDepots
      .filter((item) => visibleDepots.includes(item.id))
      .map((depot) => ({
        title: `${depot.company.name} - ${depot.name}`,
        id: depot.id,
        depot: depot,
        latitude: depot.geoLocation.coordinates.lat ?? 0,
        longitude: depot.geoLocation.coordinates.lng ?? 0,
        color: depot.indicatorColor,
        pulsing: depot.activeStopMessages.length > 0,
      }));

    mapRef.current = map;

    return () => {
      map.dispose();
    };
  }, [props.statusDepots, visibleDepots, homeGeoPoint, homeZoomLevel]);

  const handleChange = (inputValue: any) => {
    setVisibleDepots(inputValue);
  };

  const ListOfOptions = () => {
    return props.statusDepots.map(
      (depot) =>
        ({
          value: depot.id,
          label: `${depot.company.name} - ${depot.name}`,
        } as LabeledValue)
    );
  };

  const tagRender = (depotData: any) => {
    const {label, value, closable, onClose} = depotData;
    const onPreventMouseDown = (event: any) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        color={
          props.statusDepots.find((depot) => depot.name === value)
            ?.activeStopMessages.length
            ? 'red'
            : 'green'
        }
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{marginRight: 3}}>
        {label}
      </Tag>
    );
  };

  return (
    <>
      {props.showDepotFilter && (
        <Select
          tagRender={tagRender}
          value={visibleDepots}
          mode="tags"
          style={{width: '100%'}}
          onChange={handleChange}
          tokenSeparators={[',']}
          placeholder="Filter the depots"
          options={ListOfOptions()}
        />
      )}
      <Spin spinning={props.isLoading}>
        <div
          id="map"
          style={{width: '100%', height: props.height ? props.height : '800px'}}
        />
      </Spin>
    </>
  );
};
