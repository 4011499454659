import {Select} from 'antd';
import {useSelector} from 'react-redux';
import {depotStateSelector} from 'store/slices/depotsSlice';
import {getUserDepots} from 'store/selectors';

const {Option} = Select;

interface UserDepotSelectProps {
  value?: string;
  onChange?: (value: string) => void;
}

export const UserDepotSelect = (props: UserDepotSelectProps): JSX.Element => {
  const depotState = useSelector(depotStateSelector);
  const userDepots = useSelector(getUserDepots);

  const handleChange = (value: string) => {
    if (props.onChange) props.onChange(value);
  };

  return (
    <Select
      value={props.value}
      key={'depot-select'}
      loading={depotState.isLoading}
      placeholder="Select depot"
      notFoundContent={'No available depots'}
      style={{width: 300, marginRight: '16px'}}
      onChange={handleChange}>
      {userDepots.map((depot) => (
        <Option key={depot.id} value={depot.id}>
          {depot.company.name} {depot.name} ({depot.country})
        </Option>
      ))}
    </Select>
  );
};
