import {createSlice} from '@reduxjs/toolkit';
import {AppThunk, RootState} from 'store/index';
import {Depot} from './depotsSlice';
import {firebase} from 'services/firebase';
import {message} from 'antd';
import i18next from 'i18next';
import {createCompanyDepotStopsQuery} from './stopMessagesSlice';

export const REMINDERS_COLLECTION = 'reminders';

export interface ScheduledReminder {
  uuid?: string;
  queued: boolean;
  recipientType: string;
  reminderType: string;
  sendDateTime: number;
  stopMessageId: string;
}

interface ReminderState {
  scheduledReminders: ScheduledReminder[];
  showEndDateDisplayMessageIssue: boolean; 
  error: string;
  isLoading: boolean;
}

const initialState = {
  scheduledReminders: [],
  showEndDateDisplayMessageIssue: false,
  error: '',
  isLoading: false,
} as ReminderState;

export const reminderSlice = createSlice({
  name: 'reminder',
  initialState: initialState,
  reducers: {
    setScheduledReminders: (state, action) => {
      state.scheduledReminders = action.payload;
    },
    clearScheduledReminders: (state) => {
      state.scheduledReminders = [];
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setShowEndDateDisplayMessageIssue: (state, action) => {
      state.showEndDateDisplayMessageIssue = action.payload;
    },
  },
});

const dataToScheduledReminder = (
  doc: firebase.firestore.QueryDocumentSnapshot
): ScheduledReminder => {
  return {
    uuid: doc.id,
    queued: doc.data().queued,
    recipientType: doc.data().recipientType,
    reminderType: doc.data().reminderType,
    sendDateTime: doc.data().sendDateTime.toMillis(),
    stopMessageId: doc.data().stopMessageId,
  } as ScheduledReminder;
};

/**
 * Fetches all reminders for a stopMessage collection - companies/depot/stopMessages/[reminders]
 * @param depot - points to the correct depot - companies/depot
 * @param stopMessageUUID - points to the correct stopMessage uuid - companies/depot/stopMessages
 * @returns void
 */
export const loadAllScheduledRemindersForStopMessage = (
  depot: Depot,
  stopMessageUUID: string
): AppThunk => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const stopMessageRefDoc = createCompanyDepotStopsQuery(
      depot.company.id,
      depot.id
    ).doc(stopMessageUUID);

    const querySnapshot = await stopMessageRefDoc
      .collection(REMINDERS_COLLECTION)
      .orderBy('sendDateTime')
      .get();

    const scheduledReminders = querySnapshot.docs.map((doc: any) => {
      return dataToScheduledReminder(doc);
    });

    dispatch(setScheduledReminders(scheduledReminders));
    dispatch(setLoading(false));
  } catch (error: any) {
    dispatch(setLoading(false));
    message.error(i18next.t('stopMessage.error.couldntLoadActive'));
  }
};

/**
 * Create new scheduled reminders collection for a stop message collection - companies/depot/stopMessages/[reminders]
 * @param docRef
 * @param stopMessageId
 * @param scheduledReminders
 */
export const createScheduledRemindersAsync = async (
  docRef: firebase.firestore.DocumentReference,
  stopMessageId: string,
  scheduledReminders: ScheduledReminder[]
) => {
  try {
    if (scheduledReminders.length === 0) return;
    const db = firebase.firestore();
    const batch = db.batch();

    scheduledReminders.forEach((reminder: any) => {
      // Only insert reminders to firebase with mandatory data.
      if (
        reminder.sendDateTime &&
        reminder.recipientType &&
        reminder.reminderType
      ) {
        const sendDateTimeStamp = firebase.firestore.Timestamp.fromMillis(
          reminder.sendDateTime
        );

        const scheduledReminder = {
          sendDateTime: sendDateTimeStamp,
          recipientType: reminder.recipientType,
          reminderType: reminder.reminderType,
          queued: reminder.queued !== undefined ? reminder.queued : true, // Already existing reminders gets the same queued data set. New reminders get queued set to true.
          stopMessageId,
        };

        const scheduledReminderDocRef = docRef
          .collection(REMINDERS_COLLECTION)
          .doc(); // automatically generate unique id
        batch.set(scheduledReminderDocRef, scheduledReminder);
      }
    });

    await batch.commit();
    message.info(
      i18next.t('reminder.successfullyEntity', {
        entity: i18next.t('genericWords.created'),
      })
    );
  } catch (error: any) {
    message.error(error.message.toString());
  }
};

/**
 * Update scheduled reminders collection for a stop message collection by replacing the whole collection with new data - companies/depot/stopMessages/[reminders]
 * @param docRef
 * @param scheduledReminders
 */
export const replaceScheduledRemindersAsync = async (
  docRef: firebase.firestore.DocumentReference,
  stopMessageId: string,
  scheduledReminders: ScheduledReminder[]
) => {
  try {
    await deleteScheduledRemindersCollectionAsync(docRef);

    if (scheduledReminders.length === 0) {
      message.info(
        i18next.t('reminder.successfullyEntity', {
          entity: i18next.t('genericWords.created'),
        })
      );
      return;
    }

    await createScheduledRemindersAsync(
      docRef,
      stopMessageId,
      scheduledReminders
    );
  } catch (error: any) {
    message.error(error.message.toString());
  }
};

/**
 * Delte whole collection of scheduled reminders for a stop message collection - companies/depot/stopMessages/[reminders]
 * @param docRef
 * @param scheduledReminders
 */
export const deleteScheduledRemindersCollectionAsync = async (
  docRef: firebase.firestore.DocumentReference
) => {
  try {
    const querySnapshot = await docRef.collection(REMINDERS_COLLECTION).get();
    const existingReminderDocIds = querySnapshot.docs.map((doc) => doc.id);

    const db = firebase.firestore();
    const batch = db.batch();

    existingReminderDocIds.forEach((docId) => {
      const documentReferences = docRef
        .collection(REMINDERS_COLLECTION)
        .doc(docId);
      batch.delete(documentReferences);
    });

    await batch.commit();
  } catch (error: any) {
    message.error(error.message.toString());
  }
};

export const {
  setScheduledReminders,
  clearScheduledReminders,
  setLoading,
  setShowEndDateDisplayMessageIssue,
} = reminderSlice.actions;

export const reminderStateSelector = (state: RootState): ReminderState =>
  state.reminder;

export default reminderSlice.reducer;
