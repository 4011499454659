import {useState, useEffect} from 'react';
import {message} from 'antd';
import {useDispatch} from 'react-redux';
import {Redirect} from 'react-router-dom';
import {firebase} from 'services/firebase';
import {getEmailByActionCode, revertEmailAsync} from 'store/slices/authSlice';

export const RecoverEmail = (props: any) => {
  const dispatch = useDispatch();
  const [finished, setFinished] = useState(false);

  useEffect(() => {
    const verifier = async () => {
      try {
        const emailAddress = await getEmailByActionCode(props.actionCode);
        await firebase.auth().applyActionCode(props.actionCode);
        if (emailAddress) {
          revertEmailAsync(emailAddress);
        }
        setFinished(true);
        message.info(`Your email was reverted successfully back to ${emailAddress}` );
      } catch (error) {
        message.error(error.message.toString(), 5);
      }
    };
    verifier().then();
  }, [dispatch, props.actionCode]);

  if (finished) return <Redirect to="/" />;

  return (
    <div className="site-view-welcome" style={{paddingTop: '128px'}}>
    </div>
  );
};
