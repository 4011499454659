import {Select} from 'antd';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {
  ProductType,
  productTypesStateSelector,
} from 'store/slices/productTypesSlice';
import i18next from 'i18next';

const {Option} = Select;

interface ProductTypeSelectProps {
  value?: ProductType;
  onChange?: (value: ProductType) => void;
}

export const ProductTypeSelect = (
  props: ProductTypeSelectProps
): JSX.Element => {
  const {t} = useTranslation();
  const productTypesState = useSelector(productTypesStateSelector);

  const handleChange = (value: string) => {
    const productType = productTypesState.types.find(
      (type) => type.id === value
    );
    if (props.onChange && productType) props.onChange(productType);
  };

  return (
    <Select
      value={props.value?.id}
      loading={productTypesState.isLoading}
      placeholder={i18next.t('form.placeholder.selectEntity', {
        entity: i18next.t('product.aProductType'),
      })}
      notFoundContent={i18next.t('product.error.noProductTypesFound')}
      onChange={handleChange}>
      {productTypesState.types.map((type) => (
        <Option key={type.id} value={type.id}>
          {t(`productType.${type.name}`)}
        </Option>
      ))}
    </Select>
  );
};
