import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {loadWeeklyExportSubscriptionAsync} from 'store/slices/weeklyExportSlice';
import { authStateSelector } from "store/slices/authSlice";

export const useWeeklyExport = () => {
  const dispatch = useDispatch();
  const authState = useSelector(authStateSelector);

  useEffect(() => {
    const email = authState.user?.email;
    if (email !== undefined) {
      dispatch(loadWeeklyExportSubscriptionAsync(email));
    }
  }, [dispatch, authState.user?.email]);
};
