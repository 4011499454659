import {Table, Typography} from 'antd';
import {ViewHeader} from 'components/layout/ViewHeader';
import example from './api/example.json';
const {Text, Title, Paragraph} = Typography;

const columns = [
  {
    title: 'Code',
    dataIndex: 'code',
    key: 'code',
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
  },
];

export const ApiView = (): JSX.Element => {
  return (
    <div className="site-main-content" style={{padding: '16px'}}>
      <ViewHeader title={'API Documentation'} extra={[]} />
      <Paragraph>
        Since 2022.9, DSP contains a JSON API. It enables you to build
        applications outside the DSP-portal to view messages.
      </Paragraph>
      <Title level={5}>Security</Title>
      <Paragraph>
        The API uses keys for authorization. An API key is a token that a client
        provides when making API calls. The key must be sent as a request
        header.
      </Paragraph>
      <Title level={5}>Example request with curl</Title>
      <Text>
        <pre>
          curl --header "x-api-key: YOUR_KEY_HERE"
          https://.../app/api/messages
        </pre>
      </Text>
      <Title level={5}>Example response json</Title>
      <Text>
        <pre>{JSON.stringify(example, null, 1)}</pre>
      </Text>
      <Title level={5}>Response Objects</Title>
      <Text>
        <pre>{`
interface StopMessage {
  // The creator user of the stop message
  createdBy: CreatedBy
  // The calculated nr of minutes of the down time
  downTimeMinutes: number
  // Internal flag if the messages has been sent by email/sms
  queued: boolean
  // unique identifier of this stop
  id: string
  // created at timestamp
  createdAt: Timestamp
  // nr of days for the stop down time
  downTimeDays: number
  // Array of replacement depots
  replacementDepots: ReplacementDepot[]
  // Flag for if the stop will concern all products at the depot
  isConcerningAllProducts: boolean
  // internal soft delete flag
  deleted: boolean
  // A timestamp object indicates when the down time will start
  startDate: Timestamp
  // Internal timestamp object for the system to be able to send message later
  sendLaterDate: Timestamp
  // Urgency of the stop can be (ACUTE or PLANNED)
  urgency: string
  // Subject can be MAINTENANCE_WORK, TECHNICAL_ISSUE, PRODUCTS_SHORTAGE, BOAT_UNLOADING, ELECTRICAL_WORK or TRAINING
  subject: string
  // The actual message to stakeholders
  message: string
  // Internal flag can be skipped
  hasStakeholdersBeenNotified: boolean
  // Internal send status flag can be SEND_NOW or SEND_LATER
  status: string
  // is the stop active or not?
  completed: boolean
  // A timestamp object indicates when the down time will end
  endDate: Timestamp
  // flag for disturbance
  isDisturbanceInformation: boolean
  // internal language 
  languageCulture: string
  // An array of product GUIDs
  products: string[]
  // Total down time hours
  downTimeHours: number
  // flag for if the stop has been updated or not
  updated: boolean
  // the depot this stop covers
  depot: Depot
}

interface Info {
  // Internal array of users who are receivers of info message
  sendTo: string[]
  // The creator user of the info message
  createdBy: CreatedBy
  // End timestamp
  endDate: Timestamp
  // the depot this info covers
  depot: Depot
  // Internal lang code 
  languageCulture: string
  // Internal id (GUID)
  id: string
  // A timestamp object indicates when the info will start
  startDate:  Timestamp
  // the message
  message: string
  // Internal deleted flag 
  deleted: boolean
  // Timestamp when the info message was created
  createdAt: Timestamp
  // product array
  products: Product[]
  // internal queue flag
  queued: boolean
}


interface CreatedBy {
  // internal GUID for the user
  uid: string
  // Phone number E164 standard /^\\+[1-9]\\d{1,14}$/
  phoneNumber: string
  // The user name
  name: string
  // Email address for the creator user 
  email: string
}

interface Product {
  // product description
  description: string
  // product code
  code: string
  // internal product id (guid)
  id: string
  // the category / product type
  type: ProductType
  // name of the product
  name: string
}

interface ProductType {
  // internal id (guid)
  id: string
  // name of the product category type
  name: string
}

interface Timestamp {
   // represents seconds of UTC time since Unix epoch 1970-01-01T00:00:00Z
  _seconds: number
   // represents non-negative fractions of a second at nanosecond resolution. (can be ignored)
  _nanoseconds: number
}

interface ReplacementDepot {
  // country code (ISO 3166-1 alpha-2)
  country: string
  // company name 
  company: string
  // internal GUID for the depot
  id: string
  // Name of the depot
  name: string
}

interface Depot {
  // Depot name 
  name: string
  // Internal String array of product GUIDs
  products: string[]
  // internal deleted flag
  deleted: boolean
  // Company object for this depot
  company: Company
  // Geographic location for depot
  geoLocation: GeoLocation
  // internal GUID for the depot
  id: string
  // country code (ISO 3166-1 alpha-2)
  country: string
}

interface Company {
  // Internal company GUID
  id: string
  // Name of the company
  name: string
}

interface GeoLocation {
  // Full address for geo tagging
  full_address: string
  // Timezone object 
  timezone: Timezone
  // country code (ISO 3166-1 alpha-2)
  country: string
  // Geo coordinates lat/lng
  coordinates: Coordinates
}

interface Timezone {
  id: string // Time Zone ID
  name: string // Time zone name 
}

interface Coordinates {
  lat: number // Latitude
  lng: number // Longitude
}

        `}</pre>
      </Text>
      <Title level={5}>Errors</Title>
      <Text>
        Errors can and will occur sooner or later. The HTTP status codes to
        signal error conditions. Used HTTP status codes are:
      </Text>
      <Table
        columns={columns}
        dataSource={[
          {code: 200, description: 'Operation was performed successfully '},
          {code: 403, description: 'Access Forbidden (auth failed)'},
          {code: 500, description: 'Internal server error'},
        ]}
      />
    </div>
  );
};
