import {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {loadDepotsAsync} from 'store/slices/depotsSlice';

export const useDepots = () => {
  const dispatch = useDispatch();

  useEffect(() => {
      dispatch(loadDepotsAsync());
  }, [dispatch]);
};
