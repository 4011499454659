import {useSelector} from 'react-redux';
import {ViewHeader} from 'components/layout/ViewHeader';
import {stopMessageSelector} from 'store/slices/stopMessagesSlice';
import {authStateSelector} from "store/slices/authSlice";
import {getOverviewUserActiveStops} from "store/slices/usersSlice";
import {capitalizeFirstLetter} from "utils/helpers";
import {Collapse, Tabs} from 'antd';
import {
  CalendarOutlined,
  EnvironmentOutlined,
  TableOutlined,
} from '@ant-design/icons';
import {MessageListTable} from "components/tables/MessageListTable";
import BigCalendar from './depot/partials/BigCalendar';
import {useTranslation} from 'react-i18next';
import {useActiveStopMessages} from "services/hooks/useActiveStopMessages";
import {DepotMap} from "components/DepotMap";
import {depotStateSelector} from "store/slices/depotsSlice";
import {useHistory} from 'react-router-dom';
import {UserDepotSelect} from "components/form/UserDepotSelect";

const {Panel} = Collapse;
const {TabPane} = Tabs;

export const PersonalizedOverview = (): JSX.Element => {
  useActiveStopMessages();
  const {t} = useTranslation();
  const history = useHistory();

  const authState = useSelector(authStateSelector);
  const stopMessageState = useSelector(stopMessageSelector);
  const depotState = useSelector(depotStateSelector);
  const userDepotsStops = useSelector(getOverviewUserActiveStops());

  return (
    <div className="site-main-content" style={{padding: '16px'}}>
      <ViewHeader title={t('depot.overview.name')} extra={[
        <UserDepotSelect
          key="change-depot"
          onChange={(depotId) => history.push(`/depot/${depotId}`)}
        />
        ]} />
      <Collapse
        collapsible="header"
        style={{marginBottom: '16px'}}
        defaultActiveKey={['active-depot-stop-table']}>
        <Panel
          header={`${capitalizeFirstLetter(t('depot.depotStops'))} (${
            userDepotsStops.flatMap((u) => u.activeStopMessages).length
          })`}
          key="active-depot-stop-table"
          className="panel-body-no-padding">
          <Tabs defaultActiveKey="list-view">
            <TabPane
              tab={
                <span>
                  <TableOutlined />
                  {t('genericWords.list')}
                </span>
              }
              key="list-view">
              <MessageListTable
                loading={
                  stopMessageState.isLoading || authState.isLoadingProfile
                }
                stopMessages={userDepotsStops.flatMap(
                  (u) => u.activeStopMessages
                )}
              />
            </TabPane>
            <TabPane
              tab={
                <span>
                  <CalendarOutlined />
                  {t('time.calendar')}
                </span>
              }
              key="calendar-view">
              <BigCalendar
                calendarEvent={userDepotsStops.flatMap(
                  (u) => u.activeStopMessages
                )}
              />
            </TabPane>
            <TabPane
              tab={
                <span>
                  <EnvironmentOutlined />
                  {capitalizeFirstLetter(t('map.name'))}
                </span>
              }
              key="map-view">
              <DepotMap
                isLoading={
                  stopMessageState.isLoading ||
                  authState.isLoadingProfile ||
                  depotState.isLoading
                }
                statusDepots={userDepotsStops}
              />
            </TabPane>
          </Tabs>
        </Panel>
      </Collapse>
    </div>
  );
};
