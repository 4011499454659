import React, {useState, useEffect} from 'react';
import {
  Form,
  Input,
  Switch,
  Select,
  Spin,
  Tabs,
  Row,
  Col,
  Tooltip,
  Space,
  Button,
} from 'antd';
import {FormInstance} from 'antd/es/form';
import {User} from 'store/slices/usersSlice';
import {
  ADMINISTRATOR,
  ALL_ROLES,
  COMPANY_ROLES,
  HAULAGE_ROLES,
  SUPER_ADMIN,
} from 'constants/roles';
import {PHONE_E164} from 'utils/patterns';
import {DepotMultiSelect} from 'components/form/DepotMultiSelect';
import {ProductTypeMultiSelect} from 'components/form/ProductTypeMultiSelect';
import {authStateSelector} from 'store/slices/authSlice';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {capitalizeFirstLetter} from 'utils/helpers';
import {
  QuestionCircleOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';

const {TabPane} = Tabs;

export interface UserFormProps {
  form: FormInstance<User>;
  onSave: (values: User) => void;
  user?: User;
  mode: 'create' | 'edit';
  isSaving: boolean;
  haulageId?: string;
  suggestDepots?: string[];
}
const {Option} = Select;

export const UserForm = (props: UserFormProps): JSX.Element => {
  const authState = useSelector(authStateSelector);
  const {t} = useTranslation();
  const [selectedRole, setSelectedRole] = useState('');
  const [disabledRoles, setDisabledRoles] = useState<string[]>([]);
  const [enableDepotsTab, setEnableDepotsTab] = useState<boolean>(true);
  const [showPhoneNumberFields, setShowPhoneNumberFields] = useState<boolean>(
    props.user?.notificationsBySms ? props.user?.notificationsBySms : false
  );

  useEffect(() => {
    const userRole = props.user?.role ?? '';
    const currentUserRole = authState.user?.role ?? '';

    if (props.mode === 'edit') {
      setEnableDepotsTab(
        currentUserRole === ADMINISTRATOR || currentUserRole === SUPER_ADMIN
      );
      if (HAULAGE_ROLES.includes(userRole)) {
        setDisabledRoles(COMPANY_ROLES);
      }
      if (COMPANY_ROLES.includes(userRole)) {
        const disabled =
        currentUserRole === SUPER_ADMIN ? [] : currentUserRole  === ADMINISTRATOR
            ? [...HAULAGE_ROLES, SUPER_ADMIN]
            : HAULAGE_ROLES;
        setDisabledRoles(disabled);
      }
    }
    if (props.mode === 'create') {
      if (props.haulageId) {
        setDisabledRoles(COMPANY_ROLES);
      } else {
        // should we disable super_admin if admin is editor
        const disabled =
          currentUserRole === SUPER_ADMIN ? [] : currentUserRole === ADMINISTRATOR
            ? [...HAULAGE_ROLES, SUPER_ADMIN]
            : HAULAGE_ROLES;
        setDisabledRoles(disabled);
      }
    }
  }, [props.user, props.mode, props.haulageId, authState.user?.role]);

  return (
    <Spin spinning={props.isSaving || authState.isLoadingProfile}>
      <Form
        initialValues={
          props.mode === 'edit' && props.user
            ? props.user
            : {
                depots: props.suggestDepots,
                productTypes: [],
                notificationsByEmail: false,
                notificationsBySms: false,
              }
        }
        name="user-form"
        autoComplete="off"
        layout="vertical"
        form={props.form}
        onFinish={props.onSave}>
        <Tabs defaultActiveKey="basic">
          <TabPane
            tab={t('genericWords.basicInformation')}
            key="basic"
            forceRender={true}>
            <Row gutter={24}>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  label={capitalizeFirstLetter(t('user.role'))}
                  name="role"
                  rules={[
                    {
                      required: true,
                      message: t('form.required.pleaseEnterEntity', {
                        entity: t('user.aRole'),
                      }),
                    },
                  ]}>
                  <Select
                    value={selectedRole}
                    onChange={(value: string) => setSelectedRole(value)}
                    placeholder={t('form.placeholder.enterEntity', {
                      entity: t('user.aRole'),
                    })}
                    disabled={authState.user?.id === props.user?.id}>
                    {ALL_ROLES.map((role) => (
                      <Option
                        key={role}
                        value={role}
                        disabled={disabledRoles.includes(role)}>
                        {t(`roles.${role}`)}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Row gutter={12}>
                  <Col span={12}>
                    <Form.Item
                      label={capitalizeFirstLetter(t('user.firstName'))}
                      name="firstName"
                      rules={[
                        {
                          required: true,
                          message: t('form.required.pleaseEnterEntity', {
                            entity: t('user.aFirstName'),
                          }),
                        },
                      ]}>
                      <Input
                        placeholder={t('form.placeholder.enterEntity', {
                          entity: t('user.aFirstName'),
                        })}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label={capitalizeFirstLetter(t('user.lastName'))}
                      name="lastName"
                      rules={[
                        {
                          required: true,
                          message: t('form.required.pleaseEnterEntity', {
                            entity: t('user.aLastName'),
                          }),
                        },
                      ]}>
                      <Input
                        placeholder={t('form.placeholder.enterEntity', {
                          entity: t('user.aLastName'),
                        })}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item
                  label={capitalizeFirstLetter(t('email.name'))}
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: t('form.required.pleaseEnterEntity', {
                        entity: t('email.anEmailAddress'),
                      }),
                    },
                    {
                      type: 'email',
                      message: t('email.error.valid'),
                    },
                  ]}>
                  <Input
                    type="email"
                    placeholder={t('form.placeholder.enterEntity', {
                      entity: t('email.anEmailAddress'),
                    })}
                    readOnly={authState.user?.id === props.user?.id}
                    disabled={authState.user?.id === props.user?.id}
                  />
                </Form.Item>
                {showPhoneNumberFields && (
                  <>
                    <Form.Item
                      label={capitalizeFirstLetter(
                        t('communication.phoneNumber')
                      )}
                      name="phoneNumber"
                      rules={[
                        {
                          required: true,
                          message: t('form.required.pleaseEnterEntity', {
                            entity: t('communication.aPhoneNumber'),
                          }),
                        },
                        () => ({
                          validator(_, value) {
                            if (PHONE_E164.test(value)) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error(
                                t('communication.required.validPhoneFormat')
                              )
                            );
                          },
                        }),
                      ]}>
                      <Input
                        type="text"
                        placeholder={`${t('form.placeholder.enterEntity', {
                          entity: t('communication.aPhoneNumber'),
                        })}`}
                      />
                    </Form.Item>
                    <Form.Item
                      label={t('communication.addPhoneNumber')}
                      name="phoneNumbers">
                      <Form.List name="phoneNumbers">
                        {(fields, {add, remove}) => (
                          <>
                            {fields.map(
                              ({key, name, fieldKey, ...restField}) => (
                                <Space
                                  size={'large'}
                                  key={key}
                                  style={{display: 'flex', marginBottom: 8}}
                                  align="baseline">
                                  <Form.Item
                                    {...restField}
                                    name={[name, 'phone']}
                                    fieldKey={[fieldKey, 'phone']}
                                    rules={[
                                      {
                                        required: true,
                                        message: t(
                                          'form.required.pleaseEnterEntity',
                                          {
                                            entity: t(
                                              'communication.aPhoneNumber'
                                            ),
                                          }
                                        ),
                                      },
                                      () => ({
                                        validator(_, value) {
                                          if (PHONE_E164.test(value)) {
                                            return Promise.resolve();
                                          }
                                          return Promise.reject(
                                            new Error(
                                              t(
                                                'communication.required.validPhoneFormat'
                                              )
                                            )
                                          );
                                        },
                                      }),
                                    ]}>
                                    <Input
                                      placeholder={t(
                                        'form.placeholder.enterEntity',
                                        {
                                          entity: t(
                                            'communication.aPhoneNumber'
                                          ),
                                        }
                                      )}
                                    />
                                  </Form.Item>
                                  <MinusCircleOutlined
                                    onClick={() => remove(name)}
                                  />
                                </Space>
                              )
                            )}
                            <Form.Item>
                              <Button
                                type="dashed"
                                onClick={() => add()}
                                block
                                icon={<PlusOutlined />}>
                                {t('buttons.add')}
                              </Button>
                            </Form.Item>
                          </>
                        )}
                      </Form.List>
                    </Form.Item>
                  </>
                )}
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  label={t('communication.notifyByEntity', {
                    entity: t('email.name'),
                  })}
                  name="notificationsByEmail"
                  valuePropName="checked">
                  <Switch />
                </Form.Item>
                <Form.Item
                  style={{ paddingBottom: '32px' }}
                  label={t('communication.notifyByEntity', {
                    entity: t('sms.name'),
                  })}
                  help={t('communication.agreement')}
                  name="notificationsBySms"
                  valuePropName="checked">
                  <Switch
                    onChange={(checked) => setShowPhoneNumberFields(checked)}
                  />
                </Form.Item>
                <Form.Item
                  label={
                    <div>
                      {t('product.productType')}
                      <Tooltip title={t('user.selectProduct')}>
                        <QuestionCircleOutlined style={{paddingLeft: 3}} />
                      </Tooltip>
                    </div>
                  }
                  name="productTypes">
                  <ProductTypeMultiSelect />
                </Form.Item>
              </Col>
            </Row>
          </TabPane>
          <TabPane
            tab={t('menu.depots')}
            key="depots"
            forceRender={true}
            disabled={!enableDepotsTab}>
            <Form.Item label={t('user.selectDepot')} name="depots">
              <DepotMultiSelect />
            </Form.Item>
          </TabPane>
        </Tabs>
      </Form>
    </Spin>
  );
};
