import {firebase} from 'services/firebase';
import moment from 'moment';
import * as _ from 'lodash';
import i18n from 'utils/i18n';

export const getChartData = async (
  selectedCompanyId: string,
  selectedStartDate: Date,
  selectedEndDate: Date,
  tab: string
) => {
  const querySnapshot = await firebase
    .firestore()
    .collectionGroup('stopMessages')
    .where('depot.company.id', '==', selectedCompanyId)
    .get();

  const stopMessages = querySnapshot.docs
    .filter((snapshot) => {
      const startDate = new Date(snapshot.data().startDate.seconds * 1000);
      const endDate = new Date(snapshot.data().endDate.seconds * 1000);
      return (
        startDate > selectedStartDate &&
        endDate < selectedEndDate &&
        snapshot.data().status !== 'DRAFT'
      );
    })
    .map((doc) => {
      const stopMsg = doc.data();
      return {
        reason: stopMsg.subject,
        depot: stopMsg.depot.name,
        downTimeDays: stopMsg.downTimeDays,
        companyName: stopMsg.depot.company.name,
        creat: new Date(stopMsg.createdAt.seconds * 1000).toLocaleDateString(),
        start: new Date(stopMsg.startDate.seconds * 1000).toLocaleDateString(),
      };
    });

  switch (tab) {
    case 'dashboard':
      const preData = _.chain(stopMessages)
        .groupBy('reason')
        .map((messages, reason) => ({
          stops: _.chain(messages)
            .groupBy('depot')
            .map((stops, depotName) => ({
              company: depotName,
              value: stops.length,
              depot: i18n.t(`stopMessage.subject.${reason}`),
            }))
            .value(),
        }))
        .value();
      return preData.reduce((initialized: any, next) => {
        return initialized.concat(next?.stops);
      }, []);

    case 'meansAdvancedPlanning':
      const preData2 = stopMessages.map((res) => {
        return {
          company: res.companyName,
          depot: res.depot,
          value: moment(res.start).diff(res.creat, 'days'),
        };
      });
      return _.chain(preData2)
        .groupBy('depot')
        .map((value, key) => ({
          depot: value[0].company,
          company: key,
          value: +(
            value.reduce((initialized: number, next) => {
              return initialized + next.value;
            }, 0) / value.length
          ).toFixed(2),
        }))
        .value();

    case 'stopDays':
      const preData3 = stopMessages.map((res) => {
        return {
          company: res.companyName,
          value: res.downTimeDays,
          depot: res.depot,
        };
      });
      return _.chain(preData3)
        .groupBy('depot')
        .map((value, key) => ({
          depot: value[0].company,
          company: key,
          value: value.reduce((initialized: number, next) => {
            return initialized + next.value;
          }, 0),
        }))
        .value();

    case 'numberOfDowntimes':
      const preData4 = stopMessages.map((res) => {
        return {
          company: res.companyName,
          value: res.downTimeDays,
          depot: res.depot,
        };
      });
      return _.chain(preData4)
        .groupBy('depot')
        .map((value, key) => ({
          depot: value[0].company,
          company: key,
          value: value.length,
        }))
        .value();
  }
};
