import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AppThunk, RootState} from 'store/index';
import {firebase} from 'services/firebase';
import i18next from 'i18next';

export const PAGES_COLLECTION = 'pages';
export const PAGE_SUPPORT_DOC = 'support';

export interface SupportContact {
  company: string;
  name: string;
  email: string;
  phone: string;
}

interface SupportState {
  support: SupportContact[];
  hasError: boolean;
  error: string;
  isLoading: boolean;
}

const initialState = {
  support: [],
  hasError: false,
  error: '',
  isLoading: false,
} as SupportState;

export const supportSlice = createSlice({
  name: 'support',
  initialState: initialState,
  reducers: {
    updating: (state) => {
      state.support = [];
      state.isLoading = true;
    },
    hasError: (state, action: PayloadAction<string>) => {
      state.hasError = true;
      state.error = i18next.t('error.anErrorOccurredEntity', {
        entity: action.payload,
      });
      state.isLoading = false;
    },
    update: (state, action: PayloadAction<SupportContact[]>) => {
      state.support = action.payload;
      state.isLoading = false;
    },
  },
});

export const loadSupportAsync = (): AppThunk => async (dispatch) => {
  try {
    dispatch(updating());
    const snapshot = await firebase
      .firestore()
      .collection(PAGES_COLLECTION)
      .doc(PAGE_SUPPORT_DOC)
      .get();

    const support = snapshot.data()?.contacts as SupportContact[];
    dispatch(update(support));
  } catch (error) {
    dispatch(hasError(error.message.toString()));
  }
};

export const {updating, hasError, update} = supportSlice.actions;
export const supportStateSelector = (state: RootState): SupportState =>
  state.support;

export default supportSlice.reducer;
