import {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {loadCompaniesAsync} from 'store/slices/companiesSlice';


export const useCompanies = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadCompaniesAsync())
  }, [dispatch]);
};
