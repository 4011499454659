import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AppThunk, RootState} from 'store/index';
import {firebase} from 'services/firebase';
import {v4 as uuidv4} from 'uuid';
import {message} from 'antd';
import {HaulageFormValues} from 'views/haulage/partials/HaulageForm';
import {createSelector} from 'reselect';
import {Depot, depotStateSelector, StatusDepot} from 'store/slices/depotsSlice';
import {stopMessageSelector} from 'store/slices/stopMessagesSlice';
import {loadUsersAsync} from 'store/slices/usersSlice';
import i18next from 'i18next';
import {infoMessageSelector} from 'store/slices/infoMessagesSlice';

export const HAULAGE_COLLECTION = 'hauliers';

export interface HaulageAddress {
  street: string;
  city: string;
  zip: string;
}

export interface HaulageContact {
  name: string;
  email: string;
  phone: string;
}

export interface Haulage {
  id: string;
  name: string;
  orgNumber: string;
  information: string;
  country: string;
  createdAt: number;
  deleted: boolean;
  addresses: HaulageAddress[];
  contacts: HaulageContact[];
  depots: string[]; // depot Ids
  drivers?: string[];
  admins?: string[];
}

export interface HaulageLight {
  id: string;
  name: string;
}

export interface HaulageWithDepots extends Haulage {
  depotsFull: Depot[];
}

interface HaulageState {
  haulage: Haulage[];
  hasError: boolean;
  error: string;
  isLoading: boolean;
}

const initialState = {
  haulage: [],
  hasError: false,
  error: '',
  isLoading: false,
} as HaulageState;

export const haulageSlice = createSlice({
  name: 'haulage',
  initialState: initialState,
  reducers: {
    updating: (state) => {
      state.haulage = [];
      state.isLoading = true;
    },
    hasError: (state, action: PayloadAction<string>) => {
      state.hasError = true;
      state.error = i18next.t('error.anErrorOccurredEntity', {
        entity: action.payload,
      });
      state.isLoading = false;
    },
    update: (state, action: PayloadAction<Haulage[]>) => {
      state.haulage = action.payload;
      state.isLoading = false;
    },
  },
});

// helper method to convert a query doc to haulage model
const dataToHaulage = (doc: firebase.firestore.DocumentData): Haulage => {
  return {
    id: doc.id,
    name: doc.name,
    country: doc.country,
    orgNumber: doc.orgNumber,
    information: doc.information,
    addresses: doc.addresses,
    contacts: doc.contacts,
    depots: doc.depots,
    drivers: doc.drivers,
    admins: doc.admins,
    createdAt: doc.createdAt && doc.createdAt.toMillis(),
    deleted: doc.deleted,
  } as Haulage;
};

export const loadHauliersAsync = (): AppThunk => async (dispatch) => {
  try {
    dispatch(updating());
    const querySnapshot = await firebase
      .firestore()
      .collection(HAULAGE_COLLECTION)
      .get();

    const hauliers = querySnapshot.docs.map((doc) => {
      return dataToHaulage(doc.data());
    });
    dispatch(update(hauliers));
  } catch (error) {
    console.log(error);
    dispatch(hasError(error.message.toString()));
  }
};

export const loadHaulageByIdAsync = (haulageId: string): AppThunk => async (
  dispatch
) => {
  try {
    dispatch(updating());
    const documentSnapshot = await firebase
      .firestore()
      .collection(HAULAGE_COLLECTION)
      .doc(haulageId)
      .get();

    if (documentSnapshot.exists) {
      const data = documentSnapshot.data() as firebase.firestore.DocumentData;
      const haulage = dataToHaulage(data);
      // TODO Important - dispatch loadHaulageUsers instead??
      dispatch(loadUsersAsync());
      dispatch(update([haulage]));
    } else {
      dispatch(hasError(i18next.t('haulage.error.404')));
    }
  } catch (error) {
    dispatch(hasError(error.message.toString()));
  }
};

export const createHaulageAsync = (
  haulage: HaulageFormValues
): AppThunk => async (dispatch) => {
  try {
    const uuid = uuidv4();
    const docRef = firebase
      .firestore()
      .collection(HAULAGE_COLLECTION)
      .doc(uuid);
    await docRef.set({
      ...haulage,
      information: haulage.information ?? '',
      id: uuid,
      deleted: false,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
    dispatch(loadHauliersAsync());
    message.info(
      i18next.t('haulage.successfullyEntity', {
        entity: i18next.t('genericWords.created'),
      })
    );
    return docRef.id;
  } catch (error) {
    message.error(error.message.toString());
  }
};

export const updateHaulageAsync = (
  haulageFormValues: HaulageFormValues,
  haulage: Haulage
): AppThunk => async (dispatch) => {
  try {
    const docRef = firebase
      .firestore()
      .collection(HAULAGE_COLLECTION)
      .doc(haulage.id);
    await docRef.update(haulageFormValues);
    dispatch(loadHauliersAsync());
    message.info(
      i18next.t('haulage.successfullyEntity', {
        entity: i18next.t('genericWords.updated'),
      })
    );
    return docRef.id;
  } catch (error) {
    message.error(error.message.toString());
  }
};

export const removeDriverFromHaulage = (
  haulageId: string,
  userId: string
): AppThunk => async (dispatch) => {
  try {
    await firebase.functions().httpsCallable('updateHaulageUsers')({
      haulageId: haulageId,
      uid: userId,
      userRole: 'driver',
      action: 'remove',
    });
    message.info('User was successfully disconnected from Haulier');
    dispatch(loadHaulageByIdAsync(haulageId));
  } catch (error) {
    message.error(error.message.toString());
  }
};

export const addDriverToHaulage = (
  haulageId: string,
  userId: string
): AppThunk => async (dispatch) => {
  try {
    await firebase.functions().httpsCallable('updateHaulageUsers')({
      haulageId: haulageId,
      uid: userId,
      userRole: 'driver',
      action: 'add',
    });
    message.info('User was successfully connected to Haulier');
    dispatch(loadHaulageByIdAsync(haulageId));
  } catch (error) {
    message.error(error.message.toString());
  }
};

export const softDeleteHaulageAsync = (id: string): AppThunk => async (
  dispatch
) => {
  try {
    const docRef = firebase.firestore().collection(HAULAGE_COLLECTION).doc(id);
    await docRef.update({deleted: true});
    dispatch(loadHauliersAsync());
    message.info(
      i18next.t('haulage.successfullyEntity', {
        entity: i18next.t('genericWords.deleted'),
      })
    );
  } catch (error) {
    message.error(error.message.toString());
  }
};

export const {updating, hasError, update} = haulageSlice.actions;
export const haulageStateSelector = (state: RootState): HaulageState =>
  state.haulage;

export const getActiveHauliersSelector = (state: RootState) => {
  return state.haulage.haulage.filter((haulage) => haulage.deleted === false);
};

export const getHaulageByIdSelector = (haulageId: string) =>
  createSelector(getActiveHauliersSelector, (haulage) => {
    return haulage.find((hauler) => hauler.id === haulageId);
  });

export const getHaulageListWithFullDepotInfoSelector = createSelector(
  [getActiveHauliersSelector, depotStateSelector],
  (hauliers, depotState) => {
    return hauliers.map((haulage) => {
      const depotsFull =
        haulage.depots !== undefined
          ? depotState.depots.filter((depot) =>
              haulage.depots.includes(depot.id)
            )
          : [];
      return {...haulage, depotsFull} as HaulageWithDepots;
    });
  }
);

/**
 * Haulage Overview - should filter out Drafts
 * @param haulageId
 */
export const getHaulageActiveStops = (haulageId: string) =>
  createSelector(
    [getHaulageByIdSelector(haulageId), stopMessageSelector],
    (haulage, stopMessageState) => {
      if (haulage === undefined) return [];
      return stopMessageState.activeStopMessages.filter(
        (stop) =>
          haulage.depots.includes(stop.depot.id) &&
          !stop.deleted &&
          stop.status !== 'DRAFT' &&
          stop.status !== 'INACTIVE'
      );
    }
  );

export const getHaulageActiveInfoMessages = (haulageId: string) =>
  createSelector(
    [getHaulageByIdSelector(haulageId), infoMessageSelector],
    (haulage, infoMessageState) => {
      if (haulage === undefined) return [];
      return infoMessageState.activeInfoMessages.filter(
        (stop) => haulage.depots.includes(stop.depot.id) && !stop.deleted
      );
    }
  );

export const getStatusDepotsByHaulage = (haulageId: string) =>
  createSelector(
    [
      getHaulageByIdSelector(haulageId),
      depotStateSelector,
      stopMessageSelector,
    ],
    (haulage, depotState, stopMessageState) => {
      if (haulage === undefined) return [];
      const depotsList = depotState.depots.filter((depot) =>
        haulage.depots.includes(depot.id)
      );
      return depotsList.map((depot) => {
        const activeStopMessages = stopMessageState.activeStopMessages.filter(
          (stop) =>
            stop.depot.id === depot.id &&
            !stop.deleted &&
            stop.status !== 'DRAFT' &&
            stop.status !== 'INACTIVE'
        );
        const upcoming = activeStopMessages.filter(
          (stop) => stop.startDate > new Date().getTime()
        );

        const depotColor =
          activeStopMessages.length > 0
            ? upcoming.length === activeStopMessages.length
              ? '#ffa500'
              : '#ff0000'
            : '#00ff00';
        return {
          ...depot,
          indicatorColor: depotColor,
          activeStopMessages,
        } as StatusDepot;
      });
    }
  );

export default haulageSlice.reducer;

export const updateHaulageUsers = async (
  uid: string,
  haulageId: string,
  userRole: string,
  action: 'add' | 'remove'
) => {
  return await firebase.functions().httpsCallable('updateHaulageUsers')({
    haulageId: haulageId,
    uid: uid,
    userRole: userRole,
    action: action,
  });
};
