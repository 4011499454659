import {PlusOutlined} from '@ant-design/icons';
import {Button, Form, message, Modal} from 'antd';
import {CompanyForm} from 'components/form/CompanyForm';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Company, createCompanyAsync} from 'store/slices/companiesSlice';
import {useTranslation} from 'react-i18next';
import {hasSuperAdminRights} from 'constants/roles';
import {authStateSelector} from 'store/slices/authSlice';

export const CompanyCreateModal = () => {
  const dispatch = useDispatch();
  const authState = useSelector(authStateSelector);
  const [visible, setVisible] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [form] = Form.useForm<Company>();
  const {t} = useTranslation();

  useEffect(() => {
    setSubmitted(false);
  }, [visible]);

  if (!hasSuperAdminRights(authState)) {
    return null;
  }

  const addCompany = (formValues: Company) => {
    dispatch(createCompanyAsync(formValues));
    setSubmitted(true);
    setTimeout(() => {
      setVisible(false);
      form.resetFields();
    }, 1000);
  };

  const submit = async () => {
    try {
      await form.validateFields();
      form.submit();
    } catch (errorInfo) {
      message.error(t('company.error.submit'));
      setSubmitted(false);
    }
  };

  return (
    <>
      <Button
        onClick={() => setVisible(true)}
        key="create-company-button"
        type="primary"
        icon={<PlusOutlined />}>
        {t('company.addNew')}
      </Button>
      <Modal
        title={t('company.addNew')}
        width={800}
        bodyStyle={{height: '600px', overflow: 'auto'}}
        maskClosable={false}
        closable={false}
        key={'create-company-modal'}
        centered
        onCancel={() => {
          setVisible(false);
          form.resetFields();
        }}
        visible={visible}
        footer={[
          <Button
            key="back-cancel"
            disabled={submitted}
            onClick={() => {
              setVisible(false);
              form.resetFields();
            }}>
            {t('buttons.cancel')}
          </Button>,
          <Button
            key="submit-btn"
            type="primary"
            loading={submitted}
            style={{marginRight: 8}}
            onClick={async () => {
              await submit();
            }}>
            {t('buttons.save')}
          </Button>,
        ]}>
        <CompanyForm
          mode="create"
          isSaving={submitted}
          onSave={(formValues) => addCompany(formValues)}
          form={form}
        />
      </Modal>
    </>
  );
};
