import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {firebase} from 'services/firebase';
import {AppThunk, RootState} from 'store/index';
import {createSelector} from 'reselect';
import {HaulageLight, haulageStateSelector} from 'store/slices/haulageSlice';
import i18next from 'i18next';
import {CompanyLight} from 'store/slices/companiesSlice';
import {sessionStateSelector} from 'store/slices/sessionSlice';
import {
  STOP_MESSAGES_COLLECTION,
  stopMessageSelector,
} from './stopMessagesSlice';
import {authStateSelector} from './authSlice';
import {depotStateSelector, StatusDepot} from './depotsSlice';
import {DEPOT_OPERATOR, DRIVER} from 'constants/roles';

export const USERS_COLLECTION = 'users';

export interface UserLight {
  uid?: string;
  name: string;
  email: string;
  phoneNumber: string | null;
}

export interface User {
  id?: string;
  firstName: string;
  lastName: string;
  email: string;
  disabled: boolean;
  emailVerified: boolean;
  phoneNumber: string;
  notificationsByEmail: boolean;
  notificationsBySms: boolean;
  role: string;
  loginCount: number;
  lastLoginAt: number | null;
  depots: string[]; // user interested in
  productTypes: string[];
  company?: CompanyLight;
  hauliers?: HaulageLight[];
  phoneNumbers?: string[];
}

interface UsersState {
  users: User[];
  hasError: boolean;
  error: string;
  isLoading: boolean;
}

const initialState = {
  users: [],
  hasError: false,
  error: '',
  isLoading: true,
} as UsersState;

export const usersSlice = createSlice({
  name: 'users',
  initialState: initialState,
  reducers: {
    updating: (state) => {
      state.isLoading = true;
    },
    hasError: (state, action: PayloadAction<string>) => {
      state.hasError = true;
      state.error = i18next.t('error.anErrorOccurredEntity', {
        entity: action.payload,
      });
      state.isLoading = false;
    },
    update: (state, action: PayloadAction<User[]>) => {
      state.users = action.payload;
      state.isLoading = false;
    },
  },
});

/**
 * Load all depots users
 * @param depotId
 */
export const loadDepotUsersAsync = (depotId: string): AppThunk => async (
  dispatch
) => {
  try {
    dispatch(updating());
    const querySnapshot = await firebase
      .firestore()
      .collection(USERS_COLLECTION)
      .where('depots', 'array-contains', depotId)
      .get();
    const users = convertSnapshotToArray(querySnapshot);
    dispatch(update(users));
  } catch (error) {
    dispatch(hasError(error.message.toString()));
  }
};

/**
 * Convert user QuerySnapshot to array of Users
 * @param querySnapshot
 */
const convertSnapshotToArray = (
  querySnapshot: firebase.firestore.QuerySnapshot
) => {
  return querySnapshot.docs.map((doc) => {
    return {
      id: doc.id,
      firstName: doc.data().firstName,
      lastName: doc.data().lastName,
      email: doc.data().email,
      disabled: doc.data().disabled,
      emailVerified: doc.data().emailVerified,
      phoneNumber: doc.data().phoneNumber,
      notificationsByEmail: doc.data().notificationsByEmail,
      notificationsBySms: doc.data().notificationsBySms,
      depots: doc.data().depots,
      productTypes: doc.data().productTypes,
      lastLoginAt: doc.data().lastLoginAt?.toMillis() ?? null,
      loginCount: doc.data().loginCount ?? 0,
      hauliers: doc.data().hauliers,
      role: doc.data().role,
      company: doc.data().company,
      phoneNumbers: doc.data().phoneNumbers ?? [],
    } as User;
  });
};

/**
 * Load Users Async
 */
export const loadUsersAsync = (): AppThunk => async (dispatch) => {
  try {
    dispatch(updating());
    const querySnapshot = await firebase
      .firestore()
      .collection(USERS_COLLECTION)
      .get();
    const users = convertSnapshotToArray(querySnapshot);
    dispatch(update(users));
  } catch (error) {
    dispatch(hasError(error.message.toString()));
  }
};

/**
 * Find Subscriber Users by depot and productTypeIds
 * @param depotId
 * @param concerningAllProducts
 * @param productTypesIds
 */
export const getUsersSubscribedTo = (
  depotId: string,
  concerningAllProducts: boolean,
  productTypesIds: string[]
): AppThunk => async () => {
  try {
    // test get depot stops to send
    const querySnap = await firebase
      .firestore()
      .collectionGroup(STOP_MESSAGES_COLLECTION)
      .where('sendLaterDate', '<=', new Date())
      .where('status', 'in', ['SEND_LATER', 'SEND_NOW'])
      .where('queued', '==', true)
      .get();
    // we need to tag it after it was sent = queued -> false
    console.log('Found: ' + querySnap.docs.length);

    const usersCollectionRef = firebase
      .firestore()
      .collection(USERS_COLLECTION);
    const querySnapshot = await usersCollectionRef
      .where('depots', 'array-contains', depotId)
      .get();
    const users = querySnapshot.docs.map((doc) => doc.data() as User);
    const filtered = concerningAllProducts
      ? users
      : users.filter((user) =>
          productTypesIds.some((id) => user.productTypes.includes(id))
        );
    console.log(depotId);
    console.log(productTypesIds);
    console.log(users.length);
    console.log(filtered);
  } catch (error) {
    console.log(error);
  }
};

export const {updating, hasError, update} = usersSlice.actions;
export const usersStateSelector = (state: RootState): UsersState => state.users;

export default usersSlice.reducer;

/**
 * Selector to get driver and admins by HaulageID
 * usersState ... haulageState - hauliers[0] .. drivers[]
 */
export const getUsersByHaulageSelector = createSelector(
  [usersStateSelector, haulageStateSelector],
  (usersState, haulageState) => {
    const adminUserIds = haulageState.haulage.flatMap(
      (haulage) => haulage.admins
    );
    const driverUserIds = haulageState.haulage.flatMap(
      (haulage) => haulage.drivers
    );
    return usersState.users.filter(
      (user) =>
        adminUserIds.includes(user.id) || driverUserIds.includes(user.id)
    );
  }
);

/**
 * Fetch users in anyway connected to a given depot
 * @param depotId
 */
export const getUsersByDepotIdSelector = (depotId: string) =>
  createSelector(usersStateSelector, (state) => {
    return state.users.filter((user) => user.depots.includes(depotId));
  });

/**
 * Get Depot Operator Users
 * @param depotId
 */
export const getDepotOperators = (depotId: string) =>
  createSelector(getUsersByDepotIdSelector(depotId), (state) => {
    return state.filter((user) => user.role === DEPOT_OPERATOR);
  });

export const getDriverUsersSelector = createSelector(
  [usersStateSelector],
  (usersState) => {
    return usersState.users.filter((user) => user.role === DRIVER);
  }
);

export const getUsersByCompanySelector = createSelector(
  [usersStateSelector, sessionStateSelector],
  (usersState, sessionState) => {
    if (!sessionState.selectedCompany) return [];
    return usersState.users.filter(
      (user) => user.company?.id === sessionState.selectedCompany?.id
    );
  }
);

export const getUsersByCompaniesSelector = createSelector(
  [usersStateSelector, sessionStateSelector],
  (usersState, sessionState) => {
    return usersState.users.filter((user) =>
      sessionState.selectedCompanies?.includes(user.company?.id ?? '')
    );
  }
);

export const getUsersByIdsSelector = (userIds: string[]) =>
  createSelector([usersStateSelector], (usersState) => {
    return usersState.users
      .filter((user) => userIds.includes(user.id ? user.id : ''))
      .map(
        (user) =>
          ({
            id: user.id,
            email: user.email,
            name: `${user.firstName} ${user.lastName}`,
            phoneNumber: user.phoneNumber,
          } as UserLight)
      );
  });

/**
 * User Depots Overview
 * Logged in users sphere of interest
 */
export const getOverviewUserActiveStops = () =>
  createSelector(
    [authStateSelector, stopMessageSelector, depotStateSelector],
    (authState, stopMessageState, depotState) => {
      if (!authState.user) return [];
      if (!authState.user?.productTypes) return [];

      const userDepots = depotState.depots.filter((depot) =>
        authState.user?.depots.includes(depot.id)
      );

      const stops = stopMessageState.activeStopMessages.filter((stop) => {
        const stopsProductTypes = stop.products.map(
          (product) => product.type.id
        );

        const filter = stop.isConcerningAllProducts
          ? true
          : stopsProductTypes.some((id) =>
              authState.user?.productTypes.includes(id)
            );
        return (
          stop.status !== 'DRAFT' &&
          authState.user?.depots.includes(stop.depot.id) &&
          !stop.deleted &&
          (stop.isDisturbanceInformation || filter)
        );
      });

      return userDepots.map((depot) => {
        const activeStopMessages = stops.filter(
          (stop) => stop.depot.id === depot.id && !stop.deleted
        );
        const upcoming = activeStopMessages.filter(
          (stop) => stop.startDate > new Date().getTime()
        );

        const depotColor =
          activeStopMessages.length > 0
            ? upcoming.length === activeStopMessages.length
              ? '#ffa500'
              : '#ff0000'
            : '#00ff00';

        return {
          ...depot,
          indicatorColor: depotColor,
          activeStopMessages,
        } as StatusDepot;
      });
    }
  );

/**
 * Create a user async
 * @return
 */
export const createUser = async (user: User) => {
  return await firebase.functions().httpsCallable('createUser')(user);
};

/**
 *  Admin update user async
 * @return
 */
export const adminUpdateUser = async (user: User) => {
  return await firebase.functions().httpsCallable('adminUpdateUser')(user);
};

/**
 * Admin disable user async
 * * @param userId
 * @return Promise<unknown>
 */
export const adminDisableUser = async (userId: string) => {
  return await firebase.functions().httpsCallable('adminDisableUser')(userId);
};

/**
 * Admins (super admins) enable users
 * @param userId
 * @return Promise<unknown>
 */
export const adminEnableUser = async (userId: string) => {
  return await firebase.functions().httpsCallable('adminEnableUser')(userId);
};

/**
 * Delete user in auth and firestore db
 * @param userId
 */
export const adminDeleteUser = async (userId: string) => {
  return await firebase.functions().httpsCallable('adminDeleteUser')(userId);
};
