import {Menu} from 'antd';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {sessionStateSelector} from "store/slices/sessionSlice";
import {useSelector} from 'react-redux';

export const DriverAndStakeholderLayout = () => {
  const {t} = useTranslation();
  const session = useSelector(sessionStateSelector);

  return (
    <>
      <>
        <Menu theme="dark" mode="horizontal" style={{position: 'absolute'}}>
          <Menu.Item key="personal-overview">
            <Link to={'/personal-overview'}>{t('menu.personalOverview')}</Link>
          </Menu.Item>
          {session.selectedCompany?.id ===
          '87b69206-a0fa-11eb-bcbc-0242ac130002' && (
            <Menu.Item key="fullscreen-dashboard">
              <Link to={`/dashboard/${session.selectedCompany?.id}`}>
                Dashboard
              </Link>
            </Menu.Item>
          )}
          <Menu.Item key="contacts">
            <Link to={'/contacts'}>{t('menu.contacts')}</Link>
          </Menu.Item>
          <Menu.Item key="support">
            <Link to={'/support'}>{t('menu.support')}</Link>
          </Menu.Item>
        </Menu>
      </>
    </>
  );
};
