import {Button, Form, message} from 'antd';
import {Modal} from 'antd';
import {useEffect, useState} from 'react';
import {
  ProductForm,
  ProductFormValues,
} from 'views/product/partials/ProductForm';
import {Product, updateProductAsync} from 'store/slices/productSlice';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';

interface ProductEditModalProps {
  onClose: () => void;
  visible: boolean;
  product: Product;
}

export const ProductEditModal = (props: ProductEditModalProps) => {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(props.visible);
  const [submitted, setSubmitted] = useState(false);
  const [form] = Form.useForm<ProductFormValues>();
  const {t} = useTranslation();

  useEffect(() => {
    if (props.visible) {
      setSubmitted(false);
      setVisible(true);
    }
  }, [props.visible]);

  const updateProduct = (formValues: ProductFormValues) => {
    dispatch(updateProductAsync(formValues, props.product));
    setSubmitted(true);
    setTimeout(() => {
      setVisible(false);
      form.resetFields();
      if (props.onClose) props.onClose();
    }, 1000);
  };

  const submit = async () => {
    try {
      await form.validateFields();
      form.submit();
    } catch (errorInfo) {
      message.error(t('error.fieldNotFilled'));
      setSubmitted(false);
    }
  };

  return (
    <>
      <Modal
        title={t('modifyEntity.edit', {entity: t('product.name')})}
        width={600}
        maskClosable={false}
        closable={false}
        key={'edit-product-modal'}
        centered
        visible={visible}
        footer={[
          <Button
            key="back-cancel"
            disabled={submitted}
            onClick={() => {
              setVisible(false);
              form.resetFields();
              if (props.onClose) props.onClose();
            }}>
            {t('buttons.cancel')}
          </Button>,
          <Button
            key="submit-btn"
            type="primary"
            loading={submitted}
            style={{marginRight: 8}}
            onClick={async () => {
              await submit();
            }}>
            {t('buttons.save')}
          </Button>,
        ]}>
        <ProductForm
          mode="edit"
          isSaving={submitted}
          product={props.product}
          form={form}
          onSave={(formValues) => updateProduct(formValues)}
        />
      </Modal>
    </>
  );
};
